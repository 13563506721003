import React, { useState } from "react";
import { Button, ModalConfirmAddress } from "../../components";
import {
  CompanyLandingHeader,
  CompanyPhoto,
  Dream,
  globalPartImg,
  LeftArrow,
  ProgressWithOne,
  RightArrow,
  savingTime,
} from "../../assets";
import styles from "./styles.module.css";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/slices/authSlice";
import { t } from "i18next";
import { getCompanyDetailsbyUser } from "../../redux/slices/jobsSlice";
import { useNavigate } from "react-router-dom";

const images = [
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
];
function CompanyLandingPage() {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const { openModalStatus } = useSelector((state) => state.auth);
  const { companiesPartnerData } = useSelector((state) => state.jobs);
  // Function to handle modal close
  const handleCloseModal = () => {
    dispatch(closeModal()); // Dispatch an action to close the modal
  };
  const handleNext = () => {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0); // Loop back to the first image
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1); // Move to the next image
    }
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1); // Loop back to the last image
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1); // Move to the previous image
    }
  };
  const getCompanyProfileHandler = (index) => {
    const companyCode = {
      company_code: index.code,
    };
    dispatch(getCompanyDetailsbyUser(companyCode))
      .unwrap()
      .then(() => {
        navigate("/companyProfile");
      })
      .catch(() => {});
    console.log(index);
  };
  return (
    <div className={`${styles.company_landing}`}>
      {/*Header*/}
      <div className={styles.companylanding_header}>
        <Container>
          <div
            className={`row ${styles.companylanding_header_con} d-flex align-items-center `}
          >
            <div
              className={`order-2 order-md-1 col-12 col-md-6 d-flex flex-column align-items-center gap-3 ${styles.companylanding_header_info}`}
            >
              <h3>{t("HireSmarterGrowFaster")}</h3>
              <p>{t("WithShoghlFindTheBest")}</p>
              <Button
                title={t("HireNow")}
                style={styles.companylanding_header_info_btn}
                to={"/searchCV"}
              />
            </div>
            <div
              className={`order-1 order-md-2 col-12 col-md-6 d-flex align-items-center justify-content-center ${styles.company_header_img}`}
            >
              <img src={CompanyLandingHeader} alt="CompanyLandingHeader" />
            </div>
          </div>
        </Container>
      </div>

      {/*Join Saudi Top Companies */}
      <section className={`${styles.global_partners}`}>
        <Container className="p-5 m-auto d-flex flex-column justify-content-between align-items-center">
        <h1 className="py-5">{t("JoinSaudiTopCompanies")}</h1>
          {/* Start Slider */}
          <div
            className={`${styles.Globalpart_Slider} d-flex flex-row align-items-center justify-content-center gap-3`}
          >
            <img
              src={LeftArrow}
              alt="Previous"
              className="arrow-icon"
              onClick={handlePrev}
            />
            <div className={`${styles.slider_imgs}`}>
              <div
                className="d-flex align-items-center"
                style={{
                  display: "flex",
                  transition: "transform 0.5s ease",
                  transform: `translateX(-${
                    currentIndex * (100 / companiesPartnerData?.length)
                  }%)`,
                  width: `${companiesPartnerData?.length * 100}%`,
                }}
              >
                {companiesPartnerData?.map((logo, index) => (
                  <img
                    key={index}
                    src={logo.company_logo ? logo.company_logo : CompanyPhoto}
                    alt={`Slide ${index}`}
                    style={{
                      width: `${100 / companiesPartnerData?.length}%`,
                      flexShrink: 0,
                    }}
                    onClick={() => getCompanyProfileHandler(logo)}
                  />
                ))}
              </div>
            </div>
            <img
              src={RightArrow}
              alt="Next"
              className="arrow-icon"
              onClick={handleNext}
            />
          </div>
          {/* End of Slider */}
        </Container>
      </section>
      {/* Progress With One Tool */}
      <div className={`${styles.progress_section}`}>
        <div id="carouselExampleCaptions" className="carousel slide ">
          <div>
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                className={`active ${styles.carousel_btn} mx-2`}
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                className={`${styles.carousel_btn} mx-2`}
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                className={`${styles.carousel_btn} mx-2`}
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="3"
                className={`${styles.carousel_btn} mx-2`}
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item  active">
                <div className="container-fluid">
                  <div
                    className={`row ${styles.companylanding_progress} d-flex align-items-center `}
                  >
                    <div
                      className={`order-2 order-md-1 col-12 col-md-6 d-flex flex-column align-items-start gap-3 ${styles.companylanding_progress_info}`}
                    >
                      <h3>progress with one tool</h3>
                      <p>
                        Gain control over the whole process by using WUZZUF, all
                        the way from screening to short-listing, till
                        interviewing and hiring, and better manage the process
                        with your team.
                      </p>
                    </div>
                    <div
                      className={`order-1 order-md-2 col-12 col-md-6 d-flex align-items-center justify-content-center ${styles.company_progress_img}`}
                    >
                      <img src={ProgressWithOne} alt="ProgressWithOne" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item ">
                <div className="container-fluid">
                  <div
                    className={`row ${styles.companylanding_progress} d-flex align-items-center `}
                  >
                    <div
                      className={`order-2 order-md-1 col-12 col-md-6 d-flex flex-column align-items-start gap-3 ${styles.companylanding_progress_info}`}
                    >
                      <h3>progress with one tool</h3>
                      <p>
                        Gain control over the whole process by using WUZZUF, all
                        the way from screening to short-listing, till
                        interviewing and hiring, and better manage the process
                        with your team.
                      </p>
                    </div>
                    <div
                      className={`order-1 order-md-2 col-12 col-md-6 d-flex align-items-center justify-content-center ${styles.company_progress_img}`}
                    >
                      <img src={ProgressWithOne} alt="ProgressWithOne" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item ">
                <div className="container-fluid">
                  <div
                    className={`row ${styles.companylanding_progress} d-flex align-items-center `}
                  >
                    <div
                      className={`order-2 order-md-1 col-12 col-md-6 d-flex flex-column align-items-start gap-3 ${styles.companylanding_progress_info}`}
                    >
                      <h3>progress with one tool</h3>
                      <p>
                        Gain control over the whole process by using WUZZUF, all
                        the way from screening to short-listing, till
                        interviewing and hiring, and better manage the process
                        with your team.
                      </p>
                    </div>
                    <div
                      className={`order-1 order-md-2 col-12 col-md-6 d-flex align-items-center justify-content-center ${styles.company_progress_img}`}
                    >
                      <img src={ProgressWithOne} alt="ProgressWithOne" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item ">
                <div className="container-fluid">
                  <div
                    className={`row ${styles.companylanding_progress} d-flex align-items-center `}
                  >
                    <div
                      className={`order-2 order-md-1 col-12 col-md-6 d-flex flex-column align-items-start gap-3 ${styles.companylanding_progress_info}`}
                    >
                      <h3>progress with one tool</h3>
                      <p>
                        Gain control over the whole process by using WUZZUF, all
                        the way from screening to short-listing, till
                        interviewing and hiring, and better manage the process
                        with your team.
                      </p>
                    </div>
                    <div
                      className={`order-1 order-md-2 col-12 col-md-6 d-flex align-items-center justify-content-center ${styles.company_progress_img}`}
                    >
                      <img src={ProgressWithOne} alt="ProgressWithOne" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Save time contacting */}
      <Container>
        <div className={`row ${styles.saving_time} d-flex align-items-center `}>
          <div
            className={`col-12 col-md-6 d-flex align-items-center justify-content-center ${styles.saving_time_img}`}
          >
            <img src={savingTime} alt="Saving Time" />
          </div>
          <div
            className={`col-12 col-md-6 d-flex flex-column align-items-start gap-3 ${styles.saving_time_info}`}
          >
            <h3>
              Save time contacting <br />
              the right candidates
            </h3>
            <p>
              Instead of wasting time reaching out to passive candidates to
              check their interest in your jobs, you can invite them to apply
              with one simple click. Better utilize your time and effort with
              candidates who are passionate about your company and accept your
              invitation
            </p>
          </div>
        </div>
        {openModalStatus === 3 && (
          <ModalConfirmAddress
            isOpen={openModalStatus === 3}
            onClose={handleCloseModal}
          />
        )}
      </Container>
    </div>
  );
}

export default CompanyLandingPage;
