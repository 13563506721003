//=========== Component ===========
import { Button, IconPlusTitle, InputField } from "../../../components";
import {
  location,
  ProfilePic,
  Callender,
  Message,
  SaveEdit,
  editIcon,
  KsaFlag,
  UploadPhoto,
} from "../../../assets";
//=========== bootStrap ===========
import { Container } from "react-bootstrap";
//=========== styles ===========
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import i18n from "../../../i18n/i18n";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCandidateProfile } from "../../../redux/slices/jobsSlice";
import { getMyProfile } from "../../../redux/slices/authSlice";
//
const jobRoles = [
  { id: 1, role: "Programmer" },
  { id: 2, role: "Engineer" },
  { id: 3, role: "Accountant" },
  { id: 4, role: "Lawyer" },
  { id: 5, role: "Data Scientist" },
];
function UserProfileHeader() {
  const dispatch = useDispatch();
  const locationState = useLocation();
  // SELECTORS
  const { userProfileData } = useSelector((state) => state.auth);
  const { cities, countries } = useSelector((state) => state.helpers);
  const { userCvsDetailsData } = useSelector((state) => state.jobs);
  // STATE
  const { from } = locationState.state || {};
  const [isAboutMeEditing, setIsAboutMeEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    avatar: "",
    firstName: "",
    lastName: "",
    country: { value: "", id: "" },
    city: { value: "", id: "" },
    jobs: { value: "", id: "" },
    birthDate: null,
  });
  const [birthDays, setBirthDays] = useState({
    birth_day: "",
    birth_month: "",
    birth_year: "",
  });
  // ============== Fun & Logic & Integration ==============
  // Function To Upload New Image
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Validate the file type (accept only png, jpg)
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        alert("Please upload a valid image (PNG or JPG).");
        return;
      }

      // Validate the file size (maximum 5MB)
      const maxSizeInMB = 5;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSizeInBytes) {
        alert("The file size should not exceed 5MB.");
        return;
      }

      // Proceed with reading and setting the file if validation passes
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the new avatar (base64 or URL) to the state
        setProfileData((prevState) => ({
          ...prevState,
          avatar: reader.result, // base64 image
        }));
      };
      reader.readAsDataURL(file); // Read the file as data URL (base64)
    }
  };
  // Function To Edit ==> Convert to The Inputbox
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };
  const handleEditing = (field) => {
    return isAboutMeEditing ? (
      <input
        type="text"
        name={field}
        value={profileData[field]}
        onChange={handleInputChange}
        className={styles.edit_input_style}
      />
    ) : (
      <p>{profileData[field]}</p>
    );
  };
  // Function To Edit ==> Convert to The Select Box
  const handleListChange = (e, field) => {
    const { value } = e.target;

    // Find the selected item from the corresponding list based on the field
    let selectedItem;
    if (field === "country") {
      selectedItem = countries?.data?.find((item) => item.name_en === value);
    } else if (field === "city") {
      selectedItem = cities?.data?.find((item) => item.name_en === value);
    } else if (field === "jobs") {
      selectedItem = jobRoles.find((item) => item.role === value);
    }

    // Update profileData with both the selected value and id
    setProfileData({
      ...profileData,
      [field]: { value, id: selectedItem ? selectedItem.id : "" },
    });
  };
  const handleListEditing = (field) => {
    let listData = [];
    if (field === "country") {
      listData = countries?.data?.map((country) => country.name_en);
    } else if (field === "city") {
      listData =
        cities &&
        cities?.data?.length &&
        Array.isArray(cities) &&
        cities?.data?.map((city) => city.name_en);
    } else if (field === "jobs") {
      listData = jobRoles?.map((job) => job.role);
    }

    return isAboutMeEditing ? (
      <InputField
        placeholder={t("Select")}
        type="text"
        name={field}
        value={profileData[field].value} // Access the value property
        onChange={(e) => handleListChange(e, field)} // Pass field to handleListChange
        style={styles.select_component_style}
        inputStyle={styles.option_component_style}
        isList={true}
        list={listData} // Pass the list of options
      />
    ) : (
      <p>{profileData[field].value}</p> // Display the value when not editing
    );
  };
  // =============== Function To Handle Edit Birth Date ================
  const handleBirthDateChange = (e, name) => {
    if (name === "birth_day") {
      const birthDay = e.target.value;
      setBirthDays({ ...birthDays, birth_day: birthDay });
    } else if (name === "birth_month") {
      const birthMonth = e.target.value;
      setBirthDays({ ...birthDays, birth_month: birthMonth });
    } else if (name === "birth_year") {
      const birthYear = e.target.value;
      setBirthDays({ ...birthDays, birth_year: birthYear });
    }
  };
  const handleBirthDateEditing = (field) => {
    return isAboutMeEditing ? (
      <div className="d-flex align-items-center gap-2">
        <input
          type="text"
          placeholder="Day"
          name={"birth_day"}
          value={setBirthDays["birth_day"]}
          onChange={(e) => handleBirthDateChange(e, "birth_day")}
          className={styles.edit_input_style}
        />
        <input
          type="text"
          placeholder="Month"
          name={"birth_month"}
          value={setBirthDays["birth_day"]}
          onChange={(e) => handleBirthDateChange(e, "birth_month")}
          className={styles.edit_input_style}
        />
        <input
          type="text"
          placeholder="Year"
          name={"birth_year"}
          value={setBirthDays["birth_day"]}
          onChange={(e) => handleBirthDateChange(e, "birth_year")}
          className={styles.edit_input_style}
        />
      </div>
    ) : (
      <p>{profileData[field]} years ago</p>
    );
  };

  useEffect(() => {
    console.log(
      "countries",
      userProfileData,
      userProfileData?.country,
      countries
    );
  }, [countries, userProfileData]);
  // ====== Function to calculate The Old of The Candidate by (Day,Month,Year) ======
  function calculateAge(birthDay, birthMonth, birthYear) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // Months are 0-based, so add 1
    const currentDay = today.getDate();

    let age = currentYear - birthYear;

    // Adjust if the birthday hasn't occurred yet this year
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDay < birthDay)
    ) {
      age--;
    }

    return age; // Ensure age is returned
  }
  // ====== useEffect To set The Old of The Candidate ======
  useEffect(() => {
    const birthData = from === "CVsPage" ? userCvsDetailsData : userProfileData;
    if (birthData) {
      const calculatedAge = calculateAge(
        birthData?.birth_day,
        birthData?.birth_month,
        birthData?.birth_year
      );

      setProfileData((prevData) => ({
        ...prevData,
        birthDate: calculatedAge, // Pass the calculated age directly here
      }));
    }
  }, [from, userCvsDetailsData, userProfileData]);
  // ==== Effect to set profile data based on where the navigation came from ===
  useEffect(() => {
    console.log("userCvsDetailsData", userCvsDetailsData);
    if (from === "CVsPage") {
      setProfileData((prevData) => ({
        ...prevData,
        avatar: userCvsDetailsData?.avatar || "",
        firstName: userCvsDetailsData?.name || "",
        lastName: userCvsDetailsData?.last_name || "",
        country: {
          value: userProfileData?.country?.default_name || "Unknown Country",
          id:
            countries?.data?.find(
              (country) =>
                country.name_en ===
                userCvsDetailsData?.country_name[0]?.default_name
            )?.code || "",
        },
        city: {
          value:
          userProfileData?.city?.default_name  || "Unknown City",
          id:
            cities?.find(
              (city) =>
                city.name_en === userCvsDetailsData?.city_name[0]?.default_name
            )?.code || "",
        },
        jobs: {
          value:
            userCvsDetailsData?.job_titles?.join(", ") || "There is No job",
          id:
            jobRoles
              ?.filter((job) =>
                userCvsDetailsData?.job_titles?.includes(job.role)
              )
              ?.map((job) => job.code) || [],
        },
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        avatar: userProfileData?.avatar || "",
        firstName: userProfileData?.first_name || "",
        lastName: userProfileData?.last_name || "",
        country: {
          value:
          userProfileData?.country?.default_name  || "Unknown Country",
          id: userProfileData?.country || "",
        },
        city: {
          value:
          userProfileData?.city?.default_name || "Unknown City",
          id: userProfileData?.city || "",
        },
        jobs: {
          value: userProfileData?.jobs?.join(", ") || "There is No job",
          id:
            jobRoles
              ?.filter((job) => userProfileData?.jobs?.includes(job.role))
              ?.map((job) => job.code) || [],
        },
      }));
      setBirthDays((prev) => ({
        ...prev,
        birth_day: userProfileData?.birth_day,
        birth_month: userProfileData?.birth_month,
        birth_year: userProfileData?.birth_year,
      }));
    }
  }, [from, userCvsDetailsData, userProfileData, countries, cities]);
  // ====== Function To Send Data To API ======
  const toggleEditMode = () => {
    if (isAboutMeEditing) {
      // Save data when exiting edit mode
      const candidtaeData = {
        avatr: profileData?.avatar,
        first_name: profileData?.firstName,
        last_name: profileData?.lastName,
        nickname: "bhr98",
        country: profileData?.country.id,
        city: profileData?.city.id,
        birth_year: birthDays.birth_year,
        birth_month: birthDays.birth_month,
        birth_day: birthDays.birth_day,
      };
      console.log(candidtaeData);
      dispatch(editCandidateProfile(candidtaeData))
        .unwrap()
        .then(() => {
          dispatch(getMyProfile());
          alert("Profile Updated Successfully :)");
        });
    }
    setIsAboutMeEditing((prev) => !prev);
  };
  return (
    <div>
      <div className={`${styles.profile_header} p-5 mb-5`}>
        <div
          className={
            i18n.language === "ar"
              ? styles.editicon_parent_ar
              : styles.editicon_parent_en
          }
        >
          {from === "CVsPage" ? (
            ""
          ) : isAboutMeEditing ? (
            <img src={SaveEdit} onClick={toggleEditMode} alt="Save" />
          ) : (
            <img src={editIcon} onClick={toggleEditMode} alt="Edit" />
          )}
        </div>
        <Container className="row d-flex align-items-center ">
          {/* User Info */}
          <div className="row col-md-8 d-flex flex-column flex-lg-row align-items-center">
            {/* User Profile Image */}
            <div
              className={`col-12 col-md-3 pb-3 me-md-3 d-flex justify-content-center ${styles.photo_container}`}
            >
              <img
                className={`${styles.profile_photo} `}
                src={profileData.avatar || ProfilePic} // Use the uploaded avatar or default profile pic
                alt="Profile"
              />
              {isAboutMeEditing && (
                <div
                  className={`${styles.upload_photo} c-pointer d-flex align-items-center gap-1`}
                  onClick={() => document.getElementById("avatarInput").click()}
                >
                  <h6>Upload New Photo</h6>
                  <img src={UploadPhoto} alt="upload-photo" />
                </div>
              )}

              {/* Hidden file input for uploading images */}
              <input
                type="file"
                id="avatarInput"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
            {/* User Profile Info */}
            <div className="col-12 col-md-8 d-flex flex-column align-items-center align-items-lg-start mt-3 mt-md-0 ms-3 ms-md-0">
              <h3 className={`pb-2 ${styles.edit_name} mt-3 mt-lg-0`}>
                {handleEditing("firstName")} {handleEditing("lastName")}
              </h3>
              <h5 className="pb-3">{handleListEditing("jobs")}</h5>
              <div
                className={`d-flex align-items-center ${
                  styles.custom_gap
                } pb-3 ${isAboutMeEditing && `flex-column flex-md-row`}`}
              >
                <span className={`shadow ${styles.job_status} text-nowrap`}>
                  Immediate start
                </span>
                <IconPlusTitle
                  IconSrc={KsaFlag}
                  customIconStyle={styles.flag_photo}
                  title={handleListEditing("country")}
                  style={{ color: "#666666" }}
                />
                <IconPlusTitle
                  IconSrc={location}
                  title={handleListEditing("city")}
                  style={{ color: "#77777" }}
                />
              </div>
              <div className="text-nowrap">
                <IconPlusTitle
                  IconSrc={Callender}
                  title={handleBirthDateEditing("birthDate")}
                  style={{ color: "#666666" }}
                />
              </div>
            </div>
          </div>
          {/* Send Message Button */}
          {from === "CVsPage" && (
            <div
              className={` col-12 col-md-3 mt-3 mt-md-0 ${styles.send_message}`}
            >
              <Button
                title={t("SendMessage")}
                style={`p-3 fw-normal d-flex justify-content-md-end justify-content-center`}
                iconSrc={Message}
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
}

export default UserProfileHeader;
