import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { t } from "i18next";
import { Button, InputField } from "../../../components";
import { useSelector } from "react-redux";
import {
  BlackExit,
  CompanyPhoto,
  EditPen,
  rectangleCheckBox,
  rectangleCheckBoxVector,
} from "../../../assets";
import i18n from "../../../i18n/i18n";
const startMonth = {
  data: [
    { code: "1", default_name: "Jan" },
    {
      code: "2",
      default_name: "Feb",
    },
    { code: "3", default_name: "March" },
    { code: "4", default_name: "April" },
  ],
};
const startYear = {
  data: [
    { code: "1", default_name: "2020" },
    {
      code: "2",
      default_name: "2021",
    },
    { code: "3", default_name: "2022" },
    { code: "4", default_name: "2023" },
  ],
};
const endingMonth = {
  data: [
    { code: "1", default_name: "Jan" },
    {
      code: "2",
      default_name: "Feb",
    },
    { code: "3", default_name: "March" },
    { code: "4", default_name: "April" },
  ],
};
const endingYear = {
  data: [
    { code: "1", default_name: "2020" },
    {
      code: "2",
      default_name: "2021",
    },
    { code: "3", default_name: "2022" },
    { code: "4", default_name: "2023" },
  ],
};

function StepThree({ step, onStepDataChange }) {
  const { jobCategoryData, experienceYearsData, educationalLevelsData } =
    useSelector((state) => state.helpers);
  const [isEdit, setIsEdit] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [stepThree, setStepThree] = useState({
    experienceYear: { value: "", code: null },
  });
  const [experienceToEdit, setExperienceToEdit] = useState(null);
  const [showExpForm, setShowExpForm] = useState(true);
  const [savedExperiences, setSavedExperiences] = useState([]);
  // console.log(savedExperiences);
  const [experienceCompany, setExperienceCompany] = useState({
    job_title: "",
    company_name: "",
    category: { value: "", code: "" },
    starting_month: { value: "", code: "" },
    starting_year: { value: "", code: "" },
    ending_month: isChecked ? { value: "", code: "" } : null,
    ending_year: isChecked ? { value: "", code: "" } : null,
    experience_type: { value: "", code: "" },
  });
  // console.log(savedExperiences);
  const checkBoxHandler = isChecked
    ? rectangleCheckBoxVector
    : rectangleCheckBox;

  const handleListsChange = (event, field, data) => {
    const selectedName = event.target.value;

    // Update the state for the field
    setExperienceCompany((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: selectedName,
        code: null, // Set the ID to null initially
      },
    }));

    const selectedItem = data?.find(
      (item) => item?.default_name === selectedName
    );

    if (selectedItem) {
      const selectedId =
        selectedItem.code ||
        selectedItem.currency_id ||
        selectedItem.category_id;
      // Now update the ID for the field
      setExperienceCompany((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          value: selectedName,
          code: selectedId,
        },
      }));
    }
  };
  const handleEditListsChange = (event, field, data) => {
    const selectedName = event.target.value;

    // Find the selected item from the data
    const selectedItem = data?.find(
      (item) => item?.default_name === selectedName
    );

    const selectedId = selectedItem
      ? selectedItem.code ||
        selectedItem.currency_id ||
        selectedItem.category_id
      : null;

    // Update the state for the field, including both `value` and `code` in a single update
    setExperienceToEdit((prevState) => ({
      ...prevState,
      [field]: {
        value: selectedName,
        code: selectedId, // Set the code or ID based on the selected item
      },
    }));
  };
  const handleExperienceYearList = (event, field, data) => {
    const selectedName = event.target.value;

    // Update the state for the field
    setStepThree((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: selectedName,
        code: null, // Set the ID to null initially
      },
    }));

    const selectedItem = experienceYearsData?.data?.find(
      (item) => item?.default_name === selectedName
    );
    // alert(JSON.stringify(experienceYearsData?.data))
    if (selectedItem) {
      const selectedId =
        selectedItem.code ||
        selectedItem.currency_id ||
        selectedItem.category_id;
      // Now update the ID for the field
      setStepThree((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          value: selectedName,
          code: selectedId,
        },
      }));
      // alert("selectedId")
      onStepDataChange({
        exp_id: selectedId,
      });
    }
  };
  const handleInputChange = (field) => (e) => {
    setExperienceCompany((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };
  const experienceTypeHandler = (event, el) => {
    event.preventDefault();
    setExperienceCompany((prevState) => ({
      ...prevState,
      experience_type: { value: el.default_name, code: el.code },
    }));
  };
  const experienceEditTypeHandler = (event, el) => {
    event.preventDefault();
    setExperienceToEdit((prevState) => ({
      ...prevState,
      experience_type: { value: el.default_name, code: el.code },
    }));
  };
  //============================= Save Company EXP ================================
  const saveCompanyExpHandler = (e) => {
    // Check if all required fields are filled
    if (!experienceCompany.job_title || !experienceCompany.company_name) {
      alert("Please fill in all required fields."); // Add proper validation
      return;
    }

    // Add the current experience to the list of saved experiences
    setSavedExperiences((prevExperiences) => [
      ...prevExperiences,
      experienceCompany,
    ]);

    // x.push(experienceCompany);
    onStepDataChange({
      ...step,
      experiences:
        step?.experiences && step?.experiences?.length
          ? [...step?.experiences, experienceCompany]
          : [experienceCompany],
    });
    // Reset the experienceCompany state for the next input
    setExperienceCompany({
      job_title: "",
      company_name: "",
      category: { value: "", code: "" },
      starting_month: { value: "", code: "" },
      starting_year: { value: "", code: "" },
      ending_month: isChecked ? { value: "", code: "" } : null,
      ending_year: isChecked ? { value: "", code: "" } : null,
      experience_type: { value: "", code: "" },
    });

    setShowExpForm(false);
  };
  const editExperienceHandler = (experience) => {
    setExperienceToEdit(experience); // Set the experience being edited
    setIsEdit(true); // Open the modal
  };
  console.log(experienceToEdit);
  const saveEditHandler = () => {
    // Ensure all required fields are filled before saving
    if (!experienceToEdit.job_title || !experienceToEdit.company_name) {
      alert("Please fill in all required fields.");
      return;
    }
    // Update the saved experiences list with the updated experience
    setSavedExperiences((prevExperiences) => {
      // Create a new array by mapping over the previous experiences
      return prevExperiences.map(
        (exp) =>
          exp.company_name === experienceToEdit.company_name
            ? { ...exp, ...experienceToEdit } // Return the updated experience
            : exp // Return the unchanged experience
      );
    });

    // Close the edit form/modal
    setIsEdit(false);
  };
  const handleEditInputChange = (field) => (e) => {
    setExperienceToEdit((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  useEffect(() => {
    if (experienceToEdit) {
      console.log("experienceToEdit", savedExperiences);
    }
  }, [savedExperiences, onStepDataChange]);

  return (
    <main
      className={` flex-column d-flex justify-content-center position-relative ${styles.page_container}`}
    >
      <div
        className={`d-flex flex-column align-items-center py-4 mt-2 ${styles.page_header}`}
      >
        <p>{t("MainStep")}</p>
        <p>
          Tell Us About Your <span>Experience</span>
        </p>
      </div>
      <form>
        {/*============================= Start Section 1 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <h6 className="pb-4">{t("HowManyYearsOfExperienceDoYouHave")}</h6>
          <div className="row mb-3">
            <div className="col-12">
              <InputField
                placeholder={t("Select")}
                value={stepThree?.experienceYear?.value}
                onChange={(e) =>
                  handleExperienceYearList(
                    e,
                    "experienceYear",
                    educationalLevelsData?.data
                  )
                }
                isList={true}
                list={experienceYearsData?.data?.map((exp) => exp.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
          </div>
        </section>
        {/*============================= End Section 1 ============================ */}
        {/*============================= Start Section 2 ============================ */}
        <main className={`${styles.background_forms} p-5 mb-4 container`}>
          <h6 className="pb-4">What is your work experience?</h6>
          <div>
            <div>
              {savedExperiences?.map((experience, index) => (
                <div>
                  {" "}
                  <div
                    key={index}
                    className={`${styles.saved_job_parent} d-flex align-items-start`}
                  >
                    <div className={`${styles.com_image_parent}`}>
                      <img src={CompanyPhoto} alt="company-photo" />
                    </div>
                    <div
                      className={`d-flex flex-column gap-2 justify-content-start ${styles.com_info}`}
                    >
                      <div className="d-flex flex-column flex-md-row align-items-center gap-2">
                        <h6>{experience.job_title}</h6>
                        <span>{experience.experience_type.value}</span>
                      </div>
                      <p>{experience.company_name}</p>
                      <span>
                        {experience.starting_month.value}{" "}
                        {experience.starting_year.value} -{" "}
                        {experience.ending_month?.value || "Present"}{" "}
                        {experience.ending_year?.value || ""}
                      </span>
                    </div>
                    <div
                      className={`${styles.edit_icon}`}
                      onClick={() => editExperienceHandler(experience)}
                    >
                      <img src={EditPen} alt="edit_pen" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {savedExperiences?.length > 0 && (
              <div>
                <p>
                  You have successfully saved {savedExperiences?.length} work
                  experience.
                </p>
                <p
                  className={styles.addExp_para}
                  onClick={() => setShowExpForm(true)}
                >
                  + Add Experience/Activity
                </p>
              </div>
            )}
          </div>
          {showExpForm && (
            <div>
              <div className="mb-3">
                <label htmlFor="input1" className="form-label">
                  Job Title
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  id="input1"
                  placeholder="Job Title"
                  onChange={handleInputChange("job_title")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="input1" className="form-label">
                  Company/organization name
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  id="input1"
                  placeholder="Company/organization name
"
                  onChange={handleInputChange("company_name")}
                />
              </div>
              <div className="my-3">
                <p className="mb-2">
                  {stepThree?.postType?.value} {t("Category")}
                </p>
                <InputField
                  placeholder={t("Select")}
                  fieldName="options"
                  value={stepThree?.experienceCompany?.category?.value}
                  onChange={(e) =>
                    handleListsChange(e, "category", jobCategoryData?.data)
                  }
                  isList={true}
                  list={jobCategoryData?.data?.map((cat) => cat?.default_name)}
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                />
              </div>
              <section>
                <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center ">
                  <h6>Experience type</h6>
                </div>
                <div className={`row d-flex justify-content-between container`}>
                  {educationalLevelsData?.data?.map((el) => (
                    <button
                      onClick={(event) => experienceTypeHandler(event, el)}
                      key={el.code}
                      className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${
                        styles.current_level_result
                      } ${
                        experienceCompany?.experience_type.code === el.code
                          ? styles.active
                          : ""
                      }`}
                    >
                      {el.default_name}{" "}
                    </button>
                  ))}
                </div>
              </section>
              <section className="row mt-5 mb-3 d-flex justify-content-between">
                <div className="col-md-6">
                  <div className="mb-0">
                    <label htmlFor="input1" className="form-label">
                      Starting From
                    </label>
                    <InputField
                      type="text"
                      id="input1"
                      placeholder="Start Month"
                      onChange={(e) =>
                        handleListsChange(e, "starting_month", startMonth?.data)
                      }
                      style={styles.select_component_style}
                      inputStyle={styles.option_component_style}
                      isList={true}
                      list={startMonth?.data?.map((el) => el.default_name)}
                    />
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className={`${isChecked ? "d-none" : ""} mb-0`}>
                    <label htmlFor="input2" className="form-label">
                      Ending in
                    </label>
                    <InputField
                      type="text"
                      id="input2"
                      placeholder="End Month"
                      onChange={(e) =>
                        handleListsChange(e, "ending_month", endingMonth?.data)
                      }
                      style={styles.select_component_style}
                      inputStyle={styles.option_component_style}
                      isList={true}
                      list={endingMonth?.data?.map((el) => el.default_name)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <InputField
                    type="text"
                    id="input1"
                    placeholder="Start Year"
                    onChange={(e) =>
                      handleListsChange(e, "starting_year", startYear?.data)
                    }
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                    isList={true}
                    list={startYear?.data?.map((el) => el.default_name)}
                  />
                </div>
                <div className={`${isChecked ? "d-none" : ""} col-md-6`}>
                  <InputField
                    type="text"
                    id="input2"
                    placeholder="End Year"
                    onChange={(e) =>
                      handleListsChange(e, "ending_year", endingYear?.data)
                    }
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                    isList={true}
                    list={endingYear?.data?.map((el) => el.default_name)}
                  />
                </div>
              </section>
              <section>
                <div className="d-flex align-items-center my-4">
                  <input
                    type="checkbox"
                    id="currently_work"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    style={{ display: "none" }} // Hide the checkbox
                  />
                  <img
                    id="currently_work"
                    src={checkBoxHandler}
                    onClick={() => setIsChecked(!isChecked)}
                    className={`${i18n.language === "ar" ? "ms-2" : "me-2"} ${
                      styles.check_box_salary
                    }`}
                    alt="checkbox"
                  />
                  <label htmlFor="currently_work">I currently work there</label>
                </div>
                <div
                  className={"d-flex align-items-center justify-content-start"}
                >
                  {/*<Button
                    title={"Save & Add More"}
                    style={`px-3 py-3`}
                    disabled={false}
                  /> */}

                  <Button
                    onClick={saveCompanyExpHandler}
                    title={"Save"}
                    style={`px-4 py-3 me-3 ${styles.save_cancel}`}
                  />
                  <Button
                    title={"Cancel"}
                    style={`px-4 py-3 ${styles.save_cancel}`}
                  />
                </div>
              </section>
            </div>
          )}
        </main>
        {/*============================= End Section 2 ============================ */}
      </form>
      {/* Edit Experience Modal */}
      {isEdit && (
        <div
          className={styles.modal_parent}
          onClick={(e) => {
            if (e.target.className.includes("modal_parent")) {
              setIsEdit(false); // Close modal when clicking outside
            }
          }}
        >
          <div className={styles.modal_container}>
            <header
              className={`d-flex align-items-center justify-content-between`}
            >
              <h6>Edit Experience</h6>
              <div>
                <img
                  src={BlackExit}
                  alt="exit-btn"
                  className="c-pointer"
                  onClick={() => setIsEdit(false)}
                />
              </div>
            </header>
            <div className={styles.modal_body}>
              <div className="mb-3">
                <label htmlFor="input1" className="form-label">
                  Job Title
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  id="input1"
                  placeholder="Job Title"
                  value={experienceToEdit?.job_title || ""}
                  onChange={handleEditInputChange("job_title")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="input1" className="form-label">
                  Company/organization name
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  id="input1"
                  placeholder="Company/organization name"
                  value={experienceToEdit?.company_name || ""}
                  onChange={handleEditInputChange("company_name")}
                />
              </div>
              <div className="my-3">
                <p className="mb-2">
                  {stepThree?.postType?.value} {t("Category")}
                </p>
                <InputField
                  placeholder={t("Select")}
                  fieldName="options"
                  value={experienceToEdit?.category?.value || ""}
                  onChange={(e) =>
                    handleEditListsChange(e, "category", jobCategoryData?.data)
                  }
                  isList={true}
                  list={jobCategoryData?.data?.map((cat) => cat?.default_name)}
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                />
              </div>
              <section>
                <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center ">
                  <h6>Experience type</h6>
                </div>
                <div className={`row d-flex justify-content-between container`}>
                  {educationalLevelsData?.data?.map((el) => (
                    <button
                      onClick={(event) => experienceEditTypeHandler(event, el)}
                      key={el.code}
                      className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${
                        styles.current_level_result
                      } ${
                        experienceToEdit?.experience_type.code === el.code
                          ? styles.active
                          : ""
                      }`}
                    >
                      {el.default_name}{" "}
                    </button>
                  ))}
                </div>
              </section>
              <section className="row mt-5 mb-3 d-flex justify-content-between">
                <div className="col-md-6">
                  <div className="mb-0">
                    <label htmlFor="input1" className="form-label">
                      Starting From
                    </label>
                    <InputField
                      type="text"
                      id="input1"
                      placeholder="Start Month"
                      value={experienceToEdit?.starting_month?.value || ""}
                      onChange={(e) =>
                        handleEditListsChange(
                          e,
                          "starting_month",
                          startMonth?.data
                        )
                      }
                      style={styles.select_component_style}
                      inputStyle={styles.option_component_style}
                      isList={true}
                      list={startMonth?.data?.map((el) => el.default_name)}
                    />
                  </div>
                </div>
                <div className={`col-md-6`}>
                  <div className={`${isChecked ? "d-none" : ""} mb-0`}>
                    <label htmlFor="input2" className="form-label">
                      Ending in
                    </label>
                    <InputField
                      type="text"
                      id="input2"
                      placeholder="End Month"
                      value={experienceToEdit?.ending_month?.value || ""}
                      onChange={(e) =>
                        handleEditListsChange(
                          e,
                          "ending_month",
                          endingMonth?.data
                        )
                      }
                      style={styles.select_component_style}
                      inputStyle={styles.option_component_style}
                      isList={true}
                      list={endingMonth?.data?.map((el) => el.default_name)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <InputField
                    type="text"
                    id="input1"
                    placeholder="Start Year"
                    value={experienceToEdit?.starting_year?.value || ""}
                    onChange={(e) =>
                      handleEditListsChange(e, "starting_year", startYear?.data)
                    }
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                    isList={true}
                    list={startYear?.data?.map((el) => el.default_name)}
                  />
                </div>
                <div className={`${isChecked ? "d-none" : ""} col-md-6`}>
                  <InputField
                    type="text"
                    id="input2"
                    placeholder="End Year"
                    value={experienceToEdit?.ending_year?.value || ""}
                    onChange={(e) =>
                      handleEditListsChange(e, "ending_year", endingYear?.data)
                    }
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                    isList={true}
                    list={endingYear?.data?.map((el) => el.default_name)}
                  />
                </div>
              </section>
              <section>
                <div className="d-flex align-items-center my-4">
                  <input
                    type="checkbox"
                    id="hide_salary"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    style={{ display: "none" }} // Hide the checkbox
                  />
                  <img
                    id="hide_salary"
                    src={checkBoxHandler}
                    onClick={() => setIsChecked(!isChecked)}
                    className={`${i18n.language === "ar" ? "ms-2" : "me-2"} ${
                      styles.check_box_salary
                    }`}
                    alt="checkbox"
                  />
                  <label htmlFor="hide_salary">I currently work there</label>
                </div>
                <div
                  className={"d-flex align-items-center justify-content-start"}
                >
                  <Button
                    title={"Save"}
                    style={`px-4 py-3 mx-3`}
                    onClick={saveEditHandler}
                  />
                  <Button
                    title={"Cancel"}
                    style={`px-4 py-3 ${styles.save_cancel}`}
                    onClick={() => setIsEdit(false)}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default StepThree;
