import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { InputField } from "../../../components";
import { Eye, Facebook, LockOpened, Search } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { getandidateProfileCompanyViewedData } from "../../../redux/slices/jobsSlice";
const countryList = ["Saudi Arabia", "India", "Bangladesh", "Pakistan"];

function CandidateApplicationsHeader() {
  const { allAppliedJobData, companyViewedData } = useSelector((state) => state.jobs);
  // State to track which link is active (can be "applications" or "archive")
  const [activeLink, setActiveLink] = useState("applications");

  // Function to handle click and update active link
  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const [selectedOption, setSelectedOption] = useState("");
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getandidateProfileCompanyViewedData());
  }, []);
  return (
    <div
      className={`${styles.page_header} d-flex flex-column align-items-center align-items-md-start gap-4 mt-1`}
    >
      {/*Application and Archive and Sort By */}
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-5">
        <div className={`d-flex align-items-center me-0 me-md-5`}>
          <NavLink
            className={`d-flex text-align-center gap-5 ${styles.app_archive}`}
          >
            <Link
              className={`text-center ${
                activeLink === "applications" ? styles.active : ""
              }`}
              onClick={() => handleLinkClick("applications")}
            >
              {t("Applications")} ({allAppliedJobData?.data.length})
            </Link>
            <Link
              className={`text-center ${
                activeLink === "archive" ? styles.active : ""
              }`}
              onClick={() => handleLinkClick("archive")}
            >
              {t("Archive")}
            </Link>
          </NavLink>
        </div>
        <div
          className={`d-flex flex-column flex-md-row align-items-center gap-3 ms-0 ms-md-5 ${styles.sort_by_container}`}
        >
          <p>{t("SortBy")}</p>
          <div className="col-12 col-md-5">
            <InputField
              placeholder={t("LatestActivity")}
              value={selectedOption}
              onChange={handleSelectChange}
              isList={true}
              list={countryList}
              fieldName="options"
              style={styles.select_component_style}
              inputStyle={styles.option_component_style}
            />
          </div>
        </div>
      </div>
      {/*Account activity in the last 90 days */}
      {activeLink === "applications" ? (
        <div
          className={`d-flex flex-column align-items-center  align-items-md-start justify-content-start justify-content-md-center ${styles.account_activate}`}
        >
          <h5>{t("Accountactivityinthelast90days")}</h5>
          <div className="d-flex flex-column flex-md-row align-items-center gap-3">
            <div
              className={`d-flex align-items-center gap-3 ${styles.activate_box}`}
            >
              <div className={styles.img_box}>
                <img src={Search} alt="Search" />
              </div>
              <div className="d-flex flex-column align-items-start">
                <p className="m-auto m-md-0">
                  {companyViewedData && companyViewedData?.searched_count}
                </p>
                <span>{t("SearchAppearance")}</span>
              </div>
            </div>
            <div
              className={`d-flex align-items-center gap-3 ${styles.activate_box}`}
            >
              <div className={styles.img_box}>
                <img src={Eye} alt="Search" />
              </div>
              <div className="d-flex flex-column align-items-md-start">
                <p className="m-auto m-md-0">
                  {companyViewedData && companyViewedData?.viewed_count}
                </p>
                <span>{t("Viewedyourprofile")}</span>
              </div>
            </div>
            <div
              className={`d-flex align-items-center gap-3 ${styles.activate_box}`}
            >
              <div className={styles.img_box}>
                <img src={LockOpened} alt="Search" />
              </div>
              <div className="d-flex flex-column align-items-start">
                <p className="m-auto m-md-0">
                  {companyViewedData && companyViewedData?.view_profile_count}
                </p>
                <span>{t("ContactsAccessed")}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CandidateApplicationsHeader;
