import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import CompleteCompProLeft from "./CompleteCompProLeft";
import CompleteCompProRight from "./CompleteCompProRight";
// Import styles
import styles from "./styles.module.css";
// Import assets
import { CompanyPhoto, GreyGlobal, GreyLocation, RayaComp } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries } from "../../redux/slices/helperSlice";
import {
  getCompanyEmployeeRange,
  getCompanyProfile,
} from "../../redux/slices/jobsSlice";

function CompleteCompanyProfile() {
  const dispatch = useDispatch();
  const { companyInfo } = useSelector((state) => state.auth);
  const { companyProfileData } = useSelector((state) => state.jobs);
  console.log(companyProfileData)
  // State to store the uploaded cover image
  useEffect(() => {
    dispatch(getCompanyEmployeeRange());
    dispatch(getCompanyProfile());
    dispatch(getAllCountries());
  }, [dispatch]);
  // State to store the uploaded cover image
  return (
    <div className={styles.complete_company_profile}>
      <div className={`${styles.company_Profile}`}>
        <Container className="row d-flex flex-column flex-md-row align-items-center">
          <div className="col-12 col-md-9 row d-flex align-items-center">
            {/*Company Profile Image */}
            <div
              className={`col-12 col-md-3 pb-3  d-flex justify-content-center`}
            >
              <img
                className={`${styles.profile_photo} mt-1`}
                src={CompanyPhoto}
                alt={`RayaCompany`}
              />
            </div>
            {/*Company Profile Info */}
            <div
              className={`col-12 col-md-8 d-flex  flex-column align-items-center align-items-md-start mt-3 mt-md-0 ms-3 ms-lg-0 gap-2 ${styles.company_profile_info}`}
            >
              <div className="d-flex align-items-center pb-1">
                <p className="me-2 text-nowrap ">{companyProfileData?.company_name}</p>
                {/*<img src={BlueDoc} alt="Company URL" style={{ width: "16px" }} /> */}
              </div>
              <div className=" d-flex gap-2  my-2 m-md-0">
                <img src={GreyLocation} alt="Company URL" className="mt-1" />
                <span>
                {companyProfileData?.skills[0]?.default_name} . {companyProfileData?.city?.name_en}, {companyProfileData?.country?.name_en} .{" "}
                  {companyProfileData?.employees_range?.en} employees
                </span>
              </div>
              <div className="d-flex gap-2">
                <img src={GreyGlobal} alt="Company URL" className="mt-1" />
                <a href="/#">{companyProfileData?.business_email}</a>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* Company Profile Sections */}
      <Container>
        <div className="row pt-2 pt-md-4 py-2 m-auto">
          <div
            className={`col-12 col-md-8 order-lg-1 order-md-1 order-2 ${styles.z_index}`}
          >
            <CompleteCompProLeft />
          </div>
          <div
            className={`ps-0 ps-md-4 py-5 py-md-0 col-md-4 order-lg-2 order-md-2 order-1 ${styles.z_index}`}
          >
            <CompleteCompProRight />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CompleteCompanyProfile;
