import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Footer, Header } from "./components";
import { useEffect } from "react";
import {
  SignUp,
  Login,
  Home,
  UserProfile,
  RegisterSteps,
  JobProfile,
  CompanyProfile,
  ManagementJobs,
  JobPost,
  WelcomeEmployee,
  CompanyLandingPage,
  CompleteCompanyProfile,
  ManagementJobsNotFilter,
  CandidateApplications,
  MainRegisterStep,
  SearchCV,
  CompleteSearchCV,
  JobsApplications,
  ForgetPassword,
  OTPPage,
  NewPassword,
  JobLowPost,
  ChooseJobPost,
} from "./pages";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route index element={<Home />} />
        <Route path="managementJobs" element={<ManagementJobs />} />
        <Route
          path="managementJobsNotFilter"
          element={<ManagementJobsNotFilter />}
        />
        <Route
          path="candidateApplications"
          element={<CandidateApplications />}
        />
        <Route path="jobProfile" element={<JobProfile />} />
        <Route path="companyProfile" element={<CompanyProfile />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="mainRegisterStep" element={<MainRegisterStep />} />
        <Route path="registerSteps" element={<RegisterSteps />} />
        <Route path="login" element={<Login />} />
        <Route path="userProfile" element={<UserProfile />} />
        <Route path="searchCV" element={<SearchCV />} />
        <Route path="completeSearchCV" element={<CompleteSearchCV />} />
        <Route
          path="completeCompanyProfile"
          element={<CompleteCompanyProfile />}
        />
        <Route path="companyLandingPage" element={<CompanyLandingPage />} />
        <Route path="welcomeEmployee" element={<WelcomeEmployee />} />
        <Route path="jobPost" element={<JobPost />} />
        <Route path="jobLowPost" element={<JobLowPost />} />
        <Route path="chooseJobPost" element={<ChooseJobPost />} />
        <Route
        path="jobsApplications"
        element={<JobsApplications />}
      />
      <Route path="forgetPassword" element={<ForgetPassword />} />
      <Route path="otppage" element={<OTPPage />} />
      <Route path="newpassword" element={<NewPassword />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
