import React, { useEffect, useState } from "react";
import HomeHeader from "./HomeHeader";
import { Button, IconPlusTitle } from "../../components";
import { Container } from "react-bootstrap";
import {
  AccountingFinance,
  Automative,
  cash,
  Construction,
  CraftsJobs1,
  CraftsJobs2,
  CraftsJobs3,
  CraftsJobs4,
  DocumentIcon,
  Dollar,
  Dream,
  EaPhoto,
  EducationTraining,
  exploreFaster,
  FoodService,
  globalPartImg,
  HealthCare,
  HighDegree1,
  HighDegree2,
  HighDegree3,
  HighDegree4,
  Huwawi,
  job1,
  job2,
  job3,
  job4,
  job5,
  job6,
  King,
  LeftArrowT,
  location,
  Macdon,
  photoshop,
  RightArrowT,
  SalesMarketing,
  Telecommunication,
  ZoomPhoto,
} from "../../assets";
import { Link } from "react-router-dom";
//styles
import styles from "./styles.module.css";
import {getMyProfile } from "../../redux/slices/authSlice";
import { getAllCities, getAllCountries } from "../../redux/slices/helperSlice";
import { useDispatch } from "react-redux";
//======================== Popular Categories ==========================
const popularCategories = [
  {
    img: AccountingFinance,
    catNumber: 3,
    catTitle: (
      <>
        Accounting <br />/ Finance
      </>
    ),
  },
  {
    img: Automative,
    catNumber: 3,
    catTitle: "Automotive Jobs",
  },
  {
    img: Construction,
    catNumber: 3,
    catTitle: (
      <>
        Construction <br />/ Facilities
      </>
    ),
  },
  {
    img: EducationTraining,
    catNumber: 2,
    catTitle: "Education Training",
  },
  {
    img: SalesMarketing,
    catNumber: 1,
    catTitle: "Sales & Marketing",
  },
  {
    img: FoodService,
    catNumber: 2,
    catTitle: "Food Services",
  },
  {
    img: HealthCare,
    catNumber: 2,
    catTitle: "Healthcare",
  },
  {
    img: Telecommunication,
    catNumber: 4,
    catTitle: "Telecommunications",
  },
];
//======================== Recent Job ==========================
const jobs = [
  {
    types: ["Internship", "Full Time"],
    image: photoshop,
    title: "Restaurant Dishwasher",
    active: true,
  },
  {
    types: ["Full Time"],
    image: Huwawi,
    title: "Software Engineer",
    active: false,
  },
  {
    types: ["Freelance"],
    image: EaPhoto,
    title: "Graphic Designer",
    active: false,
  },
  {
    types: ["Part Time"],
    image: Macdon,
    title: "Customer Service",
    active: false,
  },
  {
    types: ["Part Time"],
    image: ZoomPhoto,
    title: "Customer Service",
    active: false,
  },
];
//
// Configuration object for job types and their associated colors
const jobTypeColors = {
  Internship: "#e1d123",
  "Full Time": "#186fc9",
  Freelance: "#53b427",
  "Part Time": "#f1630d",
};
// Function to get border color based on job types
const getBorderColor = (types) => {
  if (types.includes("Internship")) return jobTypeColors["Internship"];
  if (types.includes("Full Time")) return jobTypeColors["Full Time"];
  if (types.includes("Freelance")) return jobTypeColors["Freelance"];
  if (types.includes("Part Time")) return jobTypeColors["Part Time"];
  return "#f1630d"; // Default color if no types match
};

// Function to get button classes based on job types
const getButtonClasses = (types) => {
  const buttonClasses = {
    Internship: styles.job_status_btn_Internship,
    "Full Time": styles.job_status_btn_fulltime,
    Freelance: styles.job_status_btn_freelance,
    "Part Time": styles.job_status_btn_parttime,
  };
  return types.map((type) => buttonClasses[type] || "").join(" ");
};

// Global partners Images
const images = [
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
  Dream,
  globalPartImg,
];
function Home() {
  const dispatch = useDispatch()
  // ============================ For Slider ==========================
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex === images.length - 1) {
      setCurrentIndex(0); // Loop back to the first image
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1); // Move to the next image
    }
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(images.length - 1); // Loop back to the last image
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1); // Move to the previous image
    }
  };
  // ============================  ==========================
  const responsive =
    "d-flex flex-column flex-md-row justify-content-center align-items-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";
  const responsiveTwo =
    "d-flex flex-column  justify-content-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";

  //Popular Categories Data
  const popularCategoriesData = popularCategories.map((cat) => {
    return (
      <div
        className={`col-12 col-sm-6 col-md-4 col-lg-3 d-flex flex-column mb-4 ${styles.popular_job_content}`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <img src={cat.img} alt={cat.catTitle} />
          <h4>{cat.catNumber}</h4>
        </div>
        <p>{cat.catTitle}</p>
      </div>
    );
  });
  //Recent Jobs Data
  const recentJobsData = jobs.map((job, index) => (
    <div
      key={index}
      className={`row 
      ${responsive}
      ${styles.job_container} ${job.active ? styles.active : ""}`}
      style={{ borderLeftColor: getBorderColor(job.types) }}
    >
      <div
        className={`row col-12 col-md-10 text-center text-md-start ${responsive}`}
      >
        <div className="col-4 col-md-2">
          <Link to={"jobProfile"}>
            <img src={job.image} alt={job.title} />
          </Link>
        </div>
        <div className={`col-10 ${styles.job_info} ${responsiveTwo} `}>
          <p className="py-1">{job.title}</p>
          <div className="d-flex flex-wrap flex-column flex-md-row justify-content-center justify-content-md-start align-items-center gap-4 py-1">
            <IconPlusTitle
              IconSrc={King}
              title={"Telimed"}
              P_Style={`${styles.job_descrebtion}`}
            />
            <IconPlusTitle
              IconSrc={location}
              title={"KSA"}
              P_Style={`${styles.job_descrebtion}`}
            />
            <IconPlusTitle
              IconSrc={Dollar}
              title={"$30,000.00 - $35,000.00"}
              P_Style={`${styles.job_descrebtion}`}
              customIconStyle={styles.customIconStyle}
            />
          </div>
        </div>
      </div>
      <div className="col-2 d-flex flex-column gap-2 align-items-center mt-4">
        {job.types.map((type, idx) => (
          <button key={idx} disabled className={`${getButtonClasses([type])}`}>
            {type}
          </button>
        ))}
      </div>
    </div>
  ));
  useEffect(() => {
    dispatch(getMyProfile());
    dispatch(getAllCountries());
    dispatch(getAllCities(187));
  }, [dispatch]);
  return (
    <div>
      {/*Header*/}
      <HomeHeader />
      {/*Popular Categories*/}
      <div className={`${styles.popular_job} container  mb-5`}>
        <Container className="d-flex flex-column justify-content-center align-items-center align-items-md-start">
          <h3 className="pb-5">Popular Categories</h3>
          <Container>
            <div className={`row  gap-4 justify-content-center pb-4 `}>
              {popularCategoriesData}
            </div>
          </Container>

          <Button
            title={"Browse All Categories"}
            style={`${styles.find_job_btn}`}
          />
        </Container>
      </div>
      {/*Recent Jobs and Featured Jobs */}
      <Container className="row py-5 gap-5 m-auto">
        <div className="col-12 col-md-7 order-lg-1 order-md-1 order-2">
          <h4 className="mb-4 pb-2 fw-bold">Recent Jobs</h4>
          <div>{recentJobsData}</div>
          <div className="mt-5">
            <Button title={"Browse Jobs"} style={`${styles.find_job_btn}`} />
          </div>
        </div>
        <div className="ps-md-5 py-5 col-md-4 d-md-flex d-lg-block order-lg-2 order-md-2 order-1">
          <div>
            <div
              className={`mb-4 pb-2 d-flex align-items-center justify-content-between ${styles.feature_job_title}`}
            >
              <h4 className=" fw-bold">Featured Jobs</h4>
              <div className="d-flex align-items-center gap-1">
                <img src={LeftArrowT} alt="arrow" />
                <img src={RightArrowT} alt="arrow" />
              </div>
            </div>
            <div className={`${styles.featured_jobs_container}`}>
              <div className="d-flex align-items-center justify-content-between">
                <h5> Social Media and Public Relation Executive</h5>
                <button
                  disabled
                  className={`${styles.job_status_btn_freelance} py-2 px-4`}
                >
                  Freelance
                </button>
              </div>
              <div className="d-flex flex-column justify-content-start pt-4 gap-3">
                <IconPlusTitle
                  IconSrc={King}
                  title={" Coffee Corral"}
                  customIconStyle={"me-3"}
                  P_Style={styles.title_style}
                />
                <IconPlusTitle
                  IconSrc={location}
                  title={"Invalidenstraße, Berlin, Germany"}
                  customIconStyle={"me-3"}
                  P_Style={styles.title_style}
                />
                <IconPlusTitle
                  IconSrc={cash}
                  title={"$25- $35 / hour"}
                  customIconStyle={"me-3"}
                  P_Style={styles.title_style}
                />
                <IconPlusTitle
                  IconSrc={cash}
                  title={"$15000"}
                  customIconStyle={"me-3"}
                  P_Style={styles.title_style}
                />
              </div>
              <div className={`mt-2 p-1 ${styles.feature_job_info}`}>
                <p>
                  The Social Media & PR Executive will be responsible for
                  increasing hotel marketing communication across a variety of
                  social media
                </p>
              </div>
              <div>
                <Button
                  title={"Apply For This Job"}
                  style={styles.apply_job_btn}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/*Explore a faster, easier*/}
      <div className={`${styles.explore_faster}`}>
        <Container>
          <div className="row flex-column flex-lg-row justify-content-between pb-4 px-sm-5">
            <div
              className={`d-flex flex-column justify-content-center col-12 col-lg-4 flex-grow-1 ${styles.explore_info_container}`}
            >
              <h3>Explore a faster, easier, and better job search</h3>
              <div className="mt-4 d-flex flex-column justify-content-start gap-3">
                <div className="d-flex align-items-center gap-2">
                  <img src={DocumentIcon} alt="doc icon" />
                  <p> Unmatched quality of remote, hybrid, and flexible jobs</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src={DocumentIcon} alt="doc icon" />
                  <p>
                    Premium skills tests, remote courses, career coaching, and
                    more
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src={DocumentIcon} alt="doc icon" />
                  <p> Unmatched quality of remote, hybrid, and flexible jobs</p>
                </div>
              </div>
              <div className="mt-5">
                <Button
                  title={"Browse Jobs"}
                  style={`${styles.find_job_btn}`}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-4 mb-lg-0 order-first order-lg-last ">
              <figure className={styles.header_img_box}>
                <img src={exploreFaster} alt="exploreFaster" className="img" />
              </figure>
            </div>
          </div>
        </Container>
      </div>
      {/* Global partners that trusted us*/}
      <section className={`${styles.global_partners}`}>
        <Container className="p-5 pt-0 mb-5 m-auto d-flex flex-column justify-content-between align-items-center">
          <h1 className="pb-5">Global partners that trusted us</h1>
          {/* Start Slider */}
          <div
            className={`${styles.Globalpart_Slider} d-flex flex-row align-items-center justify-content-center gap-3`}
          >
            <img
              src={LeftArrowT}
              alt="Previous"
              className="arrow-icon"
              onClick={handlePrev}
            />
            <div className={`${styles.slider_imgs}`}>
              <div
                className="d-flex align-items-center"
                style={{
                  display: "flex",
                  transition: "transform 0.5s ease",
                  transform: `translateX(-${
                    currentIndex * (100 / images.length)
                  }%)`,
                  width: `${images.length * 100}%`,
                }}
              >
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Slide ${index}`}
                    style={{ width: `${100 / images.length}%`, flexShrink: 0 }}
                  />
                ))}
              </div>
            </div>
            <img
              src={RightArrowT}
              alt="Next"
              className="arrow-icon"
              onClick={handleNext}
            />
          </div>
          {/* End of Slider */}
        </Container>
      </section>
      {/*Browse Jobs by Career Level (High degrees)*/}
      <section>
        <div
          className={`py-5 container m-auto d-flex flex-column justify-content-between align-items-center ${styles.career_level}`}
        >
          <h1 className="py-5 text-center">
            Browse Jobs by Career Level
            <br />
            (High degrees)
          </h1>
          <div className="container-fluid">
            <div
              className={`row py-5 d-flex align-items-center justify-content-center ${styles.careers_level_cards}`}
            >
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={HighDegree1} alt="HighDegree1" />
                <p className="">Senior Management Jobs</p>
              </div>
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={HighDegree2} alt="HighDegree2" />
                <p> Senior Management Jobs</p>
              </div>
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={HighDegree3} alt="HighDegree3" />
                <p>Senior Management Jobs</p>
              </div>
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={HighDegree4} alt="Electrician" />
                <p> Senior Management Jobs</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Browse Jobs by Career Level (Crafts Jobs)*/}
      {/* <section>
        <div
          className={`py-5 container m-auto d-flex flex-column justify-content-between align-items-center ${styles.career_level}`}
        >
          <h1 className="py-5 text-center">
            {" "}
            Browse Jobs by Career Level
            <br />
            (Crafts Jobs)
          </h1>
          <div className="container-fluid">
            <div
              className={`row py-5 col-12 d-flex align-items-center justify-content-center ${styles.careers_level_cards}`}
            >
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={CraftsJobs1} alt="CraftsJobs1" />
                <p className="">Senior Management Jobs</p>
              </div>
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={CraftsJobs2} alt="CraftsJobs2" />
                <p> Senior Management Jobs</p>
              </div>
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={CraftsJobs3} alt="CraftsJobs3" />
                <p>Senior Management Jobs</p>
              </div>
              <div className="d-flex flex-column align-items-center col-12 col-md-3 col-sm-6">
                <img src={CraftsJobs4} alt="CraftsJobs4" />
                <p> Senior Management Jobs</p>
              </div>
            </div>
          </div>
        </div>
      </section>*/}

      {/* Jobs */}
      <section>
        <Container className="row py-5 m-auto d-flex flex-row align-items-center justify-content-center gap-5 ">
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-5 justify-content-start gap-4`}
          >
            <div>
              <img src={job1} alt="job1" />
            </div>
            <h3>Accounting</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job2} alt="job1" />
            </div>
            <h3> Healthcare Jobs</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p> Doctor Jobs</p>
              <p>Psychology Jobs</p>
              <p> Physiotherapist Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job3} alt="job1" />
            </div>
            <h3>Engineering</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job4} alt="job1" />
            </div>
            <h3>Media</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job5} alt="job1" />
            </div>
            <h3> Instructor</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job6} alt="job1" />
            </div>
            <h3> Project Management</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job1} alt="job1" />
            </div>
            <h3> Finance</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job1} alt="job1" />
            </div>
            <h3>Accounting</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
          <div
            className={`${styles.box_job}  d-flex flex-column align-items-start col-12 col-md-3 col-sm-6 justify-content-start gap-4`}
          >
            <div>
              <img src={job1} alt="job1" />
            </div>
            <h3> Finance</h3>
            <div className="d-flex flex-column justify-content-start gap-2">
              <p>Financial Manager Jobs</p>
              <p>Internal Audit Jobs</p>
              <p>Treasury Manager Jobs</p>
            </div>
            <div className={styles.right_arrow}>
              <img src={RightArrowT} alt="RightArrow" />
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default Home;
