import React, { useEffect, useState } from "react";
import {
  phone,
  Facebook,
  Copy,
  Home,
  Mobile,
  Share,
  SaveEdit,
  editIcon,
  rectangleCheckBoxVector,
  rectangleCheckBox,
} from "../../../assets";
import { EditedBox } from "../../../components";
import styles from "./styles.module.css";
import i18n from "../../../i18n/i18n";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { myProfileOverview } from "../../../redux/slices/authSlice";
import { t } from "i18next";
function RightSideUserProfile() {
  const locationState = useLocation();
  const { from } = locationState.state || {};
  const dispatch = useDispatch();
  //Selector
  const { userProfileData, loading } = useSelector((state) => state.auth);
  const { userCvsDetailsData } = useSelector((state) => state.jobs);
  //State
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [isChecked, setIsChecked] = useState(userProfileData?.hide_salary);
  const checkBoxHandler = isChecked
    ? rectangleCheckBoxVector
    : rectangleCheckBox;
  const [profileOverview, setProfileOverview] = useState({
    // Data that we Use
    expected_salary: "",
    mobile_phone: "",
    home_phone: "",
    address: "",
    hide_salary: isChecked ? "yes" : "no",
  });
  const [isFirstToggleChecked, setIsFirstToggleChecked] = useState(true);
  const [isSecondToggleChecked, setIsSecondToggleChecked] = useState(true);
  // ============== Fun & Logic & Integration ==============
  const onFirstToggleHandler = () => {
    setIsFirstToggleChecked(!isFirstToggleChecked);
  };
  const onSecondToggleHandler = () => {
    setIsSecondToggleChecked(!isSecondToggleChecked);
  };
  // Handle Edit Function to make it Reusable
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileOverview({
      ...profileOverview,
      [name]: value,
    });
  };
  const handleEditing = (field) => {
    return isProfileEditing ? (
      <input
        type="text"
        name={field}
        value={profileOverview[field]}
        onChange={handleInputChange}
        className={styles.edit_input_style}
      />
    ) : (
      <p>{profileOverview[field]}</p>
    );
  };
  // Function to Hide Salary
  const handleCheckboxChange = (e) => {
    if (isProfileEditing) {
      setProfileOverview((prevState) => ({
        ...prevState,
        hide_salary: !isChecked,
      }));
      setIsChecked(!isChecked);
    }
  };
  // ==== Effect to set profile data based on where the navigation came from ===
  useEffect(() => {
    if (from === "CVsPage") {
      setProfileOverview((prevData) => ({
        ...prevData,
        expected_salary: userCvsDetailsData?.expected_salary || "0",
        mobile_phone: userCvsDetailsData?.phone || "Add your Mobile phone",
        home_phone: userCvsDetailsData?.home_phone || "Add your Home phone",
        address: userCvsDetailsData?.address || "Add your address",
      }));
    } else {
      setProfileOverview((prevData) => ({
        ...prevData,
        expected_salary: userProfileData?.expected_salary || "0",
        mobile_phone: userProfileData?.phone || "Add your Mobile phone",
        home_phone: userProfileData?.home_phone || "Add your Home phone",
        address: userProfileData?.address || "Add your address",
      }));
    }
    if (from === "CVsPage") {
      setProfileOverview((prevData) => ({
        ...prevData,
        setIsChecked: userCvsDetailsData?.hide_salary || "",
      }));
    } else {
      setProfileOverview((prevData) => ({
        ...prevData,
        setIsChecked: userProfileData?.hide_salary || "",
      }));
    }
  }, [from, userCvsDetailsData, userProfileData]);
  // ====== Function To Send Data To API ======
  const toggleProfileOverviewEdit = () => {
    if (isProfileEditing) {
      // Save data when exiting edit mode
      const personalOverviewData = {
        expected_salary: Number(profileOverview?.expected_salary),
        home_phone: profileOverview?.home_phone,
        mobile_phone: profileOverview?.mobile_phone,
        address: profileOverview?.address,
        hide_salary: profileOverview?.hide_salary,
      };
      dispatch(myProfileOverview(personalOverviewData));
    }
    setIsProfileEditing((prev) => !prev);
  };
  return (
    <div>
      {/* Start Profile Overview */}
      <div className={styles.personal_overview_parent}>
        <div
          className={
            i18n.language === "en"
              ? styles.editicon_parent_en
              : styles.editicon_parent_ar
          }
        >
          {from === "CVsPage" ? (
            ""
          ) : loading ? (
            "..."
          ) : isProfileEditing ? (
            <img
              src={SaveEdit}
              onClick={toggleProfileOverviewEdit}
              alt="Save"
            />
          ) : (
            <img
              src={editIcon}
              onClick={toggleProfileOverviewEdit}
              alt="Edit"
            />
          )}
        </div>
        <h3 className={`${styles.section_title} pb-4`}>
          {t("ProfileOverview")}
        </h3>
        <div
          className={`${styles.overview_boxes} d-flex justify-content-center align-items-center`}
        >
          <div className="px-5 py-4 d-flex flex-column align-items-center">
            <p
              className="d-flex align-items-center"
              style={
                profileOverview?.hide_salary === "yes"
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {from === "CVsPage" && profileOverview?.hide_salary === "yes"
                ? "Hidden"
                : handleEditing("expected_salary")}
            </p>
            <span> Hourly Rate</span>
          </div>
          <div className="px-5 py-4 d-flex flex-column align-items-center">
            <p>0</p>
            <span>{t("JobsDone")}</span>
          </div>
        </div>
        <h3 className={`${styles.section_title} py-4`}> Contact details</h3>
        <div className={`d-flex flex-column gap-4 ${styles.num_addres}`}>
          <span className="d-flex">
            <img
              className={`${styles.icon_style} me-2`}
              src={Mobile}
              alt="phone"
            />
            {handleEditing("mobile_phone")}
          </span>
          <span className="d-flex">
            <img
              className={`${styles.icon_style} me-2`}
              src={phone}
              alt="phone"
            />
            {handleEditing("home_phone")}
          </span>
          <span className="d-flex">
            <img
              className={`${styles.icon_style} me-2`}
              src={Home}
              alt="address"
            />
            {handleEditing("address")}
          </span>
        </div>
        {from === "CVsPage" ? (
          ""
        ) : (
          <div className="d-flex align-items-center my-4">
            <input
              type="checkbox"
              name="hide_salary"
              id="hide_salary"
              checked={profileOverview?.hide_salary} //from === "CVsPage"
              style={{ display: "none" }} // Hide the checkbox
              onChange={isProfileEditing ? handleCheckboxChange : undefined}
            />
            <img
              id="hide_salary"
              src={checkBoxHandler}
              onClick={isProfileEditing ? handleCheckboxChange : undefined}
              className={`me-2 ${styles.check_box_salary}`}
              alt="checkbox"
            />
            <label htmlFor="hide_salary">{t("HideMinimumsalary")}</label>
          </div>
        )}
      </div>
      {/* End of Profile Overview */}
      {/* Start of  Social Profiles */}
      <div className="mt-4">
        <div className={styles.personal_overview_parent}>
        <div
        className={
          i18n.language === "en"
            ? styles.editicon_parent_en
            : styles.editicon_parent_ar
        }
      >
        {from === "CVsPage" ? (
          ""
        ) : loading ? (
          "..."
        ) : isProfileEditing ? (
          <img
            src={SaveEdit}
            onClick={toggleProfileOverviewEdit}
            alt="Save"
          />
        ) : (
          <img
            src={editIcon}
            onClick={toggleProfileOverviewEdit}
            alt="Edit"
          />
        )}
      </div>
          <h3 className={`${styles.section_title} pb-4`}> Social Profiles</h3>
          <div>
            <span className="d-flex">
              <img
                className={`${styles.facebook_icon} me-2`}
                src={Facebook}
                alt="phone"
              />
            </span>
          </div>
          {/*Start Skills */}
          <h3 className={`${styles.section_title} py-4`}>Skills</h3>
          <div className={`${styles.skills} ms-4 container`}>
            <div className="row">
              <div className="col-6 d-flex flex-column justify-content-start p-3">
                <p>60%</p>
                <div className={`${styles.skills_line}`}>
                  <div
                    className={styles.skills_line_fill}
                    style={{ width: "60%" }}
                  ></div>
                </div>
                <span>English</span>
              </div>
              <div className="col-6 d-flex flex-column justify-content-start p-3">
                <p>90%</p>
                <div className={`${styles.skills_line}`}>
                  <div
                    className={styles.skills_line_fill}
                    style={{ width: "90%" }}
                  ></div>
                </div>
                <span>Arabic</span>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex flex-column justify-content-start p-3">
                <p>25%</p>
                <div className={`${styles.skills_line}`}>
                  <div
                    className={styles.skills_line_fill}
                    style={{ width: "25%" }}
                  ></div>
                </div>
                <span>Computer</span>
              </div>
              <div className="col-6 d-flex flex-column justify-content-start p-3">
                <p>40%</p>
                <div className={`${styles.skills_line}`}>
                  <div
                    className={styles.skills_line_fill}
                    style={{ width: "40%" }}
                  ></div>
                </div>
                <span>MS Office</span>
              </div>
            </div>
          </div>
          <div className={`d-flex flex-column ms-4 gap-2 ${styles.num_addres}`}>
            <div key={"drivingLicence"} className={styles.checkbox_parent}>
              <input
                type="checkbox"
                id={"drivingLicence"}
                name={"drivingLicence"}
                value={"drivingLicence"}
              />
              <label htmlFor={"drivingLicence"}>Driving License</label>
            </div>
            <div key={"havingMotorCycle"} className={styles.checkbox_parent}>
              <input
                type="checkbox"
                id={"havingMotorCycle"}
                name={"havingMotorCycle"}
                value={"havingMotorCycle"}
              />
              <label htmlFor={"havingMotorCycle"}>Having Motorcycle</label>
            </div>
          </div>
        </div>
      </div>
      {/*End Skills */}
      {/* End of  Social Profiles */}
      {/*Start  Bookmarks & Share */}
      <div className="d-flex flex-column">
        <div className="d-flex flex-column ">
          <div className="my-4">
            <h3 className={`${styles.section_title} py-4`}>
              {" "}
              Bookmarks & Share
            </h3>
            <p className={`${styles.login_bookmark}`}>Login to bookmark</p>
          </div>
          <div className={`${styles.input_bookmarks}`}>
            <input
              type="text"
              placeholder=" https://shoghl.com/resume/peterwaldo"
            ></input>
            <div className={`${styles.copy_icon}`}>
              <img src={Copy} alt="copy_icon" />
            </div>
          </div>
          <div
            className={`d-flex align-items-center gap-2 pt-4 ${styles.share_link}`}
          >
            <div>
              <img src={Share} alt="Share icon" />
            </div>
            <span>Interesting?</span>
            <p>Share It!</p>
          </div>
        </div>

        <h3 className={`${styles.section_title} py-4`}> Public Profile</h3>
        {/*Toggle Buttons */}
        <div className={`${styles.toggle_buttons}`}>
        <label className={`${styles.switch_btn}`}>
        <input
          type="checkbox"
          checked={isFirstToggleChecked}
          onChange={onFirstToggleHandler}
        />
        <span className={`${styles.slider} ${styles.sliderRound}`}>
          {isFirstToggleChecked ? "ON" : "OFF"}
        </span>
      </label>
          <h3 className={`${styles.section_title} py-4`}>
            Let people find me easily
          </h3>
          <label className={`${styles.switch_btn}`}>
            <input
              type="checkbox"
              checked={isSecondToggleChecked}
              onChange={onSecondToggleHandler}
            />
            <span className={`${styles.slider} ${styles.sliderRound}`}>
              {isSecondToggleChecked ? "ON" : "OFF"}
            </span>
          </label>
        </div>
      </div>

      {/*End  Bookmarks & Share */}
    </div>
  );
}

export default RightSideUserProfile;
