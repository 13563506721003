import React, { useState } from "react";
import { IconPlusTitle } from "../../../components";
import {
  BlueSecondaryArrow,
  Dollar,
  Huwawi,
  King,
  location,
  People,
  UpperArrowTwo,
} from "../../../assets";
//styles
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
// Example array of jobs with their types
const jobs = [
  {
    types: ["Internship", "Full Time"],
    image: Huwawi,
    title: "Office Boy",
    active: true,
  },
  {
    types: ["Full Time"],
    image: Huwawi,
    title: " Restaurant Dishwasher",
    active: false,
  },
  {
    types: ["Freelance"],
    image: Huwawi,
    title: " Restaurant Dishwasher",
    active: false,
  },
  {
    types: ["Part Time"],
    image: Huwawi,
    title: " Restaurant Dishwasher",
    active: false,
  },
  {
    types: ["Part Time"],
    image: Huwawi,
    title: " Restaurant Dishwasher",
    active: false,
  },
];

function LeftHandSideCompany() {
  // Handle Expanded Text & See More
  const [isExpanded, setIsExpanded] = useState(false);
  const expandParagraph = (text, maxLength) => {
    return isExpanded ? text : `${text.substring(0, maxLength)} ...`;
  };
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const text =
    "Raya’s Trade Line of Business caters primarily to the consumer market through three main functions distribution, retail andmaintenance services for cellular phones and IT product under globalbrand names. Raya Trade sells directly to the retail market throughits stores, distributes through a nationwide network of wholesalersand provides maintenance for its products. The portfolio of productsoffered through the distribution, retail and maintenance servicefunctions includes cellul";
  //
  const responsive =
    "d-flex flex-column flex-lg-row justify-content-center align-items-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";

  // Function to get button classes based on job types
  const getButtonClasses = (types) => {
    const buttonClasses = {
      Internship: styles.job_status_btn_Internship,
      "Full Time": styles.job_status_btn_fulltime,
      Freelance: styles.job_status_btn_freelance,
      "Part Time": styles.job_status_btn_parttime,
    };
    return types.map((type) => buttonClasses[type] || "").join(" ");
  };

  const OpenVacancies = jobs.map((job, index) => (
    <Container>
      <div
        className={`${styles.vacancies} mb-2 row mx-2 text-center ${responsive}`}
        key={index}
      >
        <div className={`row  text-center ${responsive}`}>
          <div
            className={`row col-12 col-md-10 text-center text-md-start align-items-center ${responsive}`}
          >
            <div className="col-12 col-md-4 col-lg-2 d-flex align-items-center p-5 p-md-2">
              <img src={job.image} alt={"job title"} />
            </div>
            <div className={`col-10 ${styles.job_info} `}>
              <p className="py-1">{job.title}</p>
              <div
                className={`d-flex flex-column flex-md-row align-items-center gap-4 py-1 pb-3 pb-md-0`}
              >
                <IconPlusTitle
                  IconSrc={King}
                  title={"King"}
                  P_Style={`${styles.job_descrebtion}`}
                  className="order-2 order-md-1"
                />
                <IconPlusTitle
                  IconSrc={location}
                  title={"Stratford, London, United Kingdom"}
                  P_Style={`${styles.job_descrebtion}`}
                  className="order-3 order-md-2"
                />
              </div>
              <IconPlusTitle
                IconSrc={Dollar}
                title={"0 - 2 Yrs of Exp · males_only. 2500-6200 LE"}
                P_Style={`${styles.job_descrebtion}`}
              />
            </div>
          </div>
          <div className="col-12 col-lg-2 d-flex flex-column gap-2 align-items-center mt-4">
            {job.types.map((type, idx) => (
              <button
                key={idx}
                disabled
                className={`${getButtonClasses([type])}`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>
      </div>
    </Container>
  ));

  return (
    <div>
      {/*Company Profile*/}
      <div className={`${styles.company_profile} mb-2`}>
        <h5 className="ms-3 mb-2">Company Profile</h5>
        <div className="d-flex flex-column flex-md-row align-items-center justify-md-content-between gap-4 py-1">
          <div
            className={`d-flex align-items-center gap-1 py-1 ${styles.company_titles_descrebtion}`}
          >
            <IconPlusTitle
              IconSrc={location}
              title={"Location:"}
              P_Style={`${styles.job_descrebtion_compProfile}`}
            />
            <span>Cairo, Egypt</span>
          </div>
          <div
            className={`d-flex align-items-center gap-1 py-1 ${styles.company_titles_descrebtion}`}
          >
            <IconPlusTitle
              IconSrc={King}
              title={"Industry:"}
              P_Style={`${styles.job_descrebtion_compProfile}`}
            />
            <span>Consumer Electronics . Retail</span>
          </div>
        </div>
        <div
          className={`d-flex align-items-center gap-2 py-1 ${styles.company_titles_descrebtion}`}
        >
          <IconPlusTitle
            IconSrc={People}
            title={"Company Size:"}
            P_Style={`${styles.job_descrebtion_compProfile}`}
          />
          <span>501-1000 employees</span>
        </div>
        <div className="">
          <p className={`ms-4 ${styles.Company_info} `}>
            {expandParagraph(text, 300)}
            <Link
              to={""}
              className={`align-items-center ms-2 ${styles.see_more_link}`}
              onClick={() => toggleReadMore()}
            >
              {isExpanded ? "Less More" : "See More"}
              <img
                src={isExpanded ? UpperArrowTwo : BlueSecondaryArrow}
                alt="arrow"
                className={`d-inline`}
              />
            </Link>
          </p>
        </div>
      </div>
      {/* Open vacancies at Raya Distribution */}
      <div className={`${styles.open_vacancy}`}>
        <h2> Open vacancies at Raya Distribution</h2>
        <div>{OpenVacancies}</div>
      </div>
    </div>
  );
}

export default LeftHandSideCompany;
