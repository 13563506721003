import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { InputField } from "../../../components";
//Styles
import styles from "./styles.module.css";
import {
  AddIcon,
  rectangleCheckBox,
  rectangleCheckBoxVector,
} from "../../../assets";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function StepOne({ onStepDataChange }) {
  const { i18n } = useTranslation();
  const {
    careerLevelsData,
    jobTypesData,
    workSpaceSettingData,
    salaryPerData,
    allCurrenciesData,
  } = useSelector((state) => state.helpers);
  //state
  const [currentCareerActive, setCurrentCareerActive] = useState("");
  const [typeOfJobActive, setTypeOfJobActive] = useState([]);
  const [typeOfJobID, setTypeOfJobID] = useState([]);
  const [workSpaceActive, setWorkSpaceActive] = useState([]);
  const [workSpaceID, setWorkSpaceID] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCurrencyID, setSelectedCurrencyID] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedPeriodID, setSelectedPeriodID] = useState("");

  const handleCurrencyChange = (event) => {
    const selectCurrencyID = allCurrenciesData?.data?.find(
      (value) => value.default_name === event.target.value
    );
    if (selectCurrencyID) {
      setSelectedCurrencyID(selectCurrencyID.code);
    }
    setSelectedCurrency(event.target.value);
  };

  const handlePeriodChange = (event) => {
    const selectedPeriodID = salaryPerData?.data?.find(
      (value) => value.default_name === event.target.value
    );

    if (selectedPeriodID) {
      setSelectedPeriodID(selectedPeriodID.code);
    }
    setSelectedPeriod(event.target.value);
  };
  // Refs
  const salaryRef = useRef(null);
  const salary_currencyRef = useRef(null);
  const per_timeRef = useRef(null);
  //variables
  const checkBoxHandler = isChecked
    ? rectangleCheckBoxVector
    : rectangleCheckBox;

  // current Career Level Section
  const currentCareerHandler = (el) => {
    setCurrentCareerActive(el.code);
  };
  // Collect All Step One Data
  useEffect(() => {
    const stepOneData = {
      career_level: String(currentCareerActive),
      job_types: typeOfJobID,
      workspace_setting: workSpaceID,
      minimum_net_salary: salaryRef.current.value,
      minimum_net_salary_currency: selectedCurrencyID,
      minimum_net_salary_per: selectedPeriodID,
      minimum_net_salary_hide: isChecked ? "yes" : "no",
    };
    onStepDataChange(stepOneData); // Update parent with current step data
  }, [
    currentCareerActive,
    typeOfJobID,
    workSpaceID,
    salaryRef.current?.value,
    selectedCurrencyID,
    selectedPeriodID,
    isChecked,
    onStepDataChange,
  ]);

  const typeOfJobHandler = (jobType) => {
    setTypeOfJobActive((prev) => {
      if (prev.includes(jobType)) {
        // Remove from active if already selected
        return prev.filter((type) => type !== jobType);
      } else {
        // Add to active if not selected
        return [...prev, jobType];
      }
    });
    setTypeOfJobID((prev) => {
      if (prev.some((code) => code === jobType?.code)) {
        // Remove from active if already selected
        return prev.filter((code) => code !== jobType?.code);
      } else {
        // Add to active if not selected
        return [...prev, jobType?.code];
      }
    });
  };

  const workSpaceHandler = (jobType) => {
    setWorkSpaceActive((prev) => {
      if (prev.includes(jobType)) {
        // Remove from active if already selected
        return prev.filter((type) => type !== jobType);
      } else {
        // Add to active if not selected
        return [...prev, jobType];
      }
    });

    setWorkSpaceID((prev) => {
      if (prev.some((code) => code === jobType.code)) {
        // Remove from active if already selected
        return prev.filter((code) => code !== jobType.code);
      } else {
        // Add to active if not selected
        return [...prev, jobType.code];
      }
    });
  };

  return (
    <main className="d-flex flex-column align-items-center">
      <div className="d-flex flex-column align-items-center mb-5">
        <h3 className={`${styles.form_page_title} mb-3`}>
          {t("CareerInterests")}
        </h3>
        <p className="text-center">{t("CareerInterestsText")}</p>
      </div>

      {/*Start Form */}
      <div className={`${styles.form_content}`}>
        <Container className="d-flex flex-column">
          {/*Start First Section*/}
          <section className="mb-5">
            <div className="mb-4">
              <h6>{t("WhatIsYourCurrentCareerLevel")}</h6>
            </div>
            <div className={`row d-flex justify-content-between container`}>
              {careerLevelsData?.data?.map((el) => (
                <button
                  onClick={() => currentCareerHandler(el)}
                  key={el.code}
                  className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${
                    styles.current_level_result
                  } ${currentCareerActive === el.code ? styles.active : ""}`}
                >
                  {el.default_name}
                </button>
              ))}
            </div>
          </section>
          {/*End First Section*/}
          {/*Start Second Section*/}
          <section className={`${styles.background_forms}  my-5 container`}>
            <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center">
              <h6>{t("WhatTypeOfJobAreYouOpenTo")}</h6>
              <span>{t("YouCanChooseMoreThanOneJobType")}</span>
            </div>

            {/* Display Selected Job Types */}
            <div className={`row mb-3`}>
              {typeOfJobActive.map((el) => (
                <button
                  key={el.code}
                  className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow pe-4 ${styles.btn_hover} ${styles.job_type} ${styles.active}`}
                  onClick={() => typeOfJobHandler(el)}
                >
                  {el.default_name}
                  <img src={AddIcon} alt="Remove Icon" />
                </button>
              ))}
            </div>
            {typeOfJobActive.length !== 0 && <hr />}
            {/* Display Available Job Types */}

            <div className={`row`}>
              {jobTypesData?.data
                .filter((el) => !typeOfJobActive.includes(el)) // Show only unselected job types
                .map((el) => (
                  <button
                    key={el.code}
                    className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow pe-4 ${styles.btn_hover} ${styles.job_type}`}
                    onClick={() => typeOfJobHandler(el)}
                  >
                    {el.default_name}
                    <img src={AddIcon} alt="Add Icon" />
                  </button>
                ))}
            </div>
          </section>
          {/*End Second Section*/}
          {/*Start Third Section*/}
          <section className={`${styles.background_forms}  my-5 container`}>
            <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center ">
              <h6>{t("WhatYourPreferredWorkspaceSettings")}</h6>
              <span>{t("YourCanChooseMoreThanWorkspaceSetting")}</span>
            </div>
            <div className={`row`}>
              {/* Display Selected Job Types */}
              <div className={`row mb-3`}>
                {workSpaceActive.map((el) => (
                  <button
                    key={el.code}
                    className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow ${styles.btn_hover}  ${styles.workspace_setting}`}
                    onClick={() => workSpaceHandler(el)}
                  >
                    {el.default_name}
                    <img src={AddIcon} alt="Add Icon" />
                  </button>
                ))}
              </div>
              {workSpaceActive.length !== 0 && <hr />}
              {/* Display Available Job Types */}
              <div className={`row`}>
                {workSpaceSettingData?.data
                  .filter((el) => !workSpaceActive.includes(el)) // Show only unselected job types
                  .map((el) => (
                    <button
                      key={el.code}
                      className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow ${styles.btn_hover}  ${styles.workspace_setting}`}
                      onClick={() => workSpaceHandler(el)}
                    >
                      {el.default_name}
                      <img src={AddIcon} alt="Add Icon" />
                    </button>
                  ))}
              </div>
            </div>
          </section>
          {/*End Third Section*/}
          {/*Start Forth Section*/}
          {/* <section className={`${styles.background_forms} my-5 container`}>
          <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center ">
            <h6>What Type(S) Of job are you to?</h6>
            <span>Add 1 or More</span>
          </div>
          <div className="my-5">
            <InputField
              placeholder="Select"
              fieldName="options"
              id="input9"
              style={styles.text_component_style}
              value={jobStatusActive.join(" , ")} // Display the selected jobs as a string
              onChange={handleInputChange}
            />
          </div>
          <div className={`row`}>
            <div>
              <span className="me-3">Suggestion:</span>
              {workSpaceSettingData?.data.map((job) => (
                <button
                  key={job.code}
                  title={job}
                  className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow ${styles.btn_hover} ${styles.workspace_setting}`}
                  onClick={() => selectJobHandler(job.default_name)} // <-- Corrected here
                >
                  {job.default_name}
                  <img src={AddIcon} alt="Add Icon" />
                </button>
              ))}
            </div>
          </div>
        </section> */}

          {/*End Forth Section*/}
          {/*Start Fifth Section*/}
          <section className={`${styles.background_forms}  mt-5 container`}>
            <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center ">
              <h6>{t("WhatIsTheMinimumYouWouldAccept")}</h6>
              <span>{t("AddANetSalary")}</span>
            </div>
            <div className=" row my-5">
              <div className="col-12 col-md-5">
                <InputField
                  placeholder={t("TypeYourSalary")}
                  fieldName="options"
                  style={styles.text_component_style}
                  ref={salaryRef}
                />
              </div>
              <div className="col-12 col-md-5">
                <InputField
                  placeholder={t("SelectYourCurrency")}
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  isList={true}
                  list={allCurrenciesData?.data?.map(
                    (curr) => curr.default_name
                  )}
                  fieldName="options"
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                  ref={salary_currencyRef}
                />
              </div>
              <div className="col-12 col-md-5">
                <InputField
                  placeholder={t("SelectTheperiod")}
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                  isList={true}
                  list={salaryPerData?.data?.map(
                    (salary) => salary.default_name
                  )}
                  fieldName="options"
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                  ref={per_timeRef}
                />
              </div>
            </div>
            {/*Check Box Hide Salary */}
            <div className="d-flex align-items-center my-4">
              <input
                type="checkbox"
                id="hide_salary"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                style={{ display: "none" }} // Hide the checkbox
              />
              <img
                id="hide_salary"
                src={checkBoxHandler}
                onClick={() => setIsChecked(!isChecked)}
                className={`${i18n.language === "ar" ? "ms-2" : "me-2"} ${
                  styles.check_box_salary
                }`}
                alt="checkbox"
              />
              <label htmlFor="hide_salary">{t("HideMinimumSalary")}</label>
            </div>
          </section>
          {/*End Fifth Section*/}
        </Container>
      </div>
      {/*End Form */}
    </main>
  );
}

export default StepOne;
