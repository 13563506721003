import React, { useState } from "react";
import { UpperArrowTwo, DownArrow, Search } from "../../../assets";
import styles from "./styles.module.css";
import { t } from "i18next";

function RightHandSide() {
  // State to manage the visibility of each checkbox block
  //
  const [viewMoreState, setViewMoreState] = useState({});
  const [visibleBlocks, setVisibleBlocks] = useState({
    workSamples: { visibality: false, newFeature: true, numberOfResults: "6" },
    byLocation: { visibality: false, newFeature: false, numberOfResults: "" },
    byExperience: { visibality: false, newFeature: false, numberOfResults: "" },
    byEducation: {
      visibality: false,
      newFeature: false,
      numberOfResults: "11",
    },
    verifiedBadges: {
      visibality: false,
      newFeature: false,
      numberOfResults: "1",
    },
    otherPreference: {
      visibality: false,
      newFeature: false,
      numberOfResults: "1",
    },
  });

  // Function to toggle visibility of a specific block
  const toggleBlockVisibility = (blockName) => {
    setVisibleBlocks((prevState) => ({
      ...prevState,
      [blockName]: {
        ...prevState[blockName],
        visibality: !prevState[blockName].visibality,
      },
    }));
  };
  //View Less and more Checkbox Handler
  const viewMoreHandler = (title) => {
    setViewMoreState((prevState) => ({
      ...prevState,
      [title]: !prevState[title], // Toggle the state for the specific section
    }));
  };
  
  // Checkbox content for different blocks
  const workSamples = [
    {
      title: "Country",
      elements: [
        { element: "Egypt", resultsNum: "480,906" },
        { element: "india", resultsNum: "48,6" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
      ],
    },
    {
      title: "City",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
    {
      title: "Area",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
  ];
  const byLocation = [
    {
      title: "Country",
      elements: [
        { element: "Egypt", resultsNum: "480,906" },
        { element: "india", resultsNum: "48,6" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
      ],
    },
    {
      title: "City",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
    {
      title: "Area",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
  ];
  const byExperience = [
    {
      title: "Country",
      elements: [
        { element: "Egypt", resultsNum: "480,906" },
        { element: "india", resultsNum: "48,6" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
      ],
    },
    {
      title: "City",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
    {
      title: "Area",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
  ];
  const byEducation = [
    {
      title: "Country",
      elements: [
        { element: "Egypt", resultsNum: "480,906" },
        { element: "india", resultsNum: "48,6" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
      ],
    },
    {
      title: "City",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
    {
      title: "Area",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
  ];
  const verifiedBadges = [
    {
      title: "Country",
      elements: [
        { element: "Egypt", resultsNum: "480,906" },
        { element: "india", resultsNum: "48,6" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
      ],
    },
    {
      title: "City",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
    {
      title: "Area",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
  ];
  const otherPreference = [
    {
      title: "Country",
      elements: [
        { element: "Egypt", resultsNum: "480,906" },
        { element: "india", resultsNum: "48,6" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
        { element: "KSA", resultsNum: "21,27" },
      ],
    },
    {
      title: "City",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
    {
      title: "Area",
      elements: [
        { element: "Alex", resultsNum: "480,906" },
        { element: "Cairo", resultsNum: "48,6" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
        { element: "Dubai", resultsNum: "21,27" },
      ],
    },
  ];

  const createCheckBoxList = (items) =>
    items.map((element) => (
      <div key={element.index}>
        <p className={styles.checkbox_title}>{element.title}</p>
        {/* Display only 5 elements if viewMore for this section is false */}
        {element.elements
          .slice(0, viewMoreState[element.title] ? element.elements.length : 5)
          .map((el) => (
            <div key={el.index} className={styles.checkbox_parent}>
              <input
                type="checkbox"
                id={el.element}
                name={el.element}
                value={el.element}
              />
              <label htmlFor={el.element}>{el.element}</label>
              <span className="ms-1">({el.resultsNum})</span>
            </div>
          ))}
        {/* View More / View Less Button */}
        <span
          className={styles.view_more}
          onClick={() => viewMoreHandler(element.title)}
        >
          {viewMoreState[element.title] ? "- View Less" : "+ View More"}
        </span>
        <div className={`mb-4 ${styles.search_bar}`}>
          <img src={Search} alt="Search" />
          <input type="text" placeholder={element.title} />
        </div>
      </div>
    ));

  const newFeature = <span className={`${styles.New_feature}`}>New</span>;
  //function to determine Number of new Features
  const numberOfFeatureHandler = (element) => {
    if (element !== "") {
      return <span className={`${styles.feature_number}`}>{element}</span>;
    } else {
      return null;
    }
  };

  return (
    <div>
      <div className={`${styles.filter_container} p-4`}>
        <h5 className="pb-1">{t("FilterCVs")}</h5>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.border_bottom}`}
        >
          <span>1 {t("filterSelected")}</span>
          <p>{t("ClearAllFilters")}</p>
        </div>

        {/* workSamples Block */}
        <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              Work Samples
              {visibleBlocks.workSamples.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(
                visibleBlocks.workSamples.numberOfResults
              )}
            </h5>
            <img
              src={
                visibleBlocks.workSamples.visibality ? UpperArrowTwo : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("workSamples")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.workSamples.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(workSamples)}
          </div>
        </div>

        {/* byLocation Block */}
        <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              By Location
              {visibleBlocks.byLocation.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(visibleBlocks.byLocation.numberOfResults)}
            </h5>
            <img
              src={
                visibleBlocks.byLocation.visibality ? UpperArrowTwo : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("byLocation")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.byLocation.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(byLocation)}
          </div>
        </div>

        {/* byExperience Block */}
        <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              By Experience{" "}
              {visibleBlocks.byExperience.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(
                visibleBlocks.byExperience.numberOfResults
              )}
            </h5>
            <img
              src={
                visibleBlocks.byExperience.visibality
                  ? UpperArrowTwo
                  : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("byExperience")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.byExperience.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(byExperience)}
          </div>
        </div>

        {/* byEducation Block */}
        <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              By Education
              {visibleBlocks.byEducation.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(
                visibleBlocks.byEducation.numberOfResults
              )}
            </h5>
            <img
              src={
                visibleBlocks.byEducation.visibality ? UpperArrowTwo : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("byEducation")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.byEducation.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(byEducation)}
          </div>
        </div>

        {/* Verified Badges Block */}
        <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              Verified Badges
              {visibleBlocks.verifiedBadges.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(
                visibleBlocks.verifiedBadges.numberOfResults
              )}
            </h5>
            <img
              src={
                visibleBlocks.verifiedBadges.visibality
                  ? UpperArrowTwo
                  : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arr"
              onClick={() => toggleBlockVisibility("verifiedBadges")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.verifiedBadges.visibality
                ? "block"
                : "none",
            }}
          >
            {createCheckBoxList(verifiedBadges)}
          </div>
        </div>
        {/* Other Preference Block */}
        <div>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              Other Preference
              {visibleBlocks.otherPreference.newFeature ? newFeature : ""}{" "}
              {numberOfFeatureHandler(
                visibleBlocks.otherPreference.numberOfResults
              )}
            </h5>
            <img
              src={
                visibleBlocks.otherPreference.visibality
                  ? UpperArrowTwo
                  : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arr"
              onClick={() => toggleBlockVisibility("otherPreference")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.otherPreference.visibality
                ? "block"
                : "none",
            }}
          >
            {createCheckBoxList(otherPreference)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightHandSide;

