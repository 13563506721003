import React, { useState } from "react";
import footerStyles from './styles.module.css'
import { Link } from "react-router-dom";
import InputField from "../InputField";
import Button from "../Button";
import { bag, envelope, friends, logoBlue } from "../../assets";

function Footer() {
    const [subMail, setSubMail] = useState('')

    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.footer_header}>
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="flex-grow-1 d-flex justify-content-start py-3">
                        <div className={footerStyles.footer_logo_Box}>
                            <img src={logoBlue} alt="logo" className="img" />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className={`${footerStyles.footer_boxed} d-flex align-items-center px-2 px-md-5 py-3`}>
                            <div className={`${footerStyles.img_box} p-2 me-3`}>
                                <img src={bag} alt="bag" className="img" />
                            </div>
                            <div>
                                <p className={`${footerStyles.footer_section_title} mb-0`}>210</p>
                                <p className={`${footerStyles.footer_section_link} mb-0`}>Job Listings</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="d-flex align-items-center ps-2 ps-md-5 py-3">
                            <div className={`${footerStyles.img_box} p-2 me-3`}>
                                <img src={friends} alt="friends" className="img" />
                            </div>
                            <div>
                                <p className={`${footerStyles.footer_section_title} mb-0`}>78</p>
                                <p className={`${footerStyles.footer_section_link} mb-0`}>Resumes Posted</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={footerStyles.footer_content}>
                <div className="container">
                    <div className={"row justify-content-between my-5"}>
                        <div className="d-flex justify-content-between flex-grow-1 col-12 col-lg-6 pe-lg-5">
                            <div>
                                <p className={`${footerStyles.footer_section_title} mb-3`}>For Candidates</p>
                                <div className={footerStyles.footer_section_links}>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>browse jobs</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>browse categories</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>candidate dashboard</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>job alerts</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>my bookmarks</Link>
                                </div>
                            </div>
                            <div>
                                <p className={`${footerStyles.footer_section_title} mb-3`}>For Employers</p>
                                <div className={footerStyles.footer_section_links}>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>browse candidates</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>employer dashboard</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>add job</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>job packages</Link>
                                </div>
                            </div>
                            <div>
                                <p className={`${footerStyles.footer_section_title} mb-3`}>Other</p>
                                <div className={footerStyles.footer_section_links}>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>job page</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>task page</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>resume page</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>blog</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>contact</Link>
                                </div>
                            </div>
                            <div>
                                <p className={`${footerStyles.footer_section_title} mb-3`}>Legal</p>
                                <div className={footerStyles.footer_section_links}>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>privacy policy</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>terms of use</Link>
                                    <Link className={footerStyles.footer_section_link} to={'/'}>FAQ</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-5 mt-4 mt-lg-0">
                            <div className="d-flex mb-3">
                                <div className="me-2">
                                    <img src={envelope} alt="envelope" className="img" />
                                </div>
                                <p className={`${footerStyles.footer_subscribe_title} mb-0`}>
                                    sign up for a newsletter
                                </p>
                            </div>
                            <p className={footerStyles.footer_subscribe_txt}>Weekly breaking news, analysis and cutting edge advices on job searching.</p>
                            <div className={`${footerStyles.footer_subscribe_box} d-flex align-items-baseline`}>
                                <InputField placeholder={'Enter Your Email'} value={subMail} onChange={(e) => setSubMail(e.target.value)} inputStyle={footerStyles.footer_subscribe_input} style={'flex-grow-1'} />
                                <Button title={'subscribe'} onClick={() => setSubMail('')} style={`${footerStyles.footer_subscribe_btn}`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={footerStyles.footer_copyright}>
                <div className="container">
                    <p className={`${footerStyles.copy_right} py-3`}>© Theme by Purethemes.net. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
