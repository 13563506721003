import { editIcon } from "../../assets";
//style
import editedbox from "./styles.module.css";
const { edited_icon } = editedbox;
function EditedBox({ style, children , customIconEdited}) {
  return (
    <div className={`${style} position-relative`}>
      <div>{children}</div>
      <img src={editIcon} className={`${edited_icon} ${customIconEdited}`} />
    </div>
  );
}

export default EditedBox;
