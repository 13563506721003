import { Container } from "react-bootstrap";
import UserProfileHeader from "./UserProfileHeader";
import LeftSideUserProfile from "./LeftSideUserProfile";
import RightSideUserProfile from "./RightSideUserProfile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMyProfile } from "../../redux/slices/authSlice";
import { getAllCountries } from "../../redux/slices/helperSlice";

function UserProfile() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyProfile());
    dispatch(getAllCountries());
  }, [dispatch]);
  return (
    <div>
      <UserProfileHeader />
      <Container>
        <div className="row px-5 py-2 m-auto">
          <div className="col-12 col-md-8 order-lg-1 order-md-1 order-2">
            <LeftSideUserProfile />
          </div>
          <div className=" ps-0 ps-md-5 py-5 py-md-0 col-md-4 order-lg-2 order-md-2 order-1">
            <RightSideUserProfile />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UserProfile;
