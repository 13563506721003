import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import { t } from "i18next";
import { Button } from "../../../components";
import { AddIcon } from "../../../assets";

function StepSix({onStepDataChange}) {
  const [uploadedFile, setUploadedFile] = useState(null);
  // ================================ Refs ================================
  const fileInputRef = useRef(null);

  const handleUploadClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
    ];

    if (!file) {
      alert("The Document field must be a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      alert("The Document field must be a file of type: docx, doc, pdf.");
      return;
    }

    // If the file passes the validation, set it
    setUploadedFile(file);
    onStepDataChange(file)
  };
  const handleDeleteClick = () => {
    setUploadedFile(null);
    fileInputRef.current.value = null; // Reset the input file
  };
  return (
    <main
      className={` flex-column d-flex justify-content-center position-relative ${styles.page_container}`}
    >
      <div
        className={`d-flex flex-column align-items-center py-4 mt-2 ${styles.page_header}`}
      >
        <p>{t("MainStep")}</p>
        <p>Almost There!</p>
      </div>
      <form>
      {/* <div className={`row mb-3`}>
            {Categ.map((el) => (
              <button
                key={el.code}
                className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow pe-4 ${styles.btn_hover} ${styles.job_type} ${styles.active}`}
                onClick={() => typeOfJobHandler(el)}
              >
                {el.default_name}
                <img src={AddIcon} alt="Remove Icon" />
              </button>
            ))}
          </div> */}
          {/* {typeOfJobActive.length !== 0 && <hr />} */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className=" d-flex flex-column flex-sm-row gap-3 align-items-center">
            <h6>Upload your CV</h6>
            <span>- Optional</span>
          </div>
          <span >
            Supported file types pdf, doc, docx & ODT.
          </span>
          <div className="mt-4 w-100 d-flex flex-column align-items-start">
            <Button
              title={"Upload CV"}
              style={styles.cv_btn}
              onClick={handleUploadClick}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {uploadedFile && (
              <div className="mt-3">
                <p className="mb-1">
                  {t("UploadedFile")}: {uploadedFile.name}
                </p>
                <Button
                  title={"Delete"}
                  style={`px-3 py-2`}
                  onClick={handleDeleteClick}
                />
              </div>
            )}
          </div>
        </section>
      </form>
    </main>
  );
}

export default StepSix;
