import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import JobsApplicationsHeader from "./JobsApplicationsHeader";
import JobsAppRightHandSide from "./JobsAppRightHandSide";
import JobsAppLeftHandSide from "./JobsAppLeftHandSide";

function JobsApplications() {
  return (
    <main>
      <JobsApplicationsHeader />
      <Container>
        <div className="row py-2 m-auto">
          <div className="col-12 col-md-8 order-lg-1 order-md-1 order-2">
            <JobsAppLeftHandSide />
          </div>
          <div className="ps-md-5 py-5 col-md-4 d-md-flex d-lg-block order-lg-2 order-md-2 order-1">
            <JobsAppRightHandSide />
          </div>
        </div>
      </Container>
    </main>
  );
}

export default JobsApplications;
