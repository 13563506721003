import React, { useEffect, useState } from "react";
import { UpperArrowTwo, DownArrow } from "../../../assets";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllWithFilter,
} from "../../../redux/slices/jobsSlice";
import { Button } from "../../../components";
import { t } from "i18next";
import { getAllCities, getCareerLevels, getWorkSpaceSetting } from "../../../redux/slices/helperSlice";

function RightHandSide() {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState({
    contract_typeData: [],
    cityData: [],
    career_levelData: [],
  });
  console.log(filteredData)
  const { cities , workSpaceSettingData ,careerLevelsData} = useSelector((state) => state.helpers);
  // State to manage the visibility of each checkbox block
  const [visibleBlocks, setVisibleBlocks] = useState({
    workplace: { visibality: false, newFeature: true },
    city: { visibality: false, newFeature: false },
    careerLevel: { visibality: false, newFeature: false },
  });
  const [counter, setCounter] = useState(0);
  // Function to toggle visibility of a specific block
  const toggleBlockVisibility = (blockName) => {
    setVisibleBlocks((prevState) => ({
      ...prevState,
      [blockName]: {
        ...prevState[blockName],
        visibality: !prevState[blockName].visibality,
      },
    }));
  };
  //=================
  const createCheckBoxList = (items, blockTitle) =>
    items?.map((element) => (
      <div key={element.code} className={styles.checkbox_parent}>
        <input
          type="checkbox"
          id={element.default_name}
          name={element.default_name}
          value={element.default_name}
          checked={filteredData[blockTitle].includes(
            blockTitle === "cityData" ? element.code : element.code
          )} // Check based on state
          onChange={() => filterDataHandler(element, blockTitle)} // Use onChange instead of onClick
        />
        <label htmlFor={element.default_name}>{element.default_name}</label>
      </div>
    ));

  const filterDataHandler = (element, blockTitle) => {
    setFilteredData((prevData) => {
      const selectedValues = prevData[blockTitle];

      // Determine whether to use element.name_en or element.code based on blockTitle
      const valueToUse = blockTitle === "cityData" ? element.code : element.code;

      // Check if the value is already selected
      if (selectedValues.includes(valueToUse)) {
        // Remove the value if it's already selected
        setCounter(counter - 1);
        return {
          ...prevData,
          [blockTitle]: selectedValues.filter((value) => value !== valueToUse),
        };
      } else {
        // Add the value if it's not selected
        setCounter(counter + 1);
        return {
          ...prevData,
          [blockTitle]: [...selectedValues, valueToUse],
        };
      }
    });
  };
  const clearFilterHandler = () => {
    // Reset the filtered data
    setFilteredData({
      contract_typeData: [],
      cityData: [],
      career_levelData: [],
    });

    // Reset the counter
    setCounter(0);
    // Toggle visibility for multiple blocks: city, careerLevel, and workplace
    setVisibleBlocks((prevState) => ({
      ...prevState,
      city: {
        ...prevState.city,
        visibality: false,
      },
      careerLevel: {
        ...prevState.careerLevel,
        visibality: false,
      },
      workplace: {
        ...prevState.workplace,
        visibality: false,
      },
    }));
  };
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  useEffect(() => {
    // Define your data based on filteredData
    const data = {
      contract_type: filteredData.contract_typeData,
      city: filteredData.cityData,
      career_level: filteredData.career_levelData,
    };

    // Check if any filter is applied
    const hasFilters = data.contract_type || data.city || data.career_level;

    // Dispatch only if filters are applied and `isFilterApplied` is true
    if (isFilterApplied && hasFilters) {
      dispatch(getAllWithFilter(data));
    }
    setIsFilterApplied(false);
  }, [dispatch, isFilterApplied, filteredData]);

  // Function to apply filters and set the flag
  const applyFilters = () => {
    setIsFilterApplied(true);
    // Call this function when filters are actually applied by the user
  };

  //================

  const newFeature = (
    <span className={`${styles.New_feature}`}>{t("New")}</span>
  );
  //function to determine Number of new Features
  const numberOfFeatureHandler = (element) => {
    if (element !== "") {
      return <span className={`${styles.feature_number}`}>{element}</span>;
    } else {
      return null;
    }
  };
  useEffect(() => {
    dispatch(getAllCities(1));
    dispatch(getWorkSpaceSetting());
    dispatch(getCareerLevels());
  }, [dispatch]);


  return (
    <div>
      <div className={`${styles.filter_container} p-4`}>
        <h5 className="pb-1">{t("Filters")}</h5>
        <div className="d-flex justify-content-center mb-2">
          <Button
            title={t("ApplyFilters")}
            onClick={applyFilters}
            style={`py-1 px-2`}
          />
        </div>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.border_bottom}`}
        >
          <span>{counter + " " + t("filterSelected")} </span>
          <p onClick={clearFilterHandler}>{t("ClearAllFilters")}</p>
        </div>

        {/* Workplace Block */}
        <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              {t("WorkPlace")}
              {visibleBlocks.workplace.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(workSpaceSettingData?.data?.length)}
            </h5>
            <img
              src={
                visibleBlocks.workplace.visibality ? UpperArrowTwo : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("workplace")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.workplace.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(
              workSpaceSettingData?.data,
              "contract_typeData"
            )}
          </div>
        </div>

        {/* Country Block */}
        {/* <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              Country {visibleBlocks.country.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(visibleBlocks.country.numberOfResults)}
            </h5>
            <img
              src={visibleBlocks.country.visibality ? UpperArrowTwo : DownArrow}
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("country")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.country.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(country)}
          </div>
        </div>*/}

        {/* City Block */}
        <div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              {t("City")} {visibleBlocks.city.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(cities?.data?.length)}
            </h5>
            <img
              src={visibleBlocks.city.visibality ? UpperArrowTwo : DownArrow}
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("city")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.city.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(cities?.data, "cityData")}
          </div>
        </div>
        {/* Area Block */}
        {/*<div className={`${styles.border_bottom}`}>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              Area {visibleBlocks.area.newFeature ? newFeature : ""}
              {numberOfFeatureHandler(visibleBlocks.area.numberOfResults)}
            </h5>
            <img
              src={visibleBlocks.area.visibality ? UpperArrowTwo : DownArrow}
              className={styles.dropdown_imgs}
              alt="arrow"
              onClick={() => toggleBlockVisibility("area")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.area.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(area)}
          </div>
        </div> */}

        {/* Career Level Block */}
        <div>
          <div className="pt-4 d-flex align-items-center justify-content-between">
            <h5 className="pb-1">
              {t("CareerLevel")}
              {visibleBlocks.careerLevel.newFeature ? newFeature : ""}{" "}
              {numberOfFeatureHandler(careerLevelsData?.data?.length)}
            </h5>
            <img
              src={
                visibleBlocks.careerLevel.visibality ? UpperArrowTwo : DownArrow
              }
              className={styles.dropdown_imgs}
              alt="arr"
              onClick={() => toggleBlockVisibility("careerLevel")}
            />
          </div>
          <div
            className="pt-2 ps-3"
            style={{
              transition: "0.3s",
              display: visibleBlocks.careerLevel.visibality ? "block" : "none",
            }}
          >
            {createCheckBoxList(
              careerLevelsData?.data,
              "career_levelData"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightHandSide;
