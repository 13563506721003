import React, { useEffect, useRef, useState } from "react";
import {
  cash,
  CompanyPhoto,
  EditPen,
  ExitXicon,
  FileIcon,
  King,
  location,
  Plus,
  RayaComp,
  Trash,
} from "../../../assets";
import { Container } from "react-bootstrap";
import { IconPlusTitle, InputField } from "../../../components";
//=================== Styles =======================
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { t } from "i18next";
import {
  editCompanyProfile,
  getCompanyEmployeeRange,
  getCompanyProfile,
  getIndustriesSearch,
  getSpecialtiesSearch,
} from "../../../redux/slices/jobsSlice";

const jobs = [
  {
    id: 1,
    types: ["Internship", "Full Time"],
    image: RayaComp,
    title: "Office Boy",
    active: true,
  },
  {
    id: 2,
    types: ["Full Time"],
    image: RayaComp,
    title: " Restaurant Dishwasher",
    active: false,
  },
  {
    id: 3,
    types: ["Freelance"],
    image: RayaComp,
    title: " Restaurant Dishwasher",
    active: false,
  },
];

function CompleteCompProLeft() {
  const dispatch = useDispatch();
  const { companyInfo } = useSelector((state) => state.auth);
  const { countries } = useSelector((state) => state.helpers);
  const {
    CompanyEmployeeRangeData,
    companyProfileData,
    industriesData,
    specialtiesData,
    allMyJobPostsData,
  } = useSelector((state) => state.jobs);

  console.log(
    `{
    CompanyEmployeeRangeData,
    companyProfileData,
    industriesData,
    specialtiesData,
    allMyJobPostsData,
  }`,
    {
      CompanyEmployeeRangeData,
      companyProfileData,
      industriesData,
      specialtiesData,
      allMyJobPostsData,
      companyInfo,
      countries,
    }
  );

  const fileInputRef = useRef(null);
  // Function to get button classes based on job types
  const getButtonClasses = (types) => {
    const buttonClasses = {
      Internship: styles.job_status_btn_Internship,
      "Full Time": styles.job_status_btn_fulltime,
      Freelance: styles.job_status_btn_freelance,
      "Free Lance / Project": styles.job_status_btn_freelance,
      "Shift based": styles.job_status_btn_freelance,
      "Part Time": styles.job_status_btn_parttime,
      "Student activity": styles.job_status_btn_parttime,
    };
    return types.map((type) => buttonClasses[type] || "").join(" ");
  };
  const responsive =
    "d-flex flex-column flex-lg-row justify-content-center align-items-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";
  /// =========================== Data =========================
  const OpenVacancies = allMyJobPostsData?.data?.map((job, index) => (
    <div
      className={`${styles.vacancies} mb-4  mt-4 row container mx-2 text-center ${responsive}`}
      key={index}
    >
      <div
        className={`row  text-center  ${responsive} ${styles.vacancies_content}`}
      >
        <div
          className={`row col-12 col-md-10 text-center text-md-start align-items-center ${responsive}`}
        >
          <div className="col-12 col-md-4 col-lg-2 d-flex align-items-center mt-5 p-5 p-md-0">
            <img src={CompanyPhoto} alt={"job.title"} />
          </div>
          <div className={`col-10 ${styles.job_info} `}>
            <p className="py-1 mb-2">{job.title}</p>
            <div
              className={`d-flex flex-column flex-md-row align-items-center gap-4 py-1 pb-3 pb-md-0`}
            >
              <IconPlusTitle
                IconSrc={King}
                title={job?.post_type?.default_name}
                P_Style={`${styles.job_descrebtion}`}
                className="order-2 order-md-1"
              />
              <IconPlusTitle
                IconSrc={location}
                title={`${job?.country?.name_en}, ${job.city.name_en}`}
                P_Style={`${styles.job_descrebtion}`}
                className="order-3 order-md-2"
              />
            </div>
            <div className=" mt-md-2">
              <IconPlusTitle
                IconSrc={cash}
                title={`${job?.experience_from} - ${job?.experience_to} 
                  Yrs of Exp · ${job?.career_level?.en}. ${job?.salary_from}-${job?.salary_to}
                   ${job?.salary_currency?.currency} ${job?.salary_per?.en}`}
                P_Style={`${styles.job_descrebtion}`}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-2 d-flex flex-row flex-lg-column gap-2 justify-content-center align-items-center mt-4">
          {job.job_types
            ? job?.job_types?.en?.map((type, index) => (
                <button
                  key={index}
                  disabled
                  className={`${getButtonClasses([[type]])}`}
                >
                  {[type]}
                </button>
              ))
            : ""}
        </div>
      </div>
      {/*<div>
        <div
          className={`d-flex align-items-center justify-content-evenly justify-content-lg-start mt-4 ${styles.edit_delete_btn}`}
        >
          <div
            className=" d-flex align-items-center"
            onClick={() => handleEditPost(job.code)}
          >
            <img src={EditPen} alt="EditPen" />
            <button className="ms-1">Edit</button>
          </div>
          <div
            className=" d-flex align-items-center"
            onClick={() => handleDeletePost(job.code)}
          >
            <img src={ExitXicon} className="ms-3" alt="ExitXicon" />
            <button className="ms-1">Delete</button>
          </div>
        </div>
      </div> */}
    </div>
  ));

  // =========================== Logic of Company Profile Info ========================
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    location: { value: "", id: "" }, //Done
    founded: "", //Done
    companySize: { value: "", id: "" }, //Done
    specialties: { value: "", id: "" },
    industry: "",
    cv: companyInfo?.cv || null,
  });
  const handleEditClick = () => {
    const companyData = {
      country: profileInfo?.location?.id,
      company_year_founded: profileInfo?.founded,
      company_employees_range: profileInfo?.companySize?.id,
      company_specialties: [profileInfo?.specialties?.id],
      company_industry: profileInfo?.industry?.id,
      company_about: "test about",
    };
    if (isEditing) {
      dispatch(editCompanyProfile(companyData))
        .unwrap()
        .then(() => {
          dispatch(getCompanyProfile());
        })
        .catch(() => {});
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo({
      ...profileInfo,
      [name]: value,
    });
  };
  const handleListChange = (e, name) => {
    const { value } = e.target;
    // Find the corresponding id for the selected value (for location or companySize)
    let id = "";
    if (name === "location") {
      const selectedCountry = countries?.data?.find(
        (country) => country.name_en === value
      );
      id = selectedCountry ? selectedCountry.id : "";
    } else if (name === "companySize") {
      const selectedRange = CompanyEmployeeRangeData.find(
        (range) => range.name_en === value
      );
      id = selectedRange ? selectedRange.code : "";
    }

    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      [name]: { value, id }, // Set both value and id for location or companySize
    }));
  };

  // Handle Edit Function to make it Reusable
  const handleEditing = (field) => {
    return isEditing ? (
      <input
        type="text"
        name={field}
        value={profileInfo[field].value} // Use profileInfo[field].value
        onChange={handleInputChange}
        className={`form-control ${styles.input_edit_compinfo}`}
        onClick={() => setIsDropdownVisible(true)} // Show dropdown when input is clicked
      />
    ) : (
      <p>{profileInfo[field].value}</p> // Display the value of the specialty
    );
  };
  const handleListEditing = (field) => {
    let listData = [];

    if (field === "location") {
      listData = countries?.data?.map((country) => country.name_en);
    } else if (field === "companySize") {
      listData = CompanyEmployeeRangeData?.map((range) => range.name_en);
    }

    return isEditing ? (
      <InputField
        placeholder={t("Select")}
        type="text"
        name={field}
        value={profileInfo[field].value} // Access the value property
        onChange={(e) => handleListChange(e, field)}
        style={styles.select_component_style}
        inputStyle={styles.option_component_style}
        isList={true}
        list={listData}
      />
    ) : (
      <p>{profileInfo[field].value}</p> // Display the value when not editing
    );
  };
  const handleEdtingFounded = (field) => {
    return isEditing ? (
      <input
        type="text"
        name={field}
        value={profileInfo[field]} // Use profileInfo[field].value
        onChange={handleInputChange}
        className={`form-control ${styles.input_edit_compinfo}`}
        onClick={() => setIsDropdownVisible(true)} // Show dropdown when input is clicked
      />
    ) : (
      <p>{profileInfo[field]}</p> // Display the value of the specialty
    );
  };
  // Add Documentation File Handler
  const AddDocHandler = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);

      setProfileInfo((prev) => ({
        ...prev,
        cv: fileUrl,
      }));
    }
  };
  // Delete Documentation File Handler
  const DeleteDocHandler = () => {
    if (window.confirm("Are you sure you want to delete the CV?")) {
      setProfileInfo((prev) => ({
        ...prev,
        cv: null, // Clear the CV
      }));
      // Optionally, you can also handle deletion on the backend here
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
    }
  };
  const handleSpecialistChange = (el) => {
    // Update both name and id of the selected specialty
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      specialties: { value: el.name, id: el.code }, // Store both name and id
    }));

    // Hide the dropdown after selection
    setIsDropdownVisible(false);
  };
  const handleIndustryChange = (el) => {
    // Update both name and id of the selected specialty
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      industry: { value: el.name, id: el.code }, // Store both name and id
    }));

    // Hide the dropdown after selection
    setIsDropdownVisible(false);
  };
  useEffect(() => {
    if (profileInfo?.industry) {
      // Only dispatch if industry is not empty
      const industryData = {
        company_industry_name: profileInfo?.industry,
      };
      dispatch(getIndustriesSearch(industryData));
    }
  }, [dispatch, profileInfo?.industry]);

  useEffect(() => {
    if (profileInfo?.specialties) {
      // Only dispatch if specialties is not empty
      const specialistData = {
        company_specialties_name: profileInfo?.specialties,
      };
      dispatch(getSpecialtiesSearch(specialistData));
    }
  }, [dispatch, profileInfo?.specialties]);

  useEffect(() => {
    if (companyProfileData) {
      setProfileInfo((prev) => ({
        ...prev,
        location: {
          value: companyProfileData?.country?.name_en || "",
          id: companyProfileData?.country?.id || "",
        },
        founded: companyProfileData?.founded || "Not Added",
        companySize: {
          value: companyProfileData?.employees_range?.en || "Not Selected",
          id: companyProfileData?.employees_range?.id || "",
        },
        specialties: {
          value: companyProfileData?.hiring_for?.join(", ") || "",
        },
        industry: {
          value: companyProfileData?.skills || "Software Development",
        },
        cv: companyProfileData?.cv || "",
      }));
    }
  }, [companyProfileData]);
  useEffect(() => {
    dispatch(getCompanyEmployeeRange());
    dispatch(getCompanyProfile());
  }, [dispatch]);
  return (
    <div>
      {/*Company Profile */}
      <div className={styles.company_profile}>
        <Container>
          <div
            className={`d-flex text-aligns-center justify-content-between ${styles.company_profile_title}`}
          >
            <h4 className="d-flex align-items-center">{t("CompanyProfile")}</h4>
            <button onClick={handleEditClick}>
              {isEditing ? "" : <img src={Plus} alt="icon" />}

              {isEditing ? "Save" : "Edit"}
            </button>
          </div>
          {/*Company Profile Info*/}
          <div className={`mt-5 ${styles.company_profile_info} container`}>
            <div className="row gap-3 gap-sm-0 mb-3 mb-sm-4">
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-sm-start gap-2">
                <label>{t("Location")}:</label>
                {handleListEditing("location")}
              </div>
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-sm-start gap-2">
                <label>{t("Founded")}:</label>
                {handleEdtingFounded("founded")}
              </div>
            </div>
            <div className="row gap-3 gap-sm-0 mb-3 mb-sm-4">
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-sm-start gap-2">
                <label>{t("CompanySize")}:</label>
                {handleListEditing("companySize")}
              </div>
              <div className="col-12 col-sm-6 d-flex align-items-baseline justify-content-sm-start gap-2">
                <label>{t("Specialties")}:</label>
                <div className={styles.dropdown_parent}>
                  {handleEditing("specialties")}
                  <div className={styles.dropdown_content}>
                  {console.log("specialtiesData", specialtiesData)}
                    {isEditing &&
                      specialtiesData?.length > 0 &&
                      isDropdownVisible && ( // Show dropdown only when isDropdownVisible is true
                        <ul
                          className={`dropdown-list shadow ${styles.specialist_dropdown}`}
                        >
                          {specialtiesData?.map((el) => (
                            <li
                              key={el.name_en}
                              className="dropdown-item"
                              onClick={() => handleSpecialistChange(el)} // Update input and hide the dropdown
                            >
                              {el.name}
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row gap-3 gap-sm-0 mb-3 mb-sm-4">
              <div className="col-12 col-sm-6 d-flex align-items-baseline justify-content-sm-start gap-2">
                <label>{t("Industry")}:</label>
                <div className={styles.dropdown_parent_two}>
                  {handleEditing("industry")}
                  <div className={styles.dropdown_content_two}>
                    {isEditing &&
                      industriesData?.length > 0 &&
                      isDropdownVisible && (
                        <ul
                          className={`dropdown-list shadow ${styles.specialist_dropdown}`}
                        >
                          {industriesData?.map((el) => (
                            <li
                              key={el.name_en}
                              className="dropdown-item"
                              onClick={() => handleIndustryChange(el)} // Update input and hide the dropdown
                            >
                              {el.name}
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Company Profile Description*/}
          <div
            className={`mt-5 d-flex flex-column align-items-center gap-5 ${styles.company_profile_description}`}
          >
            <div className="d-flex flex-column align-items-center">
              <img src={FileIcon} alt="file icon" />
              <h6>{t("CompanyDescription")}</h6>

              {/* Conditionally render PDF or prompt to add it */}
              {profileInfo?.cv ? (
                // Display the PDF if companyInfo.cv contains the URL
                <Link
                  to={profileInfo?.cv}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-link mt-1"
                >
                  <div className="d-flex align-items-center flex-column">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                      alt="PDF file"
                    />
                    <span>{profileInfo?.cv.split("/").pop()}</span>
                  </div>
                </Link>
              ) : (
                <p>Add description about your company</p>
              )}
            </div>

            <div className="mt-4">
              {/* Conditionally render "Add description" button if no PDF is present */}
              <input
                type="file"
                accept="application/pdf"
                onChange={AddDocHandler}
                ref={fileInputRef} // Attach the ref
                style={{ display: "none" }} // Hide the input
              />
              {console.log("profileInfo", profileInfo)}
              {isEditing && !profileInfo?.cv && (
                <button
                  className="align-items-center"
                  onClick={() => fileInputRef.current.click()}
                >
                  <img src={Plus} alt="plus icon" />
                  Add description
                </button>
              )}

              {/* Delete CV button */}
              {isEditing && profileInfo?.cv && (
                <button
                  className="align-items-center mt-2"
                  onClick={DeleteDocHandler}
                >
                  <img
                    src={Trash}
                    alt="minus icon"
                    className={styles.trash_icon}
                  />
                  Delete CV
                </button>
              )}
            </div>
          </div>
        </Container>
      </div>
      {/*Recent jobs at Raya */}
      <div className={`mt-5 ${styles.recent_job}`}>
        <div className={`${styles.open_vacancy} px-4 mb-5 `}>
          <h2>
            {" "}
            <h3>
              {t("RecentJobs")} at {companyInfo?.company_name}
            </h3>
          </h2>
          <div className="container">{OpenVacancies}</div>
        </div>
      </div>
    </div>
  );
}

export default CompleteCompProLeft;
