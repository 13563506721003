import React, { useEffect } from "react";
import { IconPlusTitle } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { location, King, Dollar, AddIcon } from "../../../assets";
import { allSavedJob, getJobsDetails } from "../../../redux/slices/jobsSlice";
import styles from "./styles.module.css";
import { t } from "i18next";
// Configuration object for job types and their associated colors
const jobTypeColors = {
  Internship: "#e1d123",
  "Full Time": "#186fc9",
  Freelance: "#53b427",
  "Part Time": "#f1630d",
};

// Function to get border color based on job types
const getBorderColor = (types) => {
  if (types?.includes("Internship")) return jobTypeColors["Internship"];
  if (types?.includes("Full Time")) return jobTypeColors["Full Time"];
  if (types?.includes("Freelance")) return jobTypeColors["Freelance"];
  if (types?.includes("Part Time")) return jobTypeColors["Part Time"];
  if (types?.includes("Student activity")) return jobTypeColors["Part Time"];
  return "#f1630d"; // Default color if no types match
};

// Function to get button classes based on job types
const getButtonClasses = (types) => {
  const buttonClasses = {
    Internship: styles.job_status_btn_Internship,
    "Full Time": styles.job_status_btn_fulltime,
    Freelance: styles.job_status_btn_freelance,
    "Free Lance / Project": styles.job_status_btn_freelance,
    "Shift based": styles.job_status_btn_freelance,
    "Part Time": styles.job_status_btn_parttime,
    "Student activity": styles.job_status_btn_parttime,
  };
  return types.map((type) => buttonClasses[type] || "").join(" ");
};

function LeftHandSide() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allSavedJobData } = useSelector((state) => state.jobs);
  // Example array of jobs with their types
  const responsive =
    "d-flex flex-column flex-md-row justify-content-center align-items-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";
  const responsiveTwo =
    "d-flex flex-column  justify-content-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";

  const getThisJobHandler = (job) => {
    const jobCode = {
      job_code: job.code,
    };
    dispatch(getJobsDetails(jobCode))
      .unwrap()
      .then(() => {
        navigate("/jobProfile");
      })
      .catch(() => {});
  };

  useEffect(() => {
    dispatch(allSavedJob());
  }, [dispatch]);
  //
  return (
    <div>
      {/* Show Jobs Section */}
      <div className="pb-5 container">
        {allSavedJobData?.data?.map((job, index) => (
          <div
            key={index}
            className={`row 
              ${responsive}
              ${styles.job_container} ${job?.job.active ? styles.active : ""}`}
            style={{ borderLeftColor: getBorderColor(job?.job?.job_types?.en) }}
          >
            <div
              className={`row col-12 col-md-10 text-center text-md-start   ${responsive}`}
            >
              <div className="col-4 col-md-2">
                <img
                  src={job?.job?.company?.company_logo}
                  alt={job?.job?.company?.company_name}
                  className="c-pointer"
                  onClick={() => getThisJobHandler(job?.job)}
                />
              </div>
              <div
                className={`col-10 ${styles.job_info} ${responsiveTwo} align-items-center align-items-md-start`}
              >
                <p className="py-1">{job?.job?.title}</p>
                <div className="d-flex align-items-center  gap-4 py-1">
                  <IconPlusTitle
                    IconSrc={King}
                    title={job?.job?.company?.company_name}
                    P_Style={`${styles.job_descrebtion}`}
                  />
                  <IconPlusTitle
                    IconSrc={location}
                    title={`${job?.job?.country?.name_en} , ${job?.job?.city?.name_en}`}
                    P_Style={`${styles.job_descrebtion}`}
                  />
                </div>
                <IconPlusTitle
                  IconSrc={Dollar}
                  title={`${job?.job?.category_name?.en}/ ${job?.job?.contract_type?.en}/ ${job?.job?.career_level?.en}`}
                  P_Style={`${styles.job_descrebtion}`}
                  customIconStyle={styles.customIconStyle}
                />
              </div>
            </div>
            <div className="col-2 d-flex flex-column gap-2 align-items-center mt-4">
              {job?.job?.job_types?.en?.map((type, idx) => (
                <button
                  key={idx}
                  disabled
                  className={`${getButtonClasses([type])}`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* Pages Number */}
      <div className="mb-5 d-flex align-items-center justify-content-around">
        <div
          className={`d-flex flex-row align-items-center gap-3 justify-content-center ${styles.number_parent}`}
        >
          <p className={`${styles.active}`}>1</p>
          <p>2</p>
          <p>3</p>
        </div>
        <div className={`d-flex justify-content-end ${styles.number_parent}`}>
          <p>{t("Next")}</p>
        </div>
      </div>
      {/* Questionnaire */}
      <div className={`${styles.questionair_parent} d-flex flex-column`}>
        <div className="d-flex justify-content-between align-items-center p-4">
          <p>What are the top 10 popular jobs in Saudi Arabia Now?</p>
          <img alt="" src={AddIcon} />
        </div>
        <div className="d-flex justify-content-between align-items-center p-4">
          <p>Saudi Arabia’s Top 5 Companies Hiring in Saudi Arabia Now</p>
          <img alt="" src={AddIcon} />
        </div>
        <div className="d-flex justify-content-between align-items-center p-4">
          <p>What are the top 5 cities with open jobs in Saudi Arabia?</p>
          <img alt="" src={AddIcon} />
        </div>
      </div>
    </div>
  );
}

export default LeftHandSide;
