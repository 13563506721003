import React from 'react'
import { Button } from '../../../components'
//style
import completeCompProRight from "./styles.module.css";
const { hiring_box } = completeCompProRight;
function CompleteCompProRight() {
  return (
    <div
      className="d-flex flex-column m-auto align-items-center"
      
    >
      <div className={`${hiring_box} mb-4 w-100 pb-5`}>
        <h4>Hiring?</h4>
        <p>If you looking for hire right candidate start hire now.</p>
        <Button
          title="Start hiring"
          to={`jobProfile`}
          style={`py-2 fw-normal`}
        />
      </div>
      <div className={`mb-4 ${hiring_box} w-100 pb-5`}>
        <h4>CVs</h4>
        <p>
        If you looking for hire right candidate start hire now.
        </p>
        <Button
          title="Browse CVs"
          to={``}
          style={`py-2 fw-normal`}
        />
      </div>
    </div>
  )
}

export default CompleteCompProRight