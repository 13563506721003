import React from "react";
import { Link } from "react-router-dom";
import { envelope, HelpIcon, UploadDoc } from "../../assets";
import Button from "../Button";
import styles from "./styles.module.css";
import Modal from "../Modal";

function ModalConfirmAddress({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={`${styles.modal_content} gap-5 d-flex flex-column`}>
        <div className={`${styles.modal_section} d-flex flex-column gap-2 align-items-start`}>
          <h3>You are almost done!</h3>
          <h4>Complete these two simple steps to publish your first job post.</h4>
        </div>
        <div className={`${styles.modal_section} d-flex flex-column gap-2`}>
          <div className={`${styles.title_info} d-flex flex-row gap-3 align-items-center`}>
            <p>1</p>
            <h5>Confirm your email address</h5>
          </div>
          <span>An email confirmation has been sent to <strong>infinitySolution1@gmail.com</strong></span>
          <div className={`${styles.mes_upl_icons} d-flex flex-row gap-2 align-items-center`}>
            <img src={envelope} alt="message" />
            <h6>Resend Confirmation email</h6>
          </div>
        </div>
        <div className={`${styles.modal_section} d-flex flex-column gap-2`}>
          <div className={`${styles.title_info} d-flex flex-row gap-3 align-items-center`}>
            <p>2</p>
            <h5>Add your Company Tax Card or Commercial Register</h5>
          </div>
          <span>In order to approve your company account, we need your Tax Card or your Commercial Registration Document.</span>
          <Link to="">Why do we need these documents?</Link>
          <div className={`${styles.mes_upl_icons} d-flex flex-row gap-2 align-items-center`}>
            <img src={UploadDoc} alt="message" />
            <h6>Upload Documents</h6>
          </div>
        </div>
        <div className={`${styles.help_skip_container} d-flex justify-content-between align-items-center`}>
          <div className="d-flex align-items-center gap-2">
            <img src={HelpIcon} alt="help" />
            <Link>Help Center</Link>
          </div>
          <div>
            <Button to="" title="Skip for now" style={styles.skip_btn} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirmAddress;
