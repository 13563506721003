import React, { useState } from "react";
//styles
import { Container } from "react-bootstrap";
import { BlueDoc, CompanyPhoto, Save, unSave } from "../../../assets";
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Loading } from "../../../components";
import {
  allSavedJob,
  ApplyJob,
  getAllApplyJobs,
  getCompanyDetailsbyUser,
  getJobsDetails,
  saveJob,
  UnapplyJob,
  unSaveJob,
} from "../../../redux/slices/jobsSlice";
import { t } from "i18next";
function JobProfileHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { jobDetailsData, loading } = useSelector((state) => state.jobs);
  //state
  const [loadingType, setLoadingType] = useState(null);

  const handleApply = () => {
    if (token == null) {
      setLoadingType("apply");
      const jobCode = {
        job_code: jobDetailsData?.code,
        is_high_job: 1
      };
      if (jobDetailsData?.is_applied) {
        dispatch(UnapplyJob(jobCode))
          .unwrap()
          .then(() => {
            dispatch(getAllApplyJobs());
            dispatch(getJobsDetails(jobCode));
            setLoadingType(null);
          })
          .catch(() => {
            setLoadingType(null);
          });
      } else {
        dispatch(ApplyJob(jobCode))
          .unwrap()
          .then(() => {
            dispatch(getAllApplyJobs());
            dispatch(getJobsDetails(jobCode));
            setLoadingType(null);
          })
          .catch(() => {
            setLoadingType(null);
          });
      }
    } else {
      alert("You Should Log In First")
      navigate("/login");
    }
  };
  const saveHandler = () => {
    if (token !== null) {
      setLoadingType("save");
      const jobCode = {
        job_code: jobDetailsData?.code,
      };
      if (jobDetailsData?.is_saved) {
        dispatch(unSaveJob(jobCode))
          .unwrap()
          .then(() => {
            dispatch(allSavedJob());
            dispatch(getJobsDetails(jobCode));
            setLoadingType(null);
          })
          .catch(() => {
            setLoadingType(null);
          });
      } else {
        dispatch(saveJob(jobCode))
          .unwrap()
          .then(() => {
            dispatch(allSavedJob());
            dispatch(getJobsDetails(jobCode));
            setLoadingType(null);
          })
          .catch(() => {
            setLoadingType(null);
          });
      }
    } else {
      navigate("/login");
    }
  };
  const CompanyProfileHandler = (companyCode) => {
    const data = {
      company_code: companyCode,
    };
    dispatch(getCompanyDetailsbyUser(data))
      .unwrap()
      .then(() => {
        navigate("/companyProfile");
      })
      .catch(() => { });
  };
  return (
    <div className={`${styles.Company_Profile}`}>
      <Container className="row d-flex flex-column align-items-center ">
        <div className="col-12 col-md-12 row d-flex align-items-center">
          {/*Job Profile Image */}
          <div
            className={`col-12 col-md-3 pb-3 me-0 me-md-4 me-lg-0 d-flex justify-content-center`}
          >
            <Link
              onClick={() =>
                CompanyProfileHandler(jobDetailsData?.company?.code)
              }
            >
              <img
                className={`${styles.profile_photo} ms-3 ms-md-5 mt-1`}
                src={
                  jobDetailsData?.company?.company_logo
                    ? jobDetailsData?.company?.company_logo
                    : CompanyPhoto
                }
                alt={jobDetailsData?.company?.company_name}
              />
            </Link>
          </div>
          {/*Job Profile Info */}
          <div
            className={`col-12 col-md-8 d-flex flex-column align-items-center align-items-md-start mt-3 mt-md-0 ms-3 ms-lg-0 gap-2 ${styles.company_profile_info}`}
          >
            <div className="d-flex align-items-center pb-1">
              <h5 className="me-2 text-nowrap ">{jobDetailsData?.title}</h5>
            </div>
            <div
              className={`d-flex align-items-center gap-2 ${styles.job_status}`}
            >
              {jobDetailsData?.job_types?.default_name?.map((type) => (
                <p>{type}</p>
              ))}
            </div>
            <div
              className={`d-flex align-items-center gap-2 ${styles.Company_job_name}`}
            >
              <p
                className="c-pointer"
                onClick={() =>
                  CompanyProfileHandler(jobDetailsData?.company?.code)
                }
              >
                {jobDetailsData?.company?.company_name
                  ? jobDetailsData?.company?.company_name
                  : "Raya Company"}
              </p>
              <img src={BlueDoc} alt="Company URL" />
              <span>
                - {jobDetailsData?.city?.default_name},{" "}
                {jobDetailsData?.country?.default_name
                  ? jobDetailsData?.country?.default_name
                  : "SAU"}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <p>Posted {jobDetailsData?.since}</p>
            </div>
            <div
              className={`${styles.application_view} d-flex flex-column flex-md-row align-items-center gap-4 gap-lg-5`}
            >
              <div className="d-flex align-items-center gap-2">
                <h1>{jobDetailsData?.statics?.total_applications}</h1>
                <span>
                  {t("ApplicantsFor")}
                  <br />1 {t("openposition")}
                </span>
              </div>
              <div className="d-flex align-items-center gap-3 gap-lg-5">
                <div className="d-flex flex-column align-items-center">
                  <h4>
                    {jobDetailsData?.views_count
                      ? jobDetailsData?.views_count
                      : 0}
                  </h4>
                  <span>{t("Viewed")}</span>
                </div>
                <div
                  className={`d-flex flex-column align-items-center px-2 ${styles.in_Consideration}`}
                >
                  <h4>
                    {jobDetailsData?.statics?.total_in_consideration
                      ? jobDetailsData?.statics?.total_in_consideration
                      : 0}
                  </h4>
                  <span className="px-1 px-sm-3 px-md-3">
                    {t("InConsideration")}
                  </span>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h4>0</h4>
                  <span>{t("NotSelected")}</span>
                </div>
              </div>
            </div>
            <div className={`d-flex gap-3`}>
              <Button
                title={
                  jobDetailsData?.is_applied
                    ? t("CancelApply")
                    : t("Applyforjob")
                }
                onClick={handleApply}
                loading={loadingType === "apply" && loading}
                style={`${styles.apply_btn}`}
              />
              <div className={styles.save_icon_parent} onClick={saveHandler}>
                {loadingType === "save" ? (
                  <div className={styles.loading_spinner}>
                    <Loading style={styles.save_icon} />
                  </div>
                ) : (
                  <img
                    src={jobDetailsData?.is_saved ? Save : unSave}
                    alt="save"
                    className={styles.save_icon}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default JobProfileHeader;
