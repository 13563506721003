import React, { useState } from "react";
import { Button, IconPlusTitle } from "../../../components";
import {
  BlackExit,
  DocCandidate,
  GraduateCap,
  BlueTips,
  Lock,
  Paper,
  Search,
  ShoglBag,
} from "../../../assets";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUserCVs,
  getCandidateCVDetails,
} from "../../../redux/slices/jobsSlice";
function LeftHandSide() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userCvsData, error } = useSelector((state) => state.jobs);
  const [searchValue, setSearchValue] = useState("");
  const getCandidProfile = (code) => {
    const profileCode = {
      code: code,
    };
    dispatch(getCandidateCVDetails(profileCode))
      .unwrap()
      .then(() => {
        navigate("/userProfile", { state: { from: "CVsPage" } });
      })
      .catch(() => {});
  };
  const submitSearchHandler = () => {
    const jobTitle = {
      job_title: searchValue,
    };
    dispatch(getAllUserCVs(jobTitle))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 3;

  // Ensure filteredJobs.data is defined and has a length
  const totalJobs = userCvsData?.data?.length || 0;
  const totalPages = Math.max(1, Math.ceil(totalJobs / jobsPerPage));
  // Calculate jobs to display
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs =
  userCvsData?.data?.slice(indexOfFirstJob, indexOfLastJob) || [];
  const handleNextPage = () => {
    window.scrollTo(0, 0);
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page)
  };
  return (
    <div className="d-flex flex-column gap-3">
      {/*Start Search CV */}
      <div className={`${styles.search_header}`}>
        <div className={`${styles.search_cv_content} d-flex flex-column`}>
          <div className="d-flex flex-column gap-2 gap-md-0 flex-md-row align-items-center justify-content-md-between">
            <h5>{t("SearchCandidatesCVs")}</h5>
            <Link to="/">
              <IconPlusTitle
                IconSrc={BlueTips}
                title={t("TipsForBetterSearch")}
                P_Style={styles.tips_search_style}
              />
            </Link>
          </div>
          <div className="text-center">
            {error && <p className="text-danger">{error}</p>}
          </div>
          <div className={`${styles.search_bar_container}`}>
            <input
              type="text"
              placeholder={t("SeachBarTitle")}
              onChange={(e) => setSearchValue(e.target.value)}
            ></input>
            <img
              src={Search}
              className={
                i18n.language === "en"
                  ? styles.search_icon_en
                  : styles.search_icon_ar
              }
              alt="Search"
            />
            <div
              className={
                i18n.language === "en"
                  ? styles.search_btn_en
                  : styles.search_btn_ar
              }
              onClick={submitSearchHandler}
            >
              <button>{t("Search")}</button>
            </div>
          </div>
          <div className="ms-4 d-flex flex-column flex-md-row align-items-center gap-2">
            <button
              className={`d-flex gap-1 align-items-center ${styles.recommendation_search_btn}`}
            >
              <p className="d-flex gap-1 align-items-center">
                Egypt<span>({t("Country")})</span>
              </p>
              <img src={BlackExit} alt="Exit" />
            </button>
            <button
              className={`d-flex gap-1 align-items-center ${styles.recommendation_search_btn}`}
            >
              <p className="d-flex gap-1 align-items-center">
                Alex<span>({t("Cityy")})</span>
              </p>
              <img src={BlackExit} alt="Exit" />
            </button>
          </div>
          <div className={styles.line}></div>
          <div
            className={`d-flex flex-column gap-2 gap-md-0 flex-md-row align-items-center justify-content-md-between ${styles.show_result}`}
          >
            <p>
              Showing <span>({userCvsData.data.length})</span> results
            </p>
            <Button
              title={"+ Save this search"}
              style={styles.save_search_btn}
            />
          </div>
        </div>
      </div>
      {/*End Search CV */}
      {/*Start Search Result */}
      {currentJobs?.map((cv) => (
        <div className={`${styles.search_header} ${styles.Cand_Pro}`}>
          <div>
            <div
              className="c-pointer"
              onClick={() => getCandidProfile(cv?.code)}
            >
              <div
                className={`d-flex align-items-center justify-content-bewteen ${styles.search_result_header}`}
              >
                <div className="d-flex align-items-center gap-3">
                  <div className={styles.user_profile_pic}>
                    <img src={cv?.avatar} alt="profile-pic" />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <div className={`d-flex gap-2 ${styles.user_info}`}>
                      <h3>{cv?.name}</h3>
                      <div className={styles.lock_container}>
                        <img src={Lock} alt="Lock" />
                      </div>
                      <span>CV</span>
                    </div>
                    <p>
                      {i18n.language === "en"
                        ? cv?.city_name?.en
                        : cv?.city_name?.ar}
                      ,{" "}
                      {i18n.language === "en"
                        ? cv?.country_name?.en
                        : cv?.country_name?.ar}{" "}
                      - 43yrs old
                    </p>
                    <p>More than 15yrs. Manager - Min Salary Confidential</p>
                  </div>
                </div>
                <div style={{ marginleft: "0px" }}>
                  <Button
                    to="/"
                    title={t("InviteToApply")}
                    iconSrc={Paper}
                    style={styles.invite_to_apply_btn}
                  />
                </div>
              </div>
              <span className={styles.last_activity}>
                {t("LastActive")}: 28 days
              </span>
            </div>
            {/*Candidate Info */}
            <div className={` d-flex flex-column mt-2 gap-3 justify-content-center`}>
            <div className={`${styles.candidates_info}`}>
              <div className={`${styles.candidates_info_img}`}>
                <img src={ShoglBag} alt="bag" />
              </div>
              <div>
                <p className="d-flex"> Sales Manager at Value</p>
                <span>(2022 - present)</span>
              </div>
              <div>
                <p className="d-flex">
                  Sales Manager (indirect and international sales manager) at High
                  Art
                </p>
                <span>(2016 - present)</span>
              </div>
            </div>
            <div className={`${styles.candidates_info}`}>
              <div className={`${styles.candidates_info_img}`}>
                <img src={GraduateCap} alt="Graduated cap" />
              </div>
              <div>
                <p className="d-flex">
                  BA in BA,Higher Institute for Administration Scienc...
                </p>
                <span>(2003 - 2008)</span>
              </div>
            </div>
            <div className={`${styles.candidates_info}`}>
              <div className={`${styles.candidates_info_img}`}>
                <img src={DocCandidate} alt="Doc Cand" />
              </div>
              <div>
                <p>Job titles:</p>
                <span>
                  senior sales advisorsenior sales consultantSales Manager
                  (indirect and international ...sales team leaderSales
                  ManagerSkillsSales PlanPreferred job titlessenior sales
                  advisorPreferred job rolesSales/Retail
                </span>
              </div>
              </div>
              <div className={`${styles.line}`}></div>
          </div>
            {/*immediate Start BTN */}
            <div className={styles.immediate_start}>
              <p>Immediate Start</p>
            </div>
          </div>
        </div>
      ))}
      <div className="mb-5 d-flex align-items-center justify-content-around">
      <div
        className={`d-flex flex-row align-items-center gap-3 justify-content-center ${styles.number_parent}`}
      >
        {[...Array(totalPages).keys()].map((page) => (
          <p
            key={page}
            className={`c-pointer ${
              currentPage === page + 1 ? styles.active : ""
            }`}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </p>
        ))}
      </div>
      <div
        className={`d-flex justify-content-end c-pointer ${styles.number_parent}`}
      >
        <p onClick={handleNextPage}>{t("Next")}</p>
      </div>
    </div>
      {/*End Search Result */}
    </div>
  );
}

export default LeftHandSide;

