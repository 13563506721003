import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Button, InputField } from "../../../components";
import { BlackExit, CancelClose, EditPen } from "../../../assets";
import { t } from "i18next";
import { useSelector } from "react-redux";

function StepFour({ onStepDataChange }) {
  const { allLanguagesData, allLanguagesLevelData, allSkillsData } =
    useSelector((state) => state.helpers);
  const [isEdit, setIsEdit] = useState(false);
  //languages and levels
  const [languageValue, setLanguageValue] = useState("");
  const [languageID, setLanguageID] = useState(""); // Language id
  const [languageLevelValue, setLanguageLevelValue] = useState("");
  const [languageLevelID, setLanguageLevelID] = useState(""); // Language Level id
  const [languages, setLanguages] = useState([]);
  const [languagesDataIDs, setLanguagesDataIDs] = useState([]); // Language Data (Sent)
  //Skills
  const [skillsValue, setSkillsValue] = useState("");
  const [skillsData, setSkillsData] = useState([]);
  const [skillsCode, setSkillsCode] = useState([]);
  const handleLanguageChange = (event) => {
    const selectedLanguageName = event.target.value;
    setLanguageValue(selectedLanguageName);

    const selectedLanguage = allLanguagesData?.data?.find(
      (lang) => lang?.default_name === selectedLanguageName
    );
    if (selectedLanguage) {
      const LanguageId = selectedLanguage.code;
      setLanguageID(LanguageId);
    }
  };
  const handleLanguageLevelChange = (event) => {
    const selectedLanguageLevelName = event.target.value;
    setLanguageLevelValue(selectedLanguageLevelName);

    const selectedLanguageLevel = allLanguagesLevelData?.data?.find(
      (val) => val?.default_name === selectedLanguageLevelName
    );
    if (selectedLanguageLevel) {
      const LanguageId = selectedLanguageLevel.code;
      setLanguageLevelID(LanguageId);
    }
  };
  const handleAddLanguage = (e) => {
    e.preventDefault();
    if (languageValue && languageLevelValue) {
      // Check if the language already exists in the list
      if (languages.some((lang) => lang.language === languageValue)) {
        alert("This language is already added.");
        return;
      }
      // Add new language and proficiency to the list
      setLanguages([
        ...languages,
        {
          code: languageID,
          language: languageValue,
          level: languageLevelValue,
        },
      ]);
      setLanguagesDataIDs([
        ...languagesDataIDs,
        { lang: languageID, level: languageLevelID },
      ]);
      // Clear the selected options
      setLanguageValue("");
      setLanguageLevelValue("");
    } else {
      alert("Please select both language and proficiency.");
    }
  };

  useEffect(() => {
    onStepDataChange({
      languages: languagesDataIDs?.map((item) => ({
        language_id: item?.lang,
        level_id: item?.level,
      })),
    });
  }, [languagesDataIDs]);
  const handleSkillsChange = (event) => {
    const selectedSkill = allSkillsData?.data?.find(
      (skill) => skill.default_name === event.target.value
    );
    setSkillsValue(selectedSkill);
  };
  const handleAddSkill = (e) => {
    e.preventDefault();

    if (skillsValue) {
      const { default_name, code } = skillsValue;

      // Check if the skill is already in the list
      if (skillsData.includes(default_name)) {
        alert("This Skill is already added.");
        return;
      }

      // Add the skill name and code to their respective states
      setSkillsData([...skillsData, default_name]);
      setSkillsCode([...skillsCode, code]);

      // Clear the selected skill
      setSkillsValue(null);
    } else {
      alert("Please select a Skill.");
    }
  };
  const handleRemoveSkills = (skillToRemove) => {
    const indexToRemove = skillsData.indexOf(skillToRemove);

    // Remove the skill name and its corresponding code
    if (indexToRemove > -1) {
      setSkillsData(skillsData.filter((_, index) => index !== indexToRemove));
      setSkillsCode(skillsCode.filter((_, index) => index !== indexToRemove));
    }
  };
  return (
    <main
      className={` flex-column d-flex justify-content-center position-relative ${styles.page_container}`}
    >
      <div
        className={`d-flex flex-column align-items-center py-4 mt-2 ${styles.page_header}`}
      >
        <p>{t("MainStep")}</p>
        <p>
          Tell Us About Your <span>Expertise</span>
        </p>
      </div>
      <form>
        {/*============================= Start Section 1 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center">
            <h6 className="fw-700">{t("WhatLanguagesDoYouKnow")}</h6>
            <span>— You can add more than one</span>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-center gap-3 gap-md-5">
            <div className="flex-grow-1">
              <InputField
                label={t("Language")}
                placeholder={t("Select")}
                value={languageValue}
                onChange={handleLanguageChange}
                isList={true}
                list={allLanguagesData?.data?.map((lang) => lang.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            <div className="flex-grow-1">
              <InputField
                label={t("level")}
                placeholder={t("Select")}
                value={languageLevelValue}
                onChange={handleLanguageLevelChange}
                isList={true}
                list={allLanguagesLevelData?.data.map(
                  (lvl) => lvl.default_name
                )}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            <Button
              title={t("Add")}
              style={`shadow px-5 py-2`}
              onClick={handleAddLanguage}
            />
          </div>

          {/* Render Added Languages */}
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 gap-md-3 mt-md-0 mt-3">
            {languages.map((lang, index) => (
              <div
                key={index}
                className={`d-flex align-items-center gap-3 py-2 ${styles.results_style}`}
              >
                <h5>{lang.language} -</h5>
                <p>{lang.level}</p>

                <div>
                  <img
                    alt={"delete"}
                    src={EditPen}
                    className={` ${styles.results_exit_icon}`}
                    onClick={() => setIsEdit(true)}
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
        {/*============================= End Section 1 ============================ */}
        {/*============================= Start Section 2 ============================ */}
        <section className={` ${styles.background_forms} p-5 mb-4 container `}>
          <h6 className="pb-4">{t("WhatSkillsToolsTechnologies")}</h6>
          <div className="row mb-3">
            <div className="col-md-10 col-12">
              <InputField
                placeholder={t("Select")}
                value={skillsValue?.default_name}
                onChange={handleSkillsChange}
                isList={true}
                list={allSkillsData?.data?.map((field) => field.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            <div className="col-md-2 col-12">
              <Button
                title={t("Add")}
                style={`shadow px-5 py-2`}
                onClick={handleAddSkill}
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 gap-md-3 mt-md-0 mt-3">
              {skillsData?.map((skill, index) => (
                <div
                  className={`shadow py-2 ${styles.skills_style}`}
                  key={index}
                >
                  <p>{skill}</p>
                  <div>
                    <img
                      alt={"Delete"}
                      src={CancelClose}
                      className={styles.results_exit_icon}
                      onClick={() => handleRemoveSkills(skill)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/*============================= End Section 2 ============================ */}
      </form>
      {/* Edit Experience Modal */}
      {isEdit && (
        <div
          className={styles.modal_parent}
          onClick={(e) => {
            if (e.target.className.includes("modal_parent")) {
              setIsEdit(false); // Close modal when clicking outside
            }
          }}
        >
          <div className={styles.modal_container}>
            <header
              className={`d-flex align-items-center justify-content-between`}
            >
              <h6>Edit Language</h6>
              <div>
                <img
                  src={BlackExit}
                  alt="exit-btn"
                  className="c-pointer"
                  onClick={() => setIsEdit(false)}
                />
              </div>
            </header>
            <div className={styles.modal_body}>
              <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-center gap-3 gap-md-5">
                <div className="flex-grow-1">
                  <InputField
                    label={t("Language")}
                    placeholder={t("Select")}
                    value={languageValue}
                    onChange={handleLanguageChange}
                    isList={true}
                    list={allLanguagesData?.data?.map(
                      (lang) => lang.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
                <div className="flex-grow-1">
                  <InputField
                    label={t("level")}
                    placeholder={t("Select")}
                    value={languageLevelValue}
                    onChange={handleLanguageLevelChange}
                    isList={true}
                    list={allLanguagesLevelData?.data.map(
                      (lvl) => lvl.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
            </div>
            <div
              className={"py-3 d-flex align-items-center justify-content-start"}
            >
              <Button
                title={"Save"}
                style={`px-4 py-3 mx-3`}
                disabled={false}
              />
              <Button
                title={"Cancel"}
                style={`px-4 py-3 ${styles.save_cancel}`}
              />
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default StepFour;
