import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import CandidateApplicationsHeader from "./CandidateApplicationsHeader";
import LeftHandSide from "./LeftHandSide";
import { useDispatch } from "react-redux";
import { getAllApplyJobs } from "../../redux/slices/jobsSlice";

function CandidateApplications() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllApplyJobs());
  }, [dispatch]);
  return (
    <div>
      <CandidateApplicationsHeader />
      <Container>
        <div className="row py-2 m-auto">
          <div className="col-12 col-lg-8 order-lg-1 order-md-1 order-2">
            <LeftHandSide />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CandidateApplications;
