import React from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
function ManagementJobsHeader() {
  const locationState = useLocation();
  const { title } = locationState.state || {};
  const { filteredJobs } = useSelector((state) => state.jobs);
  return (
    <div
      className={`${styles.page_header} d-flex align-items-center gap-4 mt-1`}
    >
      <p>{title} Jobs in Saudi Arabia</p>
      <span>{filteredJobs?.data?.length + " " + t("JobsFound")} </span>
    </div>
  );
}

export default ManagementJobsHeader;
