import {
  PortfolioPic,
  Experience,
  Education,
  editIcon,
  AddIcon,
  UpperArrow,
  CallenderTwo,
  Possibility,
  Salary,
  SaveEdit,
} from "../../../assets";
//style
import styles from "./styles.module.css";
import i18next, { t } from "i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getMyProfile, myAboutMe } from "../../../redux/slices/authSlice";

function LeftSideUserProfile() {
  const locationState = useLocation();
  const { from } = locationState.state || {};
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.auth);
  const { userCvsDetailsData } = useSelector((state) => state.jobs);
  const [isAboutMeEditing, setIsAboutMeEditing] = useState(false);
  const [aboutMe, setAboutMe] = useState({
    about_me: "",
    personal_characteristics: "",
  });
  // ============== Fun & Logic & Integration ==============
  // ===== Function To Convert The Sentences To Text Area =====
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAboutMe({
      ...aboutMe,
      [name]: value,
    });
  };
  // ==== Effect to set profile data based on where the navigation came from ===
  useEffect(() => {
    if (from === "CVsPage") {
      setAboutMe((prevData) => ({
        ...prevData,
        about_me: userCvsDetailsData?.about_me || "",
        personal_characteristics:
          userCvsDetailsData?.personal_characteristics || "",
      }));
    } else {
      setAboutMe((prevData) => ({
        ...prevData,
        about_me: userProfileData?.about_me || "",
        personal_characteristics:
          userProfileData?.personal_characteristics || "",
      }));
    }
  }, [from, userCvsDetailsData, userProfileData]);
  // ====== Function To Send Data To API ======
  const toggleEditMode = () => {
    if (isAboutMeEditing) {
      // Save data when exiting edit mode
      const personalInformationData = {
        about_me: aboutMe?.about_me,
        personal_characteristics: aboutMe?.personal_characteristics,
      };
      dispatch(myAboutMe(personalInformationData))
        .unwrap()
        .then(() => {
          dispatch(getMyProfile());
        })
        .catch(() => {});
    }

    setIsAboutMeEditing((prev) => !prev);
  };
  return (
    <div>
      {/*About me section*/}
      <div className="pb-4">
        <div className={`pb-4 ${styles.personal_info}`}>
          <div
            className={
              i18next.language === "en"
                ? styles.editicon_parent_en
                : styles.editicon_parent_ar
            }
          >
            {from === "CVsPage" ? (
              ""
            ) : isAboutMeEditing ? (
              <img src={SaveEdit} onClick={toggleEditMode} alt="Save" />
            ) : (
              <img src={editIcon} onClick={toggleEditMode} alt="Edit" />
            )}
          </div>
          <h3 className={`${styles.section_title}`}>{t("AboutMe")}</h3>
          {isAboutMeEditing ? (
            <textarea
              className={`${styles.section_paragraph} pb-4 lh-lg`}
              name="about_me"
              value={aboutMe.about_me}
              onChange={handleInputChange}
            />
          ) : (
            <p className={`${styles.section_paragraph} pb-4 lh-lg`}>
              {aboutMe?.about_me || "There are No personal characteristics..."}
            </p>
          )}

          <h6 className=" fw-bold">{t("PersonalCharacteristics")}</h6>
          {isAboutMeEditing ? (
            <textarea
              className={`${isAboutMeEditing ? "" : "ms-5"} ${
                styles.section_paragraph
              }`}
              name="personal_characteristics"
              value={aboutMe.personal_characteristics}
              onChange={handleInputChange}
            />
          ) : (
            <p
              className={`${isAboutMeEditing ? "" : "ms-5"} ${
                styles.section_paragraph
              }`}
            >
              {aboutMe?.personal_characteristics ||
                "There are No personal characteristics..."}
            </p>
          )}
        </div>
      </div>
      {/*End of About me section*/}
      {/*Start of Portfolio*/}
      <div className="py-4 container">
        <div className={`pb-4 ${styles.personal_info}`}>
          <div
            className={
              i18next.language === "en"
                ? styles.editicon_parent_en
                : styles.editicon_parent_ar
            }
          >
            {from === "CVsPage" ? (
              ""
            ) : isAboutMeEditing ? (
              <img src={SaveEdit} onClick={toggleEditMode} alt="Save" />
            ) : (
              <img src={editIcon} onClick={toggleEditMode} alt="Edit" />
            )}
          </div>
          <h3 className={`${styles.section_title} pb-4 `}>Portfolio</h3>
          <div className="row">
            <div className="col-6">
              <img src={PortfolioPic} alt="pic" />
            </div>
            <div className="col-6 position-relative p-0">
              <div className="row py-1 px-2">
                <div className="col-6">
                  <img src={PortfolioPic} alt="pic" />
                </div>
                <div className="col-6">
                  <img src={PortfolioPic} alt="pic" />
                </div>
              </div>
              <div className="row py-1 px-2 ">
                <div className="col-6">
                  <img src={PortfolioPic} alt="pic" />
                </div>
                <div className="col-6">
                  <img src={PortfolioPic} alt="pic" />
                </div>
              </div>
              <p className={`${styles.show_all_photos}`}>Show All Photos (6)</p>
            </div>
          </div>
        </div>
      </div>
      {/*End of Portfolio*/}

      <div>
        {/*Education */}

        <div>
          <div
            className={`d-flex justify-content-between ${styles.Edu_Exp_Poss}`}
          >
            <div className={`d-flex align-items-center ${styles.title_exp}`}>
              <img src={Education} alt="Education" className="me-2" />
              <h5>Education</h5>
            </div>
            <div className="d-flex align-items-center ms-4 ms-md-0 gap-3 gap-lg-5 gap-md-4">
              <img src={UpperArrow} alt="UpperArrow" />
              <img src={AddIcon} alt="AddIcon" />
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          <div className={`${styles.Edu_Exp_Poss_Result}`}>
            <p>Can’t Write and Read</p>
          </div>
        </div>
        {/*End Education */}
        {/*Experience */}
        {/*End Experience */}
        <div>
          <div
            className={`d-flex justify-content-between ${styles.Edu_Exp_Poss}`}
          >
            <div className={`d-flex align-items-center ${styles.title_exp}`}>
              <img src={Experience} alt="Experience" className="me-2 " />
              <h5>Experience</h5>
            </div>
            <div className="d-flex align-items-center ms-4 ms-md-0 gap-3 gap-lg-5 gap-md-4">
              <img src={UpperArrow} alt="UpperArrow" />
              <img src={AddIcon} alt="AddIcon" />
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          <div
            className={`${styles.Edu_Exp_Poss_Result} ${styles.title_exp} d-flex align-items-center gap-3`}
          >
            <img src={CallenderTwo} alt="CallenderTwo" />
            <p>6 years</p>
          </div>
        </div>
        {/*End Experience */}
        {/*Possibility of moving */}
        <div>
          <div
            className={`d-flex justify-content-between ${styles.Edu_Exp_Poss}`}
          >
            <div className={`d-flex align-items-center ${styles.title_exp}`}>
              <img src={Possibility} alt="Possibility" className="me-2 " />
              <h5>Possibility of moving</h5>
            </div>
            <div className="d-flex align-items-center ms-4 ms-md-0 gap-3 gap-lg-5 gap-md-4">
              <img src={UpperArrow} alt="UpperArrow" />
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          <div
            className={`${styles.Edu_Exp_Poss_Result} ${styles.title_exp} d-flex flex-column align-items-start gap-3 py-4`}
          >
            <p> Not willing to leave outside the country</p>
            <p> Willing to leave outside the city</p>
          </div>
        </div>
        {/*End Possibility of moving */}
        {/*Salary of moving */}
        <div>
          <div
            className={`d-flex justify-content-between ${styles.Edu_Exp_Poss}`}
          >
            <div className={`d-flex align-items-center ${styles.title_exp}`}>
              <img src={Salary} alt="Salary" className="me-2 " />
              <h5>Salary</h5>
            </div>
            <div className="d-flex align-items-center ms-4 ms-md-0 gap-3 gap-md-5">
              <img src={UpperArrow} alt="UpperArrow" />
              <img src={editIcon} alt="editIcon" />
            </div>
          </div>
          <div
            className={`${styles.Edu_Exp_Poss_Result} ${styles.title_exp} d-flex flex-column align-items-start gap-3 py-4`}
          >
            <p>Due to the task</p>
          </div>
        </div>
        {/*End Salary of moving */}
      </div>
    </div>
  );
}

export default LeftSideUserProfile;
