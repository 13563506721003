/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { InputField } from "../../../components";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllCities } from "../../../redux/slices/helperSlice";

const daysData = {
  data: [
    { code: 1, default_name: 1 },
    { code: 2, default_name: 2 },
    { code: 3, default_name: 3 },
  ],
};
const monthData = {
  data: [
    { code: 1, default_name: 1 },
    { code: 2, default_name: 2 },
    { code: 3, default_name: 3 },
    { code: 4, default_name: 4 },
    { code: 5, default_name: 5 },
    { code: 6, default_name: 6 },
    { code: 7, default_name: 7 },
    { code: 8, default_name: 8 },
    { code: 9, default_name: 9 },
  ],
};
const yearData = {
  data: [
    { code: 1, default_name: 2000 },
    { code: 2, default_name: 2002 },
    { code: 3, default_name: 2003 },
    { code: 4, default_name: 2004 },
    { code: 5, default_name: 2005 },
    { code: 6, default_name: 2006 },
    { code: 7, default_name: 2007 },
    { code: 8, default_name: 2008 },
    { code: 9, default_name: 2009 },
  ],
};

function StepOne({ onStepDataChange }) {
  const dispatch = useDispatch();
  //SELECTORS
  const { cities, countries, allGendersData } = useSelector((state) => state.helpers);
  const { userProfileData } = useSelector((state) => state.auth);
  //STATES
  const [mainRegister, setMainRegister] = useState({
    first_name: userProfileData?.first_name,
    last_name: userProfileData?.last_name,
    birth_day: "",
    birth_month: "",
    birth_year: "",
    gender: { value: "", code: "" },
    nationality: { value: "", code: "" },
    country: { value: "", code: "" },
    city: { value: "", code: "" },
    mobile_phone: "",
  });

  const [validateErrors, setValidateErrors] = useState({
    first_name: "",
    last_name: "",
    birth_day: "",
    birth_month: "",
    birth_year: "",
    gender: "",
    nationality: "",
    country: "",
    city: "",
    phone: "",
    jobs: "",
    expected_salary: "",
  });
  // =============================== Logic ===============================
  const validate = () => {
    const egyptianPhoneRegex = /^(010|011|012|015)\d{8}$/;

    // Initialize the errors object
    let errors = {
      first_name: "",
      last_name: "",
      birth_day: "",
      birth_month: "",
      birth_year: "",
      gender: "",
      nationality: "",
      country: "",
      city: "",
      phone: "",
      jobs: "",
      expected_salary: "",
    };

    // Validate first name
    if (!mainRegister.first_name?.trim()) {
      errors.first_name = "First name is required.";
    }

    // Validate last name
    if (!mainRegister.last_name?.trim()) {
      errors.last_name = "Last name is required.";
    }

    // Validate birth day
    if (
      !mainRegister.birth_day ||
      isNaN(mainRegister.birth_day) ||
      mainRegister.birth_day < 1 ||
      mainRegister.birth_day > 31
    ) {
      errors.birth_day = "Invalid day.";
    }

    // Validate birth month
    if (
      !mainRegister.birth_month ||
      isNaN(mainRegister.birth_month) ||
      mainRegister.birth_month < 1 ||
      mainRegister.birth_month > 12
    ) {
      errors.birth_month = "Invalid month.";
    }

    // Validate birth year
    const currentYear = new Date().getFullYear();
    if (
      !mainRegister.birth_year ||
      isNaN(mainRegister.birth_year) ||
      mainRegister.birth_year < 1900 ||
      mainRegister.birth_year > currentYear
    ) {
      errors.birth_year = "Invalid year.";
    }

    // Validate gender
    if (!mainRegister?.gender) {
      errors.gender = "Gender is required.";
    }

    // Validate nationality
    if (!mainRegister.nationality) {
      errors.nationality = "Nationality is required.";
    }

    // Validate country
    if (!mainRegister.country) {
      errors.country = "Country is required.";
    }

    // Validate city
    if (!mainRegister.city) {
      errors.city = "City is required.";
    }

    // Validate phone number
    if (!mainRegister.phone || !egyptianPhoneRegex.test(mainRegister.phone)) {
      errors.phone = "Invalid phone number.";
    }

    // Validate jobs
    if (!mainRegister.jobs) {
      errors.jobs = "Job title is required.";
    }

    // Validate expected salary
    if (
      !mainRegister.expected_salary ||
      isNaN(mainRegister.expected_salary) ||
      mainRegister.expected_salary <= 0
    ) {
      errors.expected_salary = "Invalid expected salary.";
    }

    // Update the validation errors state
    setValidateErrors(errors);

    // Return true if there are no errors
    return Object.values(errors).every((error) => !error);
  };

  const registerChangeHandler = (propertyName) => (e) => {
    setMainRegister((prev) => ({
      ...prev,
      [propertyName]: e.target.value,
    }));
  };

  const handleFieldChange =
    (field, list, key = "default_name") =>
      (event) => {
        const selectedValue = event.target.value;
        const selectedItem = list?.find((item) => item[key] === selectedValue);

        if (selectedItem) {
          const itemCode = selectedItem.code;
          setMainRegister((prev) => ({
            ...prev,
            [field]: { value: selectedValue, code: itemCode },
          }));
        }
      };

  useEffect(() => {
    // if (!validate()) return;
    const stepOneData = {
      first_name: mainRegister?.first_name,
      last_name: mainRegister?.last_name,
      birth_year: Number(mainRegister?.birth_year),
      birth_month: Number(mainRegister?.birth_month),
      birth_day: Number(mainRegister?.birth_day),
      gendar: mainRegister?.gender.code,
      nationality: Number(mainRegister?.nationality.code), // Temp
      country: mainRegister.country.code,
      city: mainRegister.city.code,
      mobile_phone: mainRegister.mobile_phone,
    };
    console.log('stepOneData', stepOneData);
    onStepDataChange(stepOneData); // Update parent with current step data
  }, [JSON.stringify(mainRegister), onStepDataChange]);

  useEffect(() => {
    dispatch(getAllCities(mainRegister.country.code));
  }, [dispatch, mainRegister.country.code]);

  return (
    <main
      className={` flex-column d-flex justify-content-center position-relative`}
    >
      <div
        className={`d-flex flex-column align-items-center py-4 mt-2 ${styles.page_header}`}
      >
        <p>{t("MainStep")}</p>
        <p>
          Tell Us About <span>{mainRegister?.first_name}</span>
        </p>
      </div>
      {/*Start Forms */}
      <form>
        {/*============================= Start Section 1 ============================ */}
        <div className={`${styles.background_forms} p-5 mb-4 container`}>
          <h3 className="pb-4"> Your Personal Info</h3>
          <div className="row mb-3 d-flex justify-content-between">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="input1" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  id="input1"
                  placeholder="First Name"
                  onChange={registerChangeHandler("first_name")}
                  value={mainRegister.first_name}
                />
              </div>
              {validateErrors.first_name && (
                <p className="text-danger">{validateErrors.first_name}</p>
              )}
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="input2" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="input2"
                  placeholder="Last Name"
                  onChange={registerChangeHandler("last_name")}
                  value={mainRegister.last_name}
                />
              </div>
              {validateErrors.last_name && (
                <p className="text-danger">{validateErrors.last_name}</p>
              )}
            </div>
          </div>
          <div className="row mb-3 d-flex align-items-center">
            <label className="form-label">BirthDay</label>
            <div className="col-md-3">
              <div className="mb-3">
                <InputField
                  type="text"
                  className="form-control"
                  id="input3"
                  placeholder={t("Day")}
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                  onChange={(e) =>
                    setMainRegister((prev) => ({
                      ...prev,
                      birth_day: e.target.value,
                    }))
                  }
                  isList={true}
                  list={daysData?.data?.map((day) => day.default_name)}
                />
                {validateErrors.dayBirth && (
                  <p className="text-danger">{validateErrors.dayBirth}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <InputField
                  type="text"
                  className="form-control"
                  id="input4"
                  placeholder={t("Month")}
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                  onChange={(e) =>
                    setMainRegister((prev) => ({
                      ...prev,
                      birth_month: e.target.value,
                    }))
                  }
                  isList={true}
                  list={monthData?.data?.map((day) => day.default_name)}
                />
                {validateErrors.monthBirth && (
                  <p className="text-danger">{validateErrors.monthBirth}</p>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <InputField
                  type="text"
                  className="form-control"
                  id="input5"
                  placeholder={t("Year")}
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                  isList={true}
                  list={yearData?.data?.map((day) => day.default_name)}
                  onChange={(e) =>
                    setMainRegister((prev) => ({
                      ...prev,
                      birth_year: e.target.value,
                    }))
                  }
                />
                {validateErrors.yearBirth && (
                  <p className="text-danger">{validateErrors.yearBirth}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <InputField
                label="Gender"
                placeholder="Choose an option"
                value={mainRegister.gender.value}
                onChange={handleFieldChange("gender", allGendersData?.data)}
                isList={true}
                list={allGendersData?.data?.map((el) => el.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            {validateErrors.gender && (
              <p className="text-danger">{validateErrors.gender}</p>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <InputField
                label="Nationality"
                placeholder="Choose an option"
                value={mainRegister.nationality.value}
                onChange={handleFieldChange("nationality", countries?.data)}
                isList={true}
                list={countries?.data?.map((el) => el.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            {validateErrors.nationality && (
              <p className="text-danger">{validateErrors.nationality}</p>
            )}
          </div>
        </div>
        {/*============================= End Section 1 ============================ */}
        {/*============================= Start Section 2 ============================ */}
        <div className={`${styles.background_forms} p-5 my-5 container`}>
          <h3 className="pb-4"> {t("YourLocation")}</h3>
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <InputField
                label={t("Country")}
                placeholder={t("ChooseAnOption")}
                value={mainRegister.country.value}
                onChange={handleFieldChange("country", countries?.data)}
                isList={true}
                list={countries?.data?.map((country) => country.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            {validateErrors.country && (
              <p className="text-danger">{validateErrors.country}</p>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <InputField
                label={t("City")}
                placeholder={t("ChooseAnOption")}
                value={mainRegister.city?.value}
                onChange={handleFieldChange("city", cities?.data)}
                isList={true}
                list={cities?.data?.map((city) => city.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            {validateErrors.city && (
              <p className="text-danger">{validateErrors.city}</p>
            )}
          </div>
        </div>
        {/*============================= End of Section 2 ============================ */}
        {/*============================= Start of Section 3 ============================ */}
        <div className={`${styles.background_forms} p-5 my-5 container`}>
          <h3 className="pb-4">Contact Info</h3>
          <div className="col-md-6">
            <label htmlFor="input9" className="form-label">
              {t("MobileNumber")}
            </label>
            <input
              type="text"
              className="form-control"
              id="input8"
              placeholder={t("MobileNumber")}
              onChange={registerChangeHandler("mobile_phone")}
            />
          </div>
          {validateErrors.phone && (
            <p className="text-danger">{validateErrors.phone}</p>
          )}
        </div>
        {/*============================= End of Section 3 ============================ */}
      </form>
    </main>
  );
}

export default StepOne;
