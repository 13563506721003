import React from "react";
import { Button } from "../../../components/index";
//styles
import styles from "./styles.module.css";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
function RightWelcomeEmployee() {
  const navigate = useNavigate()
  return (
    <div
      className="d-flex flex-column m-auto align-items-center"
      
    >
      <div className={`${styles.hiring_box} mb-4 w-100 pb-5`}>
        <h4>{t("Hiring")}?</h4>
        <p>If you looking for hire right candidate start hire now.</p>
        <Button
          title={t("StartHiring")}
          style={`py-2 fw-normal`}
          onClick={()=> navigate("/jobsApplications" , {state: {get :"allApps"}})}
        />
      </div>
      <div className={`mb-4 ${styles.hiring_box} w-100 pb-5`}>
        <h4>{t("CVs")}</h4>
        <p>
        If you looking for hire right candidate start hire now.
        </p>
        <Button
          title={t("Browse CVs")}
          to={`/searchCV`}
          style={`py-2 fw-normal`}
        />
      </div>
    </div>
  );
}

export default RightWelcomeEmployee;
