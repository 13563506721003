import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
//Styles
import styles from "./styles.module.css";
import { Button, InputField } from "../../../components";
import { useSelector } from "react-redux";
import { CancelClose } from "../../../assets";
import { t } from "i18next";
const languageList = ["Arabic", "English", "Germany"];

const degreeData = [
  { code: "1", default_name: "2023" },
  {
    code: "2",
    default_name: "2022",
  },
  { code: "3", default_name: "2021" },
  { code: "4", default_name: "2020" },
];

function StepThree({ onStepDataChange }) {
  const { professionalInfoData } = useSelector((state) => state.auth);
  const {
    experienceYearsData,
    educationalLevelsData,
    fieldsOfStudyData,
    universitiesData,
    gradesData,
    allSkillsData,
    allLanguagesData,
    allLanguagesLevelData,
  } = useSelector((state) => state.helpers);
  console.log(educationalLevelsData);
  // console.log(professionalInfoData);
  // ================================ States ================================
  //education level
  const [currentEducationLevel, setCurrentEducationLevel] = useState(""); // student id (Sent)
  //experiences
  const [experienceValue, setExperienceValue] = useState("");
  const [experienceID, setExperienceID] = useState(""); // experience id (Sent)
  const [degreeNumber, setDegreeNumber] = useState(""); // degree number (Sent)
  //languages and levels
  const [languageValue, setLanguageValue] = useState("");
  const [languageID, setLanguageID] = useState(""); // Language id
  const [languageLevelValue, setLanguageLevelValue] = useState("");
  const [languageLevelID, setLanguageLevelID] = useState(""); // Language Level id
  const [languages, setLanguages] = useState([]);
  const [languagesDataIDs, setLanguagesDataIDs] = useState([]); // Language Data (Sent)
  //Fields of Study
  const [studyField, setStudyField] = useState("");
  const [fieldsData, setFieldsData] = useState([]);
  const [fieldsCode, setFieldsCode] = useState([]);
  //university
  const [universityValue, setUniversityValue] = useState("");
  const [universityCode, setUniversityCode] = useState("");
  //grade
  const [gradeValue, setGradeValue] = useState("");
  const [gradeValueID, setGradeValueID] = useState("");
  //skills
  const [skillsValue, setSkillsValue] = useState("");
  const [skillsData, setSkillsData] = useState([]);
  const [skillsCode, setSkillsCode] = useState([]);
  //
  const [uploadedFile, setUploadedFile] = useState(null);
  // ================================ Refs ================================
  const fileInputRef = useRef(null);

  // ================================ Select Handler ================================
  const handleExperienceChange = (event) => {
    const selectedExperienceName = event.target.value;
    setExperienceValue(selectedExperienceName);

    const selectedExperience = educationalLevelsData?.data?.find(
      (exp) => exp?.default_name
    );
if (selectedExperience) {
      const experienceId = selectedExperience.code;
      setExperienceID(experienceId);
    }
  };
  const handleDegreeChange = (event) => {
    const selectedDegree = event.target.value;
    setDegreeNumber(selectedDegree);
  };
  const currentCareerHandler = (event, el) => {
    event.preventDefault();
    setCurrentEducationLevel(el.code);
  };
  const handleLanguageChange = (event) => {
    const selectedLanguageName = event.target.value;
    setLanguageValue(selectedLanguageName);

    const selectedLanguage = allLanguagesData?.data?.find(
      (lang) => lang?.default_name === selectedLanguageName
    );
    if (selectedLanguage) {
      const LanguageId = selectedLanguage.code;
      setLanguageID(LanguageId);
    }
  };
  const handleLanguageLevelChange = (event) => {
    const selectedLanguageLevelName = event.target.value;
    setLanguageLevelValue(selectedLanguageLevelName);

    const selectedLanguageLevel = allLanguagesLevelData?.data?.find(
      (val) => val?.default_name === selectedLanguageLevelName
    );
    if (selectedLanguageLevel) {
      const LanguageId = selectedLanguageLevel.code;
      setLanguageLevelID(LanguageId);
    }
  };

  const handleUniversityChange = (event) => {
    const universityName = event.target.value;
    setUniversityValue(universityName);

    const selectedUniversity = universitiesData?.data?.find(
      (uni) => uni.default_name === universityName
    );
    if (selectedUniversity) {
      const universityCode = selectedUniversity.code;
      setUniversityCode(universityCode);
    }
  };
  const handleGradeChange = (event) => {
    const gradeValueName = event.target.value;
    setGradeValue(gradeValueName);

    const selectedGradeValue = gradesData?.data?.find(
      (gra) => gra?.default_name === gradeValueName
    );
    if (selectedGradeValue) {
      const gradeID = selectedGradeValue.code;
      setGradeValueID(gradeID);
    }
  };

  // ============================= Logics Functions Handler =============================
  const handleUploadClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
    ];

    if (!file) {
      alert("The Document field must be a file.");
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      alert("The Document field must be a file of type: docx, doc, pdf.");
      return;
    }

    // If the file passes the validation, set it
    setUploadedFile(file);
  };
  const handleDeleteClick = () => {
    setUploadedFile(null);
    fileInputRef.current.value = null; // Reset the input file
  };
  const handleFieldOfStudyChange = (event) => {
    const selectedField = fieldsOfStudyData?.data?.find(
      (field) => field.default_name === event.target.value
    );
    setStudyField(selectedField);
  };
  const handleAddFieldStudy = (e) => {
    e.preventDefault();
    if (studyField) {
      const { default_name, code } = studyField;

      // Check if the field is already added
      if (fieldsData.includes(default_name)) {
        alert("This field is already added.");
        return;
      }

      // Add the field name and code to their respective states
      setFieldsData([...fieldsData, default_name]);
      setFieldsCode([...fieldsCode, code]);

      // Clear the selected field
      setStudyField(null);
    } else {
      alert("Please select a Field of Study.");
    }
  };
  const handleRemoveFields = (codeToRemove) => {
    // Find the index of the code to remove
    const indexToRemove = fieldsCode.indexOf(codeToRemove);

    if (indexToRemove > -1) {
      // Remove the corresponding name and code
      setFieldsData(fieldsData.filter((_, index) => index !== indexToRemove));
      setFieldsCode(fieldsCode.filter((_, index) => index !== indexToRemove));
    }
  };
  const handleSkillsChange = (event) => {
    const selectedSkill = allSkillsData?.data?.find(
      (skill) => skill.default_name === event.target.value
    );
    setSkillsValue(selectedSkill);
  };
  const handleAddSkill = (e) => {
    e.preventDefault();

    if (skillsValue) {
      const { default_name, code } = skillsValue;

      // Check if the skill is already in the list
      if (skillsData.includes(default_name)) {
        alert("This Skill is already added.");
        return;
      }

      // Add the skill name and code to their respective states
      setSkillsData([...skillsData, default_name]);
      setSkillsCode([...skillsCode, code]);

      // Clear the selected skill
      setSkillsValue(null);
    } else {
      alert("Please select a Skill.");
    }
  };
  const handleRemoveSkills = (skillToRemove) => {
    const indexToRemove = skillsData.indexOf(skillToRemove);

    // Remove the skill name and its corresponding code
    if (indexToRemove > -1) {
      setSkillsData(skillsData.filter((_, index) => index !== indexToRemove));
      setSkillsCode(skillsCode.filter((_, index) => index !== indexToRemove));
    }
  };
  const handleAddLanguage = (e) => {
    e.preventDefault();
    if (languageValue && languageLevelValue) {
      // Check if the language already exists in the list
      if (languages.some((lang) => lang.language === languageValue)) {
        alert("This language is already added.");
        return;
      }
      // Add new language and proficiency to the list
      setLanguages([
        ...languages,
        {
          code: languageID,
          language: languageValue,
          level: languageLevelValue,
        },
      ]);
      setLanguagesDataIDs([
        ...languagesDataIDs,
        { lang: languageID, level: languageLevelID },
      ]);
      // Clear the selected options
      setLanguageValue("");
      setLanguageLevelValue("");
    } else {
      alert("Please select both language and proficiency.");
    }
  };
  const handleRemoveLanguage = (languageToRemove) => {
    setLanguages(
      languages.filter((lang) => lang.language !== languageToRemove.language)
    );
    setLanguagesDataIDs(
      languagesDataIDs.filter((lang) => lang.lang !== languageToRemove.code)
    );
  };
  //======================== Send Data ========================
  useEffect(() => {
    const stepThreeData = {
      experience_years: experienceID,
      educational_level: currentEducationLevel,
      fields_of_study: fieldsCode,
      university: universityCode,
      degree: degreeNumber,
      grade: gradeValueID,
      skills: skillsCode,
      cv: uploadedFile,
      user_languages: languagesDataIDs,
    };
    console.log(stepThreeData);
    onStepDataChange(stepThreeData);
  }, [
    languagesDataIDs,
    fieldsCode,
    uploadedFile,
    gradeValueID,
    skillsCode,
    onStepDataChange,
    experienceID,
    currentEducationLevel,
    universityCode,
    degreeNumber,
  ]);
  return (
    <main className="d-flex flex-column align-items-center">
      <div className="d-flex flex-column align-items-center mb-5">
        <h3 className={`${styles.form_page_title} mb-3`}>
          {t("ProfessionalInfo")}
        </h3>
        <p className="text-center">{t("ProfessionalInfoText")}</p>
      </div>

      {/*Start Form */}
      <div className={`${styles.form_content} w-100`}>
        <Container className="d-flex flex-column">
          {/*Start Forms */}
          <form>
            {/*============================= Section 1 ============================ */}
            <section
              className={` ${styles.background_forms} pb-5  mb-5 container `}
            >
              <h6 className="pb-4">{t("HowManyYearsOfExperienceDoYouHave")}</h6>
              <div className="row mb-3">
                <div className="col-12">
                  <InputField
                    placeholder={t("Select")}
                    value={experienceValue}
                    onChange={handleExperienceChange}
                    isList={true}
                    list={experienceYearsData?.data?.map(
                      (exp) => exp.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
            </section>
            {/*============================= End of Section 1 ============================ */}
            {/*============================= Start of Section 2 ============================ */}
            <section
              className={`${styles.background_forms} py-3 my-3 container`}
            >
              <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center ">
                <h6>{t("WhatIsYourCurrentEducationalLevel")}</h6>
                <span>{t("IfYouCurrentlyStudying")}</span>
              </div>
              <div className={`row d-flex justify-content-between container`}>
                {educationalLevelsData?.data?.map((el) => (
                  <button
                    onClick={(event) => currentCareerHandler(event, el)}
                    key={el.code}
                    className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${
                      styles.current_level_result
                    } ${
                      currentEducationLevel === el.code ? styles.active : ""
                    }`}
                  >
                    {el.default_name}{" "}
                  </button>
                ))}
              </div>
            </section>
            {/*============================= End of Section 2 ============================ */}
            {/*============================= Start of Section 3 ============================ */}
            <section>
              <h6 className="pb-4">{t("DegreeDetails")}</h6>
              <div className="row mb-3 d-flex align-items-center justify-content-between">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("FieldOfStudy")}
                    placeholder={t("Select")}
                    value={studyField ? studyField.default_name : ""}
                    onChange={handleFieldOfStudyChange}
                    isList={true}
                    list={fieldsOfStudyData?.data?.map(
                      (field) => field.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
                <div className="col-md-6">
                  <Button
                    title={t("Add")}
                    style={`shadow px-5 py-2`}
                    onClick={handleAddFieldStudy}
                  />
                </div>
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 gap-md-3 mt-md-0 mt-3">
                  {fieldsData?.map((field, index) => (
                    <div
                      className={`shadow py-2 ${styles.results_style}`}
                      key={index}
                    >
                      <p>{field}</p>
                      <div>
                        <img
                          alt={"Delete"}
                          src={CancelClose}
                          className={styles.results_exit_icon}
                          onClick={() => handleRemoveFields(fieldsCode[index])}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={`${t("University")} / ${t("Institution")}`}
                    placeholder={t("Select")}
                    value={universityValue}
                    onChange={handleUniversityChange}
                    isList={true}
                    list={universitiesData?.data?.map(
                      (field) => field.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("WhenDidYouGetYourDegree")}
                    placeholder={t("Select")}
                    value={degreeNumber}
                    onChange={handleDegreeChange}
                    isList={true}
                    list={degreeData.map((deg) => deg.default_name)}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("Grade")}
                    placeholder={t("Select")}
                    value={gradeValue}
                    onChange={handleGradeChange}
                    isList={true}
                    list={gradesData?.data?.map((field) => field.default_name)}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
            </section>
            {/*============================= End of Section 3 ============================ */}
            {/*============================= Start of Section 4 ============================ */}
            <section className="my-5 py-5">
              <h6 className="pb-4">{t("WhatLanguagesDoYouKnow")} </h6>
              <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-center gap-3 gap-md-5">
                <div className="flex-grow-1">
                  <InputField
                    label={t("Language")}
                    placeholder={t("Select")}
                    value={languageValue}
                    onChange={handleLanguageChange}
                    isList={true}
                    list={allLanguagesData?.data?.map(
                      (lang) => lang.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
                <div className="flex-grow-1">
                  <InputField
                    label={t("level")}
                    placeholder={t("Select")}
                    value={languageLevelValue}
                    onChange={handleLanguageLevelChange}
                    isList={true}
                    list={allLanguagesLevelData?.data.map(
                      (lvl) => lvl.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
                <Button
                  title={t("Add")}
                  style={`shadow px-5 py-2`}
                  onClick={handleAddLanguage}
                />
              </div>

              {/* Render Added Languages */}
              <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 gap-md-3 mt-md-0 mt-3">
                {languages.map((lang, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-center gap-3 py-2 ${styles.results_style}`}
                  >
                    <h5>{lang.language}:</h5>
                    <p>{lang.level}</p>

                    <div>
                      <img
                        alt={"delete"}
                        src={CancelClose}
                        className={` ${styles.results_exit_icon}`}
                        onClick={() => handleRemoveLanguage(lang)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/*============================= End of Section 4 ============================ */}
            {/*============================= Start of Section 5 ============================ */}
            <section className={` ${styles.background_forms} pb-5  mb-5 `}>
              <h6 className="pb-4">{t("WhatSkillsToolsTechnologies")}</h6>
              <div className="row mb-3">
                <div className="col-md-10 col-12">
                  <InputField
                    placeholder={t("Select")}
                    value={skillsValue?.default_name}
                    onChange={handleSkillsChange}
                    isList={true}
                    list={allSkillsData?.data?.map(
                      (field) => field.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
                <div className="col-md-2 col-12">
                  <Button
                    title={t("Add")}
                    style={`shadow px-5 py-2`}
                    onClick={handleAddSkill}
                  />
                </div>
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 gap-md-3 mt-md-0 mt-3">
                  {skillsData?.map((skill, index) => (
                    <div
                      className={`shadow py-2 ${styles.results_style}`}
                      key={index}
                    >
                      <p>{skill}</p>
                      <div>
                        <img
                          alt={"Delete"}
                          src={CancelClose}
                          className={styles.results_exit_icon}
                          onClick={() => handleRemoveSkills(skill)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {/*============================= End of Section 5 ============================ */}
            {/*============================= Start of Section 6 ============================ */}
            <section className="my-5 py-5">
              <h6 className="pb-4">{t("UploadYourDocument")}</h6>
              <div className="w-100 d-flex flex-column align-items-start">
                <Button
                  title={t("UploadDocument")}
                  style={styles.cv_btn}
                  onClick={handleUploadClick}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {uploadedFile && (
                  <div className="mt-3">
                    <p className="mb-1">
                      {t("UploadedFile")}: {uploadedFile.name}
                    </p>
                    <Button
                      title={"Delete"}
                      style={`px-3 py-2`}
                      onClick={handleDeleteClick}
                    />
                  </div>
                )}
              </div>
            </section>
            {/*============================= End of Section 6 ============================ */}
          </form>
        </Container>
      </div>
      {/*End Form */}
    </main>
  );
}

export default StepThree;
