import React from "react";
import Button from "../../../components/Button/index";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
function RightHandSideCompany() {
  return (
    <div className="d-flex flex-column m-auto align-items-center">
      <div className={`${styles.hiring_box} mb-4 w-100`}>
        <h4>Hiring?</h4>
        <p>Sign up for an employer account and post your job</p>
        <Button title="Start hiring" to={``} style={`py-2 fw-normal ${styles.custom_btns}`} />
      </div>
      <div className={`mb-4 ${styles.hiring_box} w-100`}>
        <h4>Discover more jobs</h4>
        <p>
          Join Shoghl now and learn about all <br /> companies hiring in egypt
        </p>
        <Button
          title="Join and find your job"
          to={``}
          style={`py-2 fw-normal ${styles.custom_btns}`}
        />
      </div>
      {/*<div className={`pb-4 ${styles.jobs_by_field} w-100`}>
        <h4>Jobs By Field</h4>
        <Link to={"/#"}>All vacancies at Saudian Cultural Center</Link>
        <Link to={"/#"}>General Manager jobs in Saudian</Link>
        <Link to={"/#"}>General Manager jobs in Saudian</Link>
        <Link to={"/#"}>Operations/Management jobs in Saudian</Link>
        <Link to={"/#"}>Education/Teaching jobs in Saudian</Link>
        <Link to={"/#"}>Jobs in Saudian</Link>
      </div> */}
      
    </div>
  );
}

export default RightHandSideCompany;
