import React from "react";
import JobProfileHeader from "./JobProfileHeader";
import JobRightProfile from "./JobRightProfile";
import JobLeftProfile from "./JobLeftProfile";
import { Container } from "react-bootstrap";
function JobProfile() {
  return (
    <div>
      <JobProfileHeader />
      <Container>
        <div className="row pt-2 pt-md-5 py-2 m-auto ">
          <div className="col-12 col-md-7 order-lg-1 order-md-1 order-2">
            <JobLeftProfile />
          </div>
          <div className=" ps-0 ps-md-5  py-5 py-md-0 col-md-5 order-lg-2 order-md-2 order-1 ">
            <JobRightProfile /> 
          </div>
        </div>
      </Container>
    </div>
  );
}

export default JobProfile;
