import { Nav, Navbar } from "react-bootstrap";
//image
import {
  logo,
  register,
  login,
  ProfilePic,
  Notification,
  Globaly,
  ArLogout,
} from "../../assets";
//styles
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultAct, logoutSuccess } from "../../redux/slices/authSlice";
import { persistor } from "../../redux/store";
import { useTranslation } from "react-i18next";
const Header = () => {
  const { t } = useTranslation();
  // const shoglStatus = ["Default", "Candidate", "Corporate"];
  const navigate = useNavigate();
  const { userProfileData, userStatus, companyInfo } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  // const [headerStatus, setHeaderStatus] = useState(userStatus ? userStatus : "Default");
  const [headerStatus, setHeaderStatus] = useState(userStatus || "Default");
  const { i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("language") || "en";
  const [language, setLanguage] = useState(savedLanguage);
  useEffect(() => {
    setHeaderStatus(userStatus);
  }, [userStatus]);

  // =============================== Start Translation Button ===============================
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem("language", lng); // Save selected language to localStorage
    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr"; // Handle RTL dynamically
  };

  useEffect(() => {
    changeLanguage(savedLanguage); // Set language on initial load
  }, [savedLanguage]);
  // =============================== End Translation Button===============================

  const logutHandler = () => {
    // Clear localStorage data
    localStorage.removeItem("auth");
    localStorage.removeItem("contact");
    localStorage.removeItem("products");
    localStorage.removeItem("_persist");
    localStorage.clear();

    // Dispatch the logout action to reset auth state in Redux
    dispatch(logoutSuccess());
    dispatch(defaultAct("Default"));

    // Purge the persisted state to clear all Redux-persist data
    persistor.purge();

    // Redirect to the login page
    navigate("");
  };

  return (
    <main>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`bg-body-tertiary py-md-0 ${styles.nav}`}
      >
        <div className={`container-fluid container-lg ${styles.main_nav}`}>
          <Navbar.Brand
            className={`col-4 col-md-3 col-lg-2 ${
              i18n.language === "ar" ? "ms-3" : ""
            }`}
          >
            <Link to={userStatus === "Corporate" ? "/companyLandingPage" : "/"}>
              <img src={logo} alt="Shogl logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={styles.toggle_nav}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={styles.nav_links_content}
          >
            <Nav
              className={`d-flex align-items-center ${styles.main_pages} py-md-3 ps-md-3 gap-4 ${styles.responsive_nav} `}
            >
              <Nav.Link>
                <Link
                  to={
                    headerStatus === "Default"
                      ? "/managementJobs"
                      : headerStatus === "Candidate"
                      ? "/managementJobs"
                      : "/welcomeEmployee"
                  }
                  className={styles.header_links}
                >
                  {headerStatus === "Default"
                    ? t("BrowseJob")
                    : headerStatus === "Candidate"
                    ? t("Explore")
                    : t("dashboard")}
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to={
                    headerStatus === "Default"
                      ? "/companyLandingPage"
                      : headerStatus === "Candidate"
                      ? "/managementJobsNotFilter"
                      : null
                  }
                  className={`${styles.header_links}`}
                >
                  {headerStatus === "Default" ? (
                    t("ForEmployers")
                  ) : headerStatus === "Candidate" ? (
                    t("Save")
                  ) : (
                    <li className={`nav-item dropdown`}>
                      <Link
                        className={`text-center nav-link dropdown-toggle ${styles.drop_down} `}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {t("posts")}
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="">
                            Manage Jobs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="">
                            Manage Internships
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="">
                            Search All Posts
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="">
                            Add New Job
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="">
                            Add New Internships
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </Link>
              </Nav.Link>
              {headerStatus !== "Default" && (
                <Nav.Link>
                  <Link
                    to={
                      headerStatus === "Candidate"
                        ? "/candidateApplications"
                        : headerStatus === "Corporate"
                        ? "/"
                        : ""
                    }
                    className={styles.header_links}
                  >
                    {headerStatus === "Candidate" && t("Applications")}
                    {headerStatus === "Corporate" && (
                      <li className={`nav-item dropdown`}>
                        <Link
                          className={` text-center nav-link dropdown-toggle ${styles.drop_down}`}
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t("candidates")}
                        </Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item" to="/searchCV">
                              {t("searchCv")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/jobsApplications"
                            >
                              {t("jobsApplications")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </Link>
                </Nav.Link>
              )}
            </Nav>
            {headerStatus === "Default" ? (
              <Nav className={`ms-0 ms-lg-4 mt-lg-0 ${styles.responsive_nav}`}>
                <Link
                  to="/login"
                  className="d-flex align-items-center my-2 my-lg-0"
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.nav_icon_Box}>
                    <img
                      src={login}
                      alt="shogl-register"
                      className={`img ${
                        i18n.language === "ar" ? "ms-3" : "me-3"
                      }`}
                    />
                  </div>
                  <span>
                    <Link to="/login" className={styles.header_links}>
                      {t("Login")}
                    </Link>
                  </span>
                </Link>
                <Link
                  to="signup"
                  className={`ms-0 ms-lg-3 d-flex align-items-center  my-2 my-lg-0 ${styles.nav_txt}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.nav_icon_Box}>
                    <img
                      src={register}
                      alt="shogl-register"
                      className={`img ${
                        i18n.language === "ar" ? "ms-3" : "me-3"
                      }`}
                    />
                  </div>
                  <Link to="signup" className={styles.header_links}>
                    {t("signup")}
                  </Link>
                </Link>
              </Nav>
            ) : headerStatus === "Candidate" ? (
              <nav
                className={`d-flex gap-0 align-items-center ms-0 ms-lg-4 mt-3 mt-lg-0 ${styles.responsive_nav} ${styles.candidate_header}`}
              >
                <div className="d-flex align-items-center mb-3 mb-lg-0">
                  <Link to="/userProfile">
                    <img
                      src={ProfilePic}
                      alt="profile pic"
                      className={`img ${
                        i18n.language === "ar" ? "ms-3" : "me-3"
                      } `}
                    />
                  </Link>
                  <Link to="/userProfile">
                    Hi, {userProfileData?.first_name}
                  </Link>
                </div>

                <Link
                  to=""
                  className={`${
                    i18n.language === "ar" ? "me-4" : "ms-4"
                  }  d-flex align-items-center`}
                  onClick={logutHandler}
                >
                  <Link to="" className={styles.header_links}>
                    {t("logout")}
                  </Link>
                  <div className={styles.nav_icon_Box}>
                    <img
                      src={i18n.language === "ar" ? ArLogout : login}
                      alt="logout"
                      className={`img ${
                        i18n.language === "ar" ? "me-1" : "ms-1"
                      }`}
                    />
                  </div>
                </Link>
              </nav>
            ) : (
              <nav
                className={`d-flex gap-4 align-items-center ms-0 ms-lg-4 mt-3 mt-lg-0 ${styles.responsive_nav} ${styles.corporate_header}`}
              >
                <div className="d-flex align-items-center">
                  <Link
                    to=""
                    className={`me-0 ${
                      i18n.language === "ar" ? "ms-lg-3" : "me-lg-3"
                    } ${
                      styles.nav_icon_Box
                    } d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={Notification}
                      alt="notification"
                      className={`img me-2 ${styles.notification_icon_style}`}
                    />
                  </Link>

                  <Link
                    to="completeCompanyProfile"
                    className="d-flex align-items-center gap-2"
                  >
                    <div className={styles.nav_icon_Box}>
                      <img src={register} alt="register" className="img me-0" />
                    </div>
                    <div className="d-flex flex-column align-items-start">
                      <p>{companyInfo?.first_name}</p>
                      <span>{companyInfo?.company_name}</span>
                    </div>
                  </Link>
                </div>

                <Link
                  to=""
                  className="d-flex align-items-center"
                  onClick={logutHandler}
                >
                  <Link to="" className={styles.header_links}>
                    {t("logout")}
                  </Link>
                  <div className={styles.nav_icon_Box}>
                    <img
                      src={i18n.language === "ar" ? ArLogout : login}
                      alt="logout"
                      className={`img ${
                        i18n.language === "ar" ? "me-1" : "ms-1"
                      }`}
                    />
                  </div>
                </Link>
              </nav>
            )}
            <div className={`ms-3 ${styles.switch_language}`}>
              <img
                src={Globaly}
                alt="Global"
                style={{ width: "20px" }}
                className={`${
                  i18n.language === "ar" ? styles.ar_img : styles.en_img
                }`}
              />
              <select
                name="lang"
                id="lang-select"
                value={language}
                onChange={(e) => changeLanguage(e.target.value)}
                className={`${
                  i18n.language === "ar" ? styles.ar_select : styles.en_select
                }`}
              >
                <option value="en">English</option>
                <option value="ar">Arabic</option>
              </select>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </main>
  );
};

export default Header;
