import React from "react";
import { Container } from "react-bootstrap";
import LeftWelcomeEmployee from "./LeftWelcomeEmployee";
import RightWelcomeEmployee from "./RightWelcomeEmployee";
// styles
import welcomeEmployeeStyles from "./styles.module.css";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { handleJobPostEditing } from "../../redux/slices/jobsSlice";
const { job_post_btn, welcome_employee_h } = welcomeEmployeeStyles;
function WelcomeEmployee() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { companyInfo } = useSelector((state) => state.auth);
  const postJobHandler = () => {
    navigate("/jobPost")
    dispatch(handleJobPostEditing(false))
  }
  return (
    <div className="pt-5">
      <Container>
        <div className="d-flex align-items-center gap-4">
          <h3 className={welcome_employee_h}>
            {t("Welcome")} {companyInfo?.first_name}
          </h3>
          <Button
            onClick={postJobHandler}
            title={t("PlusPostJob")}
            style={job_post_btn}
          />
        </div>
      </Container>

      <div>
        <Container className="row pt-2 pt-md-4 py-2 m-auto ">
          <div className="col-12 col-md-8 order-lg-1 order-md-1 order-2">
            <LeftWelcomeEmployee />
          </div>
          <div className=" ps-0 ps-md-0  py-5 py-md-0 col-md-4 order-lg-2 order-md-2 order-1 ">
            <RightWelcomeEmployee />
          </div>
        </Container>
      </div>
    </div>
  );
}

export default WelcomeEmployee;
