import React from "react";
import ManagementJobsHeader from "./ManagementJobsHeader";
import LeftHandSide from "./LeftHandSide";
import RightHandSide from "./RightHandSide";
import { Container } from "react-bootstrap";

function ManagementJobs() {

  return (
    <div>
      <ManagementJobsHeader />
      <Container>
        <div className="row py-2 m-auto">
          <div className="col-12 col-md-8 order-lg-1 order-md-1 order-2">
            <LeftHandSide />
          </div>
          <div className="ps-md-5 py-5 col-md-4 d-md-flex d-lg-block order-lg-2 order-md-2 order-1">
            <RightHandSide />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ManagementJobs;
