import React, { useState, useEffect } from "react";
//images
import { whatsAppIcon } from "../../assets/index";
//Components
import { Button } from "../../components";
//Styles
import Axios, { APIS } from "../../utils/Axios";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCountries,
  getAllGenders,
  getAllLanguages,
  getAllSkills,
  getCareerLevels,
  getDegrees,
  getEducationalLevels,
  getExperienceYears,
  getFieldsOfStudy,
  getGrades,
  getJobCategory,
  getJobTypes,
  getLanguagesLevels,
  getUniversities,
} from "../../redux/slices/helperSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import { t } from "i18next";

function MainRegisterStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.auth);
  const [activeStep, setActiveStep] = useState(1);

  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [stepThreeData, setStepThreeData] = useState({});
  const [stepFourData, setStepFourData] = useState({});
  const [stepFiveData, setStepFiveData] = useState({});
  const [stepSixData, setStepSixData] = useState({});
  const [param] = useSearchParams();

  const handleBackStep = () => {
    if (activeStep > 1) {
      setActiveStep((prevStep) => prevStep - 1);
    }
    window.scrollTo(0, 0);
  };

  const handleNextStep = async () => {
    if (activeStep <= 6) {
      // Consolidate all data
      //Step 1
      if (activeStep === 1 && activeStep < 6) {
        setActiveStep((prevStep) => prevStep + 1);
        console.log("RegisterOne Data:", stepOneData);
        window.scrollTo(0, 0);
      }
      //Step 2
      if (activeStep === 2 && activeStep < 6) {
        setActiveStep((prevStep) => prevStep + 1);
        console.log("RegisterTwo Data:", stepTwoData);
        window.scrollTo(0, 0);
      }
      //Step 3
      if (activeStep === 3 && activeStep < 6) {
        setActiveStep((prevStep) => prevStep + 1);
        console.log("RegisterThree Data:", stepThreeData);
        window.scrollTo(0, 0);
      }
      //Step 4
      if (activeStep === 4 && activeStep < 6) {
        setActiveStep((prevStep) => prevStep + 1);
        console.log("RegisterFour Data:", stepFourData);
        window.scrollTo(0, 0);
      }
      //Step 5
      if (activeStep === 5 && activeStep < 6) {
        setActiveStep((prevStep) => prevStep + 1);
        console.log("RegisterFive Data:", stepFiveData);
        window.scrollTo(0, 0);
      }
      //Step 6
      if (activeStep === 6) {
        // navigate("/");
        console.log("RegisterSix Data:", stepSixData);
        window.scrollTo(0, 0);
        const registerData = {
          ...stepOneData,
          ...stepTwoData,
          ...stepThreeData,
          ...stepFourData,
          ...stepFiveData,
          ...stepSixData,
        };
        const fromData = new FormData();
        Object.keys(registerData)?.map((item) => {
          fromData.append(item, registerData[item]);
        });
        fromData.append("fields", JSON.stringify(registerData?.fields));
        fromData.append("categories", JSON.stringify(registerData?.categories));
        fromData.append("languages", JSON.stringify(registerData?.languages));
        fromData.append("jobs", JSON.stringify(registerData?.jobs));
        fromData.append(
          "experiences",
          JSON.stringify(
            registerData?.experiences?.map((item) => {
              return {
                title: item?.job_title,
                category: item?.category?.code,
                company_name: item?.company_name,
                start_month: item?.starting_month?.code,
                start_year: item?.starting_year?.code,
                end_month: item?.ending_month?.code,
                end_year: item?.ending_year?.code,
                experience: item?.experience_type?.code,
                until_there: 0,
              };
            })
          )
        );
        fromData.append("expected_salary", registerData?.minSalary);
        fromData.append("work_setting", parseInt(1));
        fromData.append("experience_year_id", registerData?.exp_id);
        fromData.append("gendar", registerData?.gendar == 1 ? "m" : "f");
        fromData.append("cv", stepSixData);
        fromData.append("email", param?.get("email"));

        console.log("All Register Data:", registerData);

        try {
          const res = await Axios({
            method: "POST",
            path: APIS.HighRegistrationTwo,
            data: fromData,
            isFormData: true,
          });
          console.log("signUpOne", res?.data);
          localStorage.setItem("access_token", res?.data?.access_token)
          window.location.href = "/"
          return res.data;
        } catch (error) {
          const errorMessage = error?.response?.data?.message || error.message;
          console.log("signUpOne Error", errorMessage);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllGenders());
    dispatch(getCareerLevels());
    dispatch(getJobTypes());
    dispatch(getExperienceYears());
    dispatch(getEducationalLevels());
    dispatch(getFieldsOfStudy());
    dispatch(getUniversities());
    dispatch(getGrades());
    dispatch(getDegrees());
    dispatch(getAllSkills());
    dispatch(getAllLanguages());
    dispatch(getLanguagesLevels());
    dispatch(getJobCategory());
  }, [dispatch]);

  return (
    <div className={`${styles.main_register_background} `}>
      <div className={`p-5 container  d-flex flex-column `}>
        <div className={styles.progress_bar_container}>
          <div
            className={styles.progress_bar_fill}
            style={{ width: `${(activeStep - 1) * (100 / 5)}%` }}
          ></div>
        </div>
        {activeStep === 1 && <StepOne onStepDataChange={setStepOneData} />}
        {activeStep === 2 && <StepTwo onStepDataChange={setStepTwoData} />}
        {activeStep === 3 && (
          <StepThree step={stepThreeData} onStepDataChange={setStepThreeData} />
        )}
        {activeStep === 4 && <StepFour onStepDataChange={setStepFourData} />}
        {activeStep === 5 && (
          <StepFive step={stepFiveData} onStepDataChange={setStepFiveData} />
        )}
        {activeStep === 6 && <StepSix onStepDataChange={setStepSixData} />}
        <div>{error && <p className="text-center text-danger">{error}</p>}</div>
        <div
          className={` ${activeStep === 1
              ? "d-flex justify-content-center justify-content-md-end"
              : "w-100"
            }`}
        >
          <div
            className={` d-flex flex-column flex-sm-row align-items-center  ${activeStep >= 2
                ? "justify-content-around"
                : "justify-content-between"
              }        `}
          >
            {activeStep > 1 && activeStep <= 6 && (
              <Button
                loading={loading}
                onClick={handleBackStep}
                title={t("Back")}
                style={`mb-4 mt-1  shadow ${styles.save_continue_btn}`}
              />
            )}

            {activeStep <= 6 && (
              <Button
                loading={loading}
                onClick={handleNextStep}
                title={activeStep === 6 ? t("Save") : t("SaveAndContinue")}
                style={`mb-4 mt-1  shadow ${styles.save_continue_btn}`}
              />
            )}
          </div>
        </div>
      </div>
      {/*Need Help Icon (Support) */}
      <div className={`d-flex align-items-center ${styles.support_icon}`}>
        <img src={whatsAppIcon} className="me-3 p" alt="whatsAppIcon" />
        <p className="text-nowrap">Need Help?</p>
      </div>
    </div>
  );
}

export default MainRegisterStep;
