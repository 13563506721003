import { t } from "i18next";
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import { bagIcon, LoginImg } from "../../assets";
import { Button } from "../../components";
const OTPPage = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const OTPNumberRef = useRef([]);
  const handleOTPChange = (e, index) => {
    const { value } = e.target;

    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        OTPNumberRef.current[index + 1].focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        OTPNumberRef.current[index - 1].focus();
      }
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        OTPNumberRef.current[index - 1].focus();
      }
    }
  };
  return (
    <main>
      <div className={`${styles.landing} pt-4 container-fluid`}>
        <div className="row flex-column flex-lg-row justify-content-center mb-4 mb-lg-0">
          {/* Register Header Image */}
          <div className="col-12 col-lg-7 mb-4 mb-lg-0 order-first flex-grow-1">
            <figure>
              <img src={LoginImg} alt="LoginImg" className="img" />
            </figure>
          </div>
          {/* Register Header Form */}
          <div
            className={`d-flex flex-column justify-content-center col-12 col-lg-4 flex-grow-1 mb-sm-5 mb-md-5`}
          >
            <form>
              <div
                className={`${styles.signup_form} d-flex flex-column px-5 pt-4 pb-5 container align-items-center`}
              >
                {/* Title & icon */}
                <div className="d-flex flex-column align-items-center mb-4 mt-2">
                  <div className={`${styles.bag_icon}`}>
                    <img src={bagIcon} alt="bagIcon" />
                  </div>
                  <p className="fw-bold fs-2">{t("OTP")}</p>
                </div>

                <div className="d-flex flex-column align-self-start w-100 mb-3">
                  <p className="fw-600 mb-3">OTP Number</p>
                  {/* <input
                      type="text"
                      placeholder="Enter your OTP number"
                      className={`${styles.input_filed}`}
                      ref={OTPNumberRef}
                      onBlur={() => validate()}
                    />*/}

                  <div className="otp-inputs pb-3 d-flex flex-row">
                    {otp.map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) => handleOTPChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (OTPNumberRef.current[index] = el)}
                        className={styles.otp_input}
                      />
                    ))}
                  </div>
                  <p className="fw-600 mb-3">Resend OTP?</p>
                </div>

                <div
                  className={`w-100 d-flex align-items-center flex-column ${styles.end_signup_form}`}
                >
                  <Button
                    to={"/newpassword"}
                    title={"Submit"}
                    style={`shadow mb-2 ${styles.next_btn}`}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OTPPage;
