import React, { useEffect } from "react";
import Swal from "sweetalert2";
import {
  cash,
  CompanyPhoto,
  EditPen,
  ExitXicon,
  King,
  location,
  RayaComp,
} from "../../../assets";
import { IconPlusTitle } from "../../../components";
//styles
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import {
  deleteJobPost,
  getAllMyJobPosts,
  getAllUserApplications,
  saveJobWillBeFilter,
} from "../../../redux/slices/jobsSlice";
import { useNavigate } from "react-router-dom";

function LeftWelcomeEmployee() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyInfo } = useSelector((state) => state.auth);
  const { allMyJobPostsData } = useSelector((state) => state.jobs);

  const handleDeletePost = (code) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0cce68",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      background: "#f9f9f9", // Custom background color
      iconColor: "#0cce68", // Custom icon color
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          job_code: code,
        };
        dispatch(deleteJobPost(data))
          .unwrap()
          .then(() => {
            Swal.fire("Deleted!", "Your job post has been deleted.", "success");
            dispatch(getAllMyJobPosts());
          })
          .catch(() => {
            Swal.fire("Error!", "Failed to delete the job post.", "error");
          });
      }
    });
  };
  const handleEditPost = (code) => {
    console.log(code);
    dispatch(saveJobWillBeFilter(code));
    // dispatch(handleJobPostEditing(true));
    navigate("/jobPost", { state: { from: "edit" } });
  };
  const getApplicantsHandler = (job) => {
    const jobCode = {
      job_code: job?.code,
      is_high_job: 1
    };
    dispatch(getAllUserApplications(jobCode))
      .unwrap()
      .then(() => {
        navigate("/jobsApplications", { state: { get: "jobApp", jobCode: job?.code } });
      })
      .catch(() => { });
  };
  // Function to get button classes based on job types
  const getButtonClasses = (types) => {
    const buttonClasses = {
      Internship: styles.job_status_btn_Internship,
      "Full Time": styles.job_status_btn_fulltime,
      Freelance: styles.job_status_btn_freelance,
      "Free Lance / Project": styles.job_status_btn_freelance,
      "Shift based": styles.job_status_btn_freelance,
      "Part Time": styles.job_status_btn_parttime,
      "Student activity": styles.job_status_btn_parttime,
    };
    return types?.map((type) => buttonClasses[type] || "").join(" ");
  };
  const responsive =
    "d-flex flex-column flex-lg-row justify-content-center align-items-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";

  const OpenVacancies = allMyJobPostsData?.data?.map((job, index) => (
    <div
      className={`${styles.vacancies} mb-4  mt-4 row container mx-2 text-center ${responsive}`}
      key={index}
    >
      <div
        className={`row  text-center  ${responsive} ${styles.vacancies_content}`}
      >
        <div
          className={`c-pointer row col-12 col-md-10 text-center text-md-start align-items-center ${responsive}`}
          onClick={() => getApplicantsHandler(job)}
        >
          <div className="col-12 col-md-4 col-lg-2 d-flex align-items-center mt-5 p-5 p-md-0">
            <img src={CompanyPhoto} alt={"job.title"} />
          </div>
          <div className={`col-10 ${styles.job_info} `}>
            <p className="py-1 mb-2">{job?.title}</p>
            <div
              className={`d-flex flex-column flex-md-row align-items-center gap-4 py-1 pb-3 pb-md-0`}
            >
              <IconPlusTitle
                IconSrc={King}
                title={job?.post_type?.default_name}
                P_Style={`${styles.job_descrebtion}`}
                className="order-2 order-md-1"
              />
              <IconPlusTitle
                IconSrc={location}
                title={`${job?.country?.default_name}, ${job?.city?.default_name}`}
                P_Style={`${styles.job_descrebtion}`}
                className="order-3 order-md-2"
              />
            </div>
            <div className=" mt-md-2">
              <IconPlusTitle
                IconSrc={cash}
                title={`${job?.experience_from} - ${job?.experience_to} 
                  Yrs of Exp · ${job?.career_level?.default_name}. ${job?.salary_from}-${job?.salary_to}
                   ${job?.salary_currency?.default_name} ${job?.salary_per?.default_name}`}
                P_Style={`${styles.job_descrebtion}`}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-2 d-flex flex-row flex-lg-column gap-2 justify-content-center align-items-center mt-4">
          {job?.job_types
            ? job?.job_types?.map((type, index) => (
              <button
                key={index}
                disabled
                className={`${getButtonClasses([[type.default_name]])}`}
              >
                {[type.default_name]}
              </button>
            ))
            : ""}
        </div>
      </div>
      <div>
        <div
          className={`d-flex align-items-center justify-content-evenly justify-content-lg-start mt-4 ${styles.edit_delete_btn}`}
        >
          <div
            className=" d-flex align-items-center"
            onClick={() => handleEditPost(job)}
          >
            <img src={EditPen} alt="EditPen" />
            <button className="ms-1">Edit</button>
          </div>
          <div
            className=" d-flex align-items-center"
            onClick={() => handleDeletePost(job?.code)}
          >
            <img src={ExitXicon} className="ms-3" alt="ExitXicon" />
            <button className="ms-1">Delete</button>
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    dispatch(getAllMyJobPosts());
  }, [dispatch]);
  return (
    <div>
      <div className={`${styles.open_vacancy} px-4 mb-5 `}>
        <h2>
          {" "}
          {t("OpenStylesVacanciesAt")} {companyInfo?.company_name}
        </h2>
        <div className="container">{OpenVacancies}</div>
      </div>
    </div>
  );
}

export default LeftWelcomeEmployee;
