import { combineReducers } from "@reduxjs/toolkit";
import auth from "./slices/authSlice";
import jobs from './slices/jobsSlice'
import helpers from './slices/helperSlice'
const rootReducer = combineReducers({
  auth,
  jobs,
  helpers,
});

export default rootReducer;
