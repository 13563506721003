import React from 'react';
import styles from './styles.module.css';

const { icon_style, icon_plus_title } = styles;

function IconPlusTitle({ IconSrc, title, style ,customIconStyle ,P_Style }) {
  return (
    <div className={icon_plus_title}>
      <img src={IconSrc} alt="icon" className={`${customIconStyle} ${icon_style} `} />
      <p style={style} className={`${P_Style}`}>{title}</p> {/* Apply inline styles directly */}
    </div>
  );
}

export default IconPlusTitle;
