import { t } from "i18next";
import React from "react";
import styles from "./styles.module.css";
import { bagIcon, LoginImg } from "../../assets";
import { Button } from "../../components";
const ForgetPassword = () => {
  return (
    <main>
      <div className={`${styles.landing} pt-4 container-fluid`}>
        <div className="row flex-column flex-lg-row justify-content-center mb-4 mb-lg-0">
          {/* Register Header Image */}
          <div className="col-12 col-lg-7 mb-4 mb-lg-0 order-first flex-grow-1">
            <figure>
              <img src={LoginImg} alt="LoginImg" className="img" />
            </figure>
          </div>
          {/* Register Header Form */}
          <div
            className={`d-flex flex-column justify-content-center col-12 col-lg-4 flex-grow-8 mb-sm-5 mb-md-5`}
          >
            <form>
              <div
                className={`${styles.signup_form} d-flex flex-column px-5 pt-4 pb-5 container align-items-center`}
              >
                {/* Title & icon */}
                <div className="d-flex flex-column align-items-center mb-4 mt-2">
                  <div className={`${styles.bag_icon}`}>
                    <img src={bagIcon} alt="bagIcon" />
                  </div>
                  <p className="fw-bold fs-2">{t("Phone Number")}</p>
                </div>

                <div className="d-flex flex-column align-self-start w-100 mb-3">
                  <p className="fw-600 mb-3">Phone Number</p>
                  <input
                    type="tel"
                    placeholder="Enter your Phone Number"
                    className={`${styles.input_filed}`}
                    // ref={borderNumberRef}
                    // onBlur={() => validate()}
                  />
                </div>

                <div
                  className={`w-100 d-flex align-items-center flex-column ${styles.end_signup_form}`}
                >
                  <Button
                    to={"/otppage"}
                    title={"Get OTP"}
                    style={`shadow mb-2 ${styles.next_btn}`}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgetPassword;
