import React from "react";
//images
import { boxWithVector, boxWithoutVector } from "../../assets";
//styles
import styles from "./styles.module.css";

function CheckBox({ label, labelStyle, imgStyle,option, selectedOption, onChange }) {
  const checkBoxHandler = (option) => {
    return selectedOption === option ? boxWithVector : boxWithoutVector;
  };

  return (
    <div className="d-flex flex-column align-self-start ">
      <div className={` ${styles.check_box}`}>
        <img
          src={checkBoxHandler(option)}
          onClick={() => onChange(option)}
          className={`me-2 ${imgStyle}`}
          alt="checkbox"
        />
        <label className={`${labelStyle}`}>{label}</label>
      </div>
    </div>
  );
}

export default CheckBox;
