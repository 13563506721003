import React, { useRef, useState } from "react";
import { Button, CheckBox } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { BagIcon, LoginImg, RoundedCheck } from "../../assets";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  corporateAct,
  getMyProfile,
  loginComapny,
  loginCompany,
  loginOne,
  trigerHelloUser,
} from "../../redux/slices/authSlice";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.auth);
  const [userStatus, setUserStatus] = useState("Candidate");
  const [isChecked, setIsChecked] = useState(false);
  const [validateErrors, setValidateErrors] = useState({
    userStatus: "",
    email: "",
    password: "",
  });

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;

    let errors = {
      email: "",
      password: "",
      userStatus: "",
    };
    const emailValue = emailRef.current?.value?.trim() || "";
    const passwordValue = passwordRef.current?.value?.trim() || "";
    // Validate Corporate & Candidate Fields
    if (userStatus === "Corporate" || userStatus === "Candidate") {
      if (!emailValue || !emailRegex.test(emailValue)) {
        errors.email = "Valid email is required";
      }
      if (!passwordValue || !passwordRegex.test(passwordValue)) {
        errors.password =
          "Password must be at least 8 characters long, include one uppercase letter and one number";
      }
    }
    // Check if userStatus is selected
    if (!userStatus) {
      errors.userStatus = "Please select either Corporate or Candidate";
    }

    // Update state with validation errors
    setValidateErrors(errors);
    // Return true if no errors
    return Object.values(errors).every((error) => !error);
  };

  const loginHandler = (e) => {
    e.preventDefault();
    // if (!validate()) return;
    const clearFields = () => {
      if (userStatus === "Candidate") {
      } else if (userStatus === "Corporate") {
        emailRef.current.value = "";
        passwordRef.current.value = "";
      }
    };

    //======================= CORPORATE =============================
    if (userStatus === "Corporate") {
      dispatch(corporateAct("Corporate"));
      const formData = new FormData();
      formData.append("email", emailRef.current.value);
      formData.append("password", passwordRef.current.value);

      dispatch(loginCompany(formData))
        .unwrap()
        .then((response) => {
          if (response?.data?.access_token) {
            localStorage.setItem("access_token", response?.data?.access_token);
            navigate("/companyLandingPage");
          } else {
            alert("Email Or Password Is Wrong")
          }
        })
        .catch(() => alert("Email Or Password Is Wrong"))
    }
    //======================= CANDIDATE =============================
    if (userStatus === "Candidate") {
      dispatch(corporateAct("Candidate"));
      const formData = new FormData();
      formData.append("email", emailRef.current.value);
      formData.append("password", passwordRef.current.value);

      dispatch(loginOne(formData))
        .unwrap()
        .then((response) => {
          if (response?.access_token) {
            localStorage.setItem("access_token", response?.access_token);
            dispatch(trigerHelloUser(1));
            dispatch(getMyProfile());
            navigate("/");
          } else {
            alert("Email Or Password Is Wrong")
          }
        })
        .catch(() => alert("Email Or Password Is Wrong"))
    }
  };

  const handleUserStatus = (e, status) => {
    e.preventDefault();
    setUserStatus(status);
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <main>
      <div className={`${styles.landing} pt-4 container-fluid`}>
        <div className="row flex-column flex-lg-row justify-content-center mb-4 mb-lg-0">
          {/* Register Header Image */}
          <div className="col-12 col-lg-7 mb-4 mb-lg-0 order-first flex-grow-1">
            <figure>
              <img src={LoginImg} alt="meeting" className="img" />
            </figure>
          </div>
          {/* Register Header Form */}
          <div
            className={`d-flex flex-column justify-content-center col-12 col-lg-4 flex-grow-1 mb-sm-5 mb-md-5`}
          >
            <form>
              <div
                className={`${styles.signup_form} d-flex flex-column px-5 pt-4 pb-5 container align-items-center`}
              >
                {/* Title & icon */}
                <div className="d-flex flex-column align-items-center">
                  <div className={`${styles.bag_icon}`}>
                    <img src={BagIcon} alt="bagIcon" />
                  </div>
                  <p className="fw-bold fs-2">{t("Login")}</p>
                </div>
                {/* Two Button */}
                <div className="d-flex justify-content-evenly gap-4 mt-3 mb-5">
                  <Button
                    style={`px-4 px-md-5 py-3 shadow ${userStatus === "Candidate" ? styles.btn_Candidate : ""
                      }`}
                    title={t("Candidate")}
                    onClick={(e) => handleUserStatus(e, "Candidate")}
                    leftIcon={
                      userStatus === "Candidate" && i18n.language === "ar"
                        ? RoundedCheck
                        : null
                    }
                    rightIcon={
                      userStatus === "Candidate" && i18n.language === "en"
                        ? RoundedCheck
                        : null
                    }
                  />
                  <Button
                    style={`px-4 px-md-5 py-3  ${userStatus === "Corporate" ? styles.btn_Candidate : ""
                      }`}
                    title={t("Corporate")}
                    onClick={(e) => handleUserStatus(e, "Corporate")}
                    leftIcon={
                      userStatus === "Corporate" && i18n.language === "ar"
                        ? RoundedCheck
                        : null
                    }
                    rightIcon={
                      userStatus === "Corporate" && i18n.language === "en"
                        ? RoundedCheck
                        : null
                    }
                  />
                </div>
                {/* Inputs */}

                <>
                  {userStatus === "Candidate" ? (
                    <p>Welcome Back, Candidates</p>
                  ) : userStatus === "Corporate" ? (
                    <p>Welcome Back, Corporates</p>
                  ) : (
                    ""
                  )}
                  <div className="d-flex flex-column align-self-start w-100 mb-3">
                    <p className="fw-600 mb-3">{t("Email")}</p>
                    <input
                      type="text"
                      placeholder={t("EnterYourEmailAddress")}
                      className={`${styles.input_filed}`}
                      ref={emailRef}
                      onBlur={() => validate()}
                    />
                    {validateErrors.email && (
                      <p className="text-danger">{validateErrors.email}</p>
                    )}
                  </div>
                  <div className="d-flex flex-column align-self-start w-100 mb-3">
                    <p className="fw-600 mb-3">{t("Password")}</p>
                    <input
                      type="password"
                      placeholder={t("EnterYourPassword")}
                      className={`${styles.input_filed}`}
                      ref={passwordRef}
                      onBlur={() => validate()}
                    />
                    {validateErrors.password && (
                      <p className="text-danger">{validateErrors.password}</p>
                    )}
                  </div>
                </>
                {/* Remember Me CheckBox */}
                <div className={`w-100 mb-4 ${styles.remember_check}`}>
                  <div className="d-flex align-items-center text-start">
                    <CheckBox
                      label={t("RememberMe")}
                      option={"option1"}
                      labelStyle={styles.label_check_style}
                      selectedOption={isChecked ? "option1" : null}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Start Handle Error */}
                <div className={styles.error_style}>
                  {error && <p>{error}</p>}
                  {validateErrors.userStatus && (
                    <p>{validateErrors.userStatus}</p>
                  )}
                </div>
                {/* Login Button */}
                <div
                  className={`w-100 d-flex align-items-center flex-column ${styles.end_signup_form}`}
                >
                  {userStatus === "Candidate" ? (
                    <Button
                      loading={loading}
                      title={t("Login")}
                      style={`shadow mb-2 ${styles.next_btn}`}
                      onClick={loginHandler}
                    />
                  ) : (
                    <Button
                      title={t("Login")}
                      style={`shadow mb-2 ${styles.next_btn}`}
                      loading={loading}
                      onClick={loginHandler}
                    />
                  )}
                  <div
                    className={`w-100 d-flex align-items-center flex-column ${styles.forget_account}`}
                  >
                    <Link to="/forgetPassword" className="fw-500 d-block my-2">
                      {t("ForgetPassword")}
                    </Link>
                    <Link to="/signup" className="fw-500 d-block my-2">
                      {t("DontHaveacc")}
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
