import React from "react";
import ManagementJobsHeader from "./ManagementJobsHeader";
import LeftHandSide from "./LeftHandSide";
import { Container } from "react-bootstrap";

function ManagementJobsNotFilter() {
  return (
    <div>
      <ManagementJobsHeader />
      <Container>
        <div className="row py-2 m-auto">
          <div className="col-12 col-md-8 order-lg-1 order-md-1 order-2">
            <LeftHandSide />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ManagementJobsNotFilter;
