import React from "react";
import { Link } from "react-router-dom";
import btnStyles from "./styles.module.css";
import Loading from "../Loading";
import i18n from "../../i18n/i18n";

function Button({
  title,
  onClick,
  to,
  style,
  loading,
  iconSrc,
  leftIcon,
  rightIcon,
}) {
  return (
    <>
      {to ? (
        <Link
          to={to}
          onClick={loading ? null : onClick}
          className={`${btnStyles.btn} ${style} ${
            loading ? btnStyles.loading : ""
          }`}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              {iconSrc && (
                <img
                  src={iconSrc}
                  alt="icon"
                  className={
                    i18n.language === "ar"
                      ? btnStyles.icon_ar
                      : btnStyles.icon_en
                  }
                />
              )}
              {title}
            </>
          )}
        </Link>
      ) : (
        <button
          onClick={loading ? null : onClick}
          className={`${btnStyles.btn} ${style} ${
            loading ? btnStyles.loading : ""
          }`}
          disabled={loading}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              {iconSrc && (
                <img src={iconSrc} alt="icon" className={btnStyles.icon} />
              )}
              <span className="d-flex align-items-center gap-1 w-full">
                <span>
                  {" "}
                  {leftIcon && (
                    <img
                      src={leftIcon}
                      alt="Left Icon"
                      className={btnStyles.checkedIcon}
                    />
                  )}
                </span>
                <span className="text-center m-auto">{title}</span>
                <span>
                  {" "}
                  {rightIcon && (
                    <img
                      src={rightIcon}
                      alt="Left Icon"
                      className={btnStyles.checkedIcon}
                    />
                  )}
                </span>
              </span>
            </>
          )}
        </button>
      )}
    </>
  );
}

export default Button;
