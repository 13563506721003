import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
//Styles
import styles from "./styles.module.css";
import { InputField } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { getAllCities } from "../../../redux/slices/helperSlice";
//Data
const genderList = ["male", "female"];
function StepTwo({ onStepDataChange }) {
  const dispatch = useDispatch();
  const { companyInfo } = useSelector((state) => state.auth);
  const { countries, cities, allGendersData } = useSelector(
    (state) => state.helpers
  );
  //State
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [selectedCity, setSelectedCity] = useState("");
  const [cityCode, setCityCode] = useState();
  const [selectNationality, setSelectNationality] = useState("");
  const [nationalityID, setNationalityID] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [genderCode, setGenderCode] = useState("");
  //Refs
  const birth_yearRef = useRef(null);
  const birth_monthRef = useRef(null);
  const birth_dayRef = useRef(null);
  const handleGenderChange = (event) => {
    const selectGenderName = event.target.value;
    setGenderValue(selectGenderName);
    const selectedGender = allGendersData?.data?.find(
      (gend) => gend.default_name === selectGenderName
    );
    if (selectedGender) {
      const genderCode = selectedGender.code
      setGenderCode(genderCode)
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    setSelectedCountry(selectedCountryName);

    const selectedCountry = countries?.data?.find(
      (country) => country?.default_name === selectedCountryName
    );
    if (selectedCountry) {
      const countryCode = selectedCountry.code;
      setCountryCode(countryCode);
    }
  };

  const handleNationalityChange = (event) => {
    const selectedNationalityName = event.target.value;
    setSelectNationality(selectedNationalityName);

    const selectNationality = countries?.data?.find(
      (country) => country?.default_name === selectedNationalityName
    );
    if (selectNationality) {
      const countryCode = selectNationality.code;
      setNationalityID(countryCode);
    }
  };
  const handleCityChange = (event) => {
    const selectedCityName = event.target.value;
    setSelectedCity(selectedCityName);

    // Find the selected city from the `saudiCities` array
    const selectedCity = cities?.data?.find(
      (city) => city.default_name === selectedCityName
    );

    if (selectedCity) {
      const cityCode = selectedCity.code;
      setCityCode(cityCode); // Set the city ID based on the selected city
    }
  };

  useEffect(() => {
    const stepTwoData = {
      first_name: companyInfo?.first_name,
      last_name: companyInfo?.last_name,
      birth_year: birth_yearRef.current.value,
      birth_month: birth_monthRef.current.value,
      birth_day: birth_dayRef.current.value,
      gender: genderCode,
      nationality: nationalityID,
      country: countryCode,
      city: cityCode,
      phone: companyInfo?.mobile_number,
    };

    onStepDataChange(stepTwoData);
  }, [
    cityCode,
    countryCode,
    genderCode,
    nationalityID,
    onStepDataChange,
    birth_yearRef.current?.value,
    birth_monthRef.current?.value,
    birth_dayRef.current?.value,
    companyInfo?.first_name,
    companyInfo?.last_name,
    companyInfo?.mobile_number,
  ]);

  useEffect(() => {
    dispatch(getAllCities(countryCode));
  }, [dispatch, countryCode]);
  return (
    <main className="d-flex flex-column align-items-center">
      <div className="d-flex flex-column align-items-center mb-5">
        <h3 className={`${styles.form_page_title} mb-3`}>{t("GeneralInfo")}</h3>
        <p className="text-center">{t("GeneralInfoText")}</p>
      </div>

      {/*Start Form */}
      <div className={`${styles.form_content} w-100`}>
        <Container className="d-flex flex-column">
          {/*Start Forms */}
          <form>
            {/*============================= Section 1 ============================ */}
            <section
              className={` ${styles.background_forms} pb-5  mb-5 container`}
            >
              <h6 className="pb-4"> {t("YourPersonalInfo")}</h6>

              <div className="row mb-3 d-flex align-items-center">
                <label className="form-label">Birthdate</label>
                <div className="col-md-3">
                  <div className="mb-3">
                    <InputField
                      type="text"
                      className="form-control"
                      id="input3"
                      placeholder={t("Day")}
                      style={styles.text_component_style}
                      ref={birth_dayRef}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <InputField
                      type="text"
                      className="form-control"
                      id="input4"
                      placeholder={t("Month")}
                      style={styles.text_component_style}
                      ref={birth_monthRef}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <InputField
                      type="text"
                      className="form-control"
                      id="input5"
                      placeholder={t("Year")}
                      style={styles.text_component_style}
                      ref={birth_yearRef}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("Gender")}
                    placeholder={t("Gender")}
                    value={genderValue}
                    onChange={handleGenderChange}
                    isList={true}
                    list={allGendersData?.data?.map(
                      (gend) => gend.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("Nationality")}
                    placeholder={t("Select")}
                    value={selectNationality}
                    onChange={handleNationalityChange}
                    isList={true}
                    list={countries?.data?.map(
                      (country) => country.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
            </section>
            {/*============================= End of Section 1 ============================ */}
            {/*============================= Start of Section 2 ============================ */}
            <section
              className={`${styles.background_forms} py-5 my-5 container`}
            >
              <h6 className="pb-4"> {t("YourLocation")}</h6>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("Country")}
                    placeholder={t("ChooseAnOption")}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    isList={true}
                    list={countries?.data?.map(
                      (country) => country.default_name
                    )}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("City")}
                    placeholder={t("ChooseAnOption")}
                    value={selectedCity}
                    onChange={handleCityChange}
                    isList={true}
                    list={cities?.data.map((city) => city.default_name)}
                    fieldName="options"
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                </div>
              </div>
            </section>
            {/*============================= End of Section 2 ============================ */}
          </form>
        </Container>
      </div>
      {/*End Form */}
    </main>
  );
}

export default StepTwo;
