import React, { useState } from "react";
import { IconPlusTitle } from "../../../components";
import { location, King, CandidatesSkills } from "../../../assets";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { t } from "i18next";
import { getCandidateCVDetails } from "../../../redux/slices/jobsSlice";
// Configuration object for job types and their associated colors
const jobTypeColors = {
  Internship: "#e1d123",
  "Full Time": "#186fc9",
  Freelance: "#53b427",
  "Part Time": "#f1630d",
};

// Function to get border color based on job types
const getBorderColor = (types) => {
  if (types.includes("Internship")) return jobTypeColors["Internship"];
  if (types.includes("Full Time")) return jobTypeColors["Full Time"];
  if (types.includes("Freelance")) return jobTypeColors["Freelance"];
  if (types.includes("Part Time")) return jobTypeColors["Part Time"];
  if (types.includes("Student activity")) return jobTypeColors["Part Time"];
  return "#f1630d"; // Default color if no types match
};

// Function to get button classes based on job types
const getButtonClasses = (types) => {
  const buttonClasses = {
    Internship: styles.job_status_btn_Internship,
    "Full Time": styles.job_status_btn_fulltime,
    Freelance: styles.job_status_btn_freelance,
    "Free Lance / Project": styles.job_status_btn_freelance,
    "Shift based": styles.job_status_btn_freelance,
    "Part Time": styles.job_status_btn_parttime,
    "Student activity": styles.job_status_btn_parttime,
  };
  return types?.map((type) => buttonClasses[type] || "").join(" ");
};

function JobsAppLeftHandSide() {
  const locationState = useLocation()
  const { state } = locationState;
  const { get, jobCode } = state || {};
  console.log(get);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { usersApplicationsData } = useSelector((state) => state.jobs);
  console.log(usersApplicationsData?.data);
  const responsive =
    "d-flex flex-column flex-md-row justify-content-center align-items-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";
  const responsiveTwo =
    "d-flex flex-column  justify-content-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";

  // Next Page
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 5;

  // Ensure filteredJobs.data is defined and has a length
  const totalJobs = usersApplicationsData?.data?.length || 0;
  const totalPages = Math.max(1, Math.ceil(totalJobs / jobsPerPage));

  // Calculate jobs to display
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs =
    usersApplicationsData?.data?.slice(indexOfFirstJob, indexOfLastJob) || [];
  const handleNextPage = () => {
    window.scrollTo(0, 0);
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
  };
  //
  const getCandidateProfileHandler = (code) => {
    console.log(code)
    const profileCode = {
      code: code,
    };
    dispatch(getCandidateCVDetails(profileCode))
      .unwrap()
      .then(() => {
        navigate("/userProfile", { state: { from: "applicationsPage", jobCode: jobCode, userCode: code } });
      })
      .catch(() => { });
  };
  return (
    <div>
      {/* Show Jobs Section */}
      <div className="pb-5 container">
        {currentJobs?.map((job, index) => (
          <div
            key={index}
            className={`row 
              
              ${styles.job_container} `}
            style={{ borderLeftColor: getBorderColor("Full Time") }}
          >
            <div
              className={`row col-12 col-md-10 text-center text-md-start ${responsive}`}
            >
              {/*<div className="col-4 col-md-2">
                <img
                  src={"job.company.company_logo"}
                  alt={"Cand Pic"}
                  className="c-pointer"
                  onClick={() => getThisJobHandler("job")}
                />
              </div> */}

              <div
                className={`col-12 ${styles.job_info} ${responsiveTwo} align-items-center align-items-md-start`}
              >
                <p
                  className="py-1 c-pointer"
                  onClick={() => getCandidateProfileHandler(job?.code)}
                >
                  {job?.first_name}  {job?.last_name}
                </p>
                <div className="d-flex align-items-center gap-4 py-1">
                  <IconPlusTitle
                    IconSrc={King}
                    title={
                      job?.jobs
                        ? job?.jobs
                        : "Carpenter"
                    }
                    P_Style={`${styles.job_descrebtion}`}
                  />
                  <IconPlusTitle
                    IconSrc={location}
                    title={`${job?.country?.default_name ? job?.country?.default_name : "SAU"}, ${job?.city?.default_name ? job?.city?.default_name : "Madina"}`}
                    P_Style={`${styles.job_descrebtion}`}
                  />
                </div>
                {/*<IconPlusTitle
                  IconSrc={CandidatesSkills}
                  title={`${job?.jobs?.map((job)=>job).join(" - ")}`}
                  P_Style={`${styles.job_descrebtion}`}
                  customIconStyle={styles.customIconStyle}
                />*/}

              </div>
            </div>

            <div className="col-2 d-flex flex-column gap-2 align-items-center mt-4">
              {job?.job_types
                ? job?.job_types?.map((type, index) => (
                  <button
                    key={index}
                    disabled
                    className={`${getButtonClasses([[type.default_name]])}`}
                  >
                    {[type.default_name]}
                  </button>
                ))
                : ""}
            </div>
          </div>
        ))}
      </div>
      {/* Pages Number */}
      <div className="mb-5 d-flex align-items-center justify-content-around">
        <div
          className={`d-flex flex-row align-items-center gap-3 justify-content-center ${styles.number_parent}`}
        >
          {[...Array(totalPages).keys()].map((page) => (
            <p
              key={page}
              className={`c-pointer ${currentPage === page + 1 ? styles.active : ""
                }`}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </p>
          ))}
        </div>
        <div
          className={`d-flex justify-content-end c-pointer ${styles.number_parent}`}
        >
          <p onClick={handleNextPage}>{t("Next")}</p>
        </div>
      </div>
    </div>
  );
}

export default JobsAppLeftHandSide;
