import React, { useState } from "react";
import { BlueRightArrow, CompanyPhoto, GreenRightArrow } from "../../../assets/index";
//style
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getJobsDetails } from "../../../redux/slices/jobsSlice";
import { t } from "i18next";
function JobLeftProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobDetailsData } = useSelector((state) => state.jobs);
  const [expandedSections, setExpandedSections] = useState({});

  const expandParagraph = (text, maxLength, sectionKey) => {
    return expandedSections[sectionKey]
      ? text
      : `${text?.substring(0, maxLength)} ...`;
  };

  const toggleReadMore = (sectionKey) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey],
    }));
  };

  const goToJobDetailsHandler = (job) => {
    const jobCode = {
      job_code: job.code,
    };
    dispatch(getJobsDetails(jobCode))
      .unwrap()
      .then(() => {
        dispatch(getJobsDetails(jobCode));
        navigate("/jobProfile");
        window.scrollTo(0, 0);
      })
      .catch(() => {});
  };
  return (
    <div>
      <div
        className={`${styles.job_requirement} my-4 d-flex flex-column justify-content-start`}
      >
        <h5 className="mb-3">{t("JobRequirements")}</h5>
        <div
          className={`d-flex flex-column gap-4 ${styles.requirement_of_Job}`}
        >
          <div className="d-flex align-items-center gap-3">
            <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">
              {t("ExperienceNeeded")}
            </h6>
            <p className="col-5">
              {jobDetailsData?.experience_from} to{" "}
              {jobDetailsData?.experience_to} years
            </p>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="col-8 col-sm-4 col-md-6 col-lg-4 ">
              {t("TheComputer")}
            </h6>
            <p className="col-5">NA</p>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">
              {t("Education Level")}
            </h6>
            <p className="col-5">
              {jobDetailsData?.career_level?.default_name}
            </p>
          </div>
          <div className="d-flex align-items-center gap-3 row">
            <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">{t("Gender")}</h6>
            <p className="col-5">NA</p>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">
              {t("Microsoftoffice")}
            </h6>
            <p className="col-5">NA</p>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">{t("English")}</h6>
            <p className="col-5">NA</p>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">
              {t("RequiredAge")}
            </h6>
            <p className="col-5">NA</p>
          </div>
        </div>
        <div className={styles.skills_tools}>
          <h5 className="pb-3">{t("SkillsAndTools")}</h5>
          <div className="d-flex align-items-center gap-5 ms-5 flex-wrap">
            {jobDetailsData?.keywords?.map((key, index) => (
              <p key={index}>{key}</p>
            ))}
          </div>
        </div>
      </div>
      {/* Salary and type of work  - Job Features  - Job Details */}
      <div
        className={`my-4 ${styles.Salary_Features_Details} d-flex flex-column gap-4`}
      >
        <h5 className="mb-3">{t("Salaryandtypeofwork")}</h5>
        <div className="d-flex align-items-center gap-3">
          <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">
            {t("BasicSalary")}
          </h6>
          <p className="col-5">
            {jobDetailsData?.salary_hide
              ? "Hidden"
              : `${jobDetailsData?.salary_from} - ${jobDetailsData?.salary_to} ${jobDetailsData?.salary_currency?.default_name} / ${jobDetailsData?.salary_per?.default_name}`}
          </p>
        </div>
        <div className="d-flex align-items-center gap-3">
          <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">
            {t("Type of Job")}
          </h6>
          <p className="col-5">{jobDetailsData?.contract_type?.default_name}</p>
        </div>
      </div>
      <div
        className={`my-4 ${styles.Salary_Features_Details} d-flex flex-column gap-4`}
      >
        <h5 className="mb-3">{t("JobFeatures")}</h5>
        <div className="d-flex align-items-center gap-3">
          <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">
            {t("Feedingallowance")}
          </h6>
          <p className="col-5">NA</p>
        </div>
        <div className="d-flex align-items-center gap-3">
          <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">{t("Overtime")}</h6>
          <p className="col-5">NA</p>
        </div>
      </div>
      <div
        className={`my-4 ${styles.Salary_Features_Details} d-flex flex-column gap-4`}
      >
        <h5 className="mb-3">{t("JobDetails")}</h5>
        <div className="d-flex align-items-center gap-3">
          <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">{t("JobField")}</h6>
          <p className="col-5">{jobDetailsData?.category?.default_name}</p>
        </div>
        <div className="d-flex align-items-center gap-3">
          <h6 className="col-8 col-sm-4 col-md-6 col-lg-4">{t("JobMajor")}</h6>
          <p className="col-5">{jobDetailsData?.career_level?.default_name}</p>
        </div>
        <div className="d-flex align-items-start gap-3">
          <h6 className="col-5 col-sm-4 col-md-6 col-lg-4">Job Description</h6>
          <p className="col-8 text-center text-md-start">
            {expandParagraph(
              jobDetailsData?.job_description,
              100,
              "jobDescription"
            )}
            <Link
              className="align-items-center ms-2"
              onClick={() => toggleReadMore("jobDescription")}
            >
              {expandedSections["jobDescription"] ? "Less" : "More"}
            </Link>
          </p>
        </div>
        <div className="d-flex align-items-start gap-3">
          <h6 className="col-5 col-sm-4 col-md-6 col-lg-4">Job Requirements</h6>
          <p className="col-8 text-center text-md-start">
            {expandParagraph(
              jobDetailsData?.job_requirements,
              100,
              "jobRequirements"
            )}
            <Link
              className="align-items-center ms-2"
              onClick={() => toggleReadMore("jobRequirements")}
            >
              {expandedSections["jobRequirements"] ? "Less" : "More"}
            </Link>
          </p>
        </div>
      </div>
      {/*Similar Jobs */}
      <div
        className={`${styles.similar_jobs} flex-column flex-md-row align-items-center position-relative`}
      >
        <h5 className="mb-4">{t("SimilarJob")}</h5>
        <div className=" row d-flex  gap-3 gap-lg-0  align-items-center justify-content-between py-1 py-md-4">
          {jobDetailsData?.similer_jobs?.map((job,index) => (
            <div
              className={` ${styles.one_similar_job} col-12 col-lg-6 d-flex flex-column gap-3 gap-lg-2 flex-lg-row align-items-center justify-content-between py-1 py-md-4`}
              key={index}>
              <div className="order-2 order-lg-1 d-flex align-items-center flex-column align-items-lg-start">
                <h6 className="pb-2">{job?.company?.company_name}</h6>
                <h4 className="pb-2">
                  {job?.title}
                  <p className="pt-1">
                    - {job?.country?.default_name}, {job?.city?.default_name}
                  </p>
                </h4>
                <span className="pt-2">{job?.since}</span>
              </div>
              <div
                className="order-1 order-lg-2 c-pointer"
                onClick={() => goToJobDetailsHandler(job)}
              >
                <img
                  src={
                    job?.company?.company_logo
                      ? job?.company?.company_logo
                      : CompanyPhoto
                  }
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>

        <div className="pt-5 pt-md-0 my-3 my-md-0">
          <div
            className={`${styles.search_opportunities} d-flex align-items-center gap-0 `}
          >
            <p>
              <Link to="/managementJobs">Search other opportunities</Link>
            </p>
            <img
              src={BlueRightArrow}
              alt=""
              style={{ width: "20px", paddingTop: "2px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobLeftProfile;
