import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { King, location, photoshop } from "../../../assets";
import { Button, IconPlusTitle } from "../../../components";
//style
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
function JobRightProfile() {
  const text =
    "Learning needs the right environment to flourish, And because we're experts in education, Our Courses designed to help Learning needs the right environment to flourish";

  const [isExpanded, setIsExpanded] = useState(false);
  const expandParagraph = (text, maxLength) => {
    return isExpanded ? text : `${text.substring(0, maxLength)} ...`;
  };
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  //
  return (
    <div>
      <Container>
        {/*About Egyptian Culture Center*/}
        <div
          className={`${styles.egyptian_culture} mt-4 d-flex flex-column align-items-start gap-2`}
        >
          <h5>About Egyptian Culture Center</h5>
          <span>Education</span>
          <span>Cairo, Egypt - 101-500 employees</span>
          <div>
            {expandParagraph(text, 100)}
            <Link
              className="align-items-center ms-2"
              onClick={() => toggleReadMore()}
            >
              {isExpanded ? "Less" : "More"}
            </Link>
          </div>
          <Link to="/#">Browse all Vacancies at Egyptian Cultural Center</Link>
        </div>
        {/*Find Related jobs*/}
        <div
          className={`${styles.related_job} mt-4 d-flex flex-column align-items-start gap-2`}
        >
          <h5>Find Related Jobs</h5>
          <div className="mt-2 d-flex flex-column gap-2">
            <Link to="/#">All Vacancies at Egyptian Cultural Center</Link>
            <Link to="/#">General Manager jobs in Cairo</Link>
            <Link to="/#">General Manager jobs in Egypt</Link>
            <Link to="/#">Operations/Management jobs in Egypt</Link>
            <Link to="/#">Education/Teaching jobs in Egypt</Link>
            <Link to="/#">jobs in Egypt</Link>
          </div>
        </div>
        {/*Similar Function*/}
        <div className={`${styles.similar_job} container mt-4 d-flex flex-column`}>
          <h5 className="pb-3  text-nowrap">Similar Functions</h5>
          <div
            className={`row d-flex flex-column flex-xl-row align-items-center justify-content-center py-4 ${styles.similar_job_content}`}
          >
            <div className={`col-6 col-lg-3 mb-3 mb-lg-0`}>
              <img src={photoshop} alt="photoshop"/>
            </div>
            <div className="col-12 col-lg-9">
              <h6 className="text-center">
                Senior Health and Nutrition Advisor
              </h6>
              <div className="d-flex flex-sm-row flex-md-column flex-lg-row justify-content-center justify-content-xlg-start align-items-center gap-3">
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={location}
                  title={"KSA"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
              </div>
            </div>
          </div>
          <div
            className={`row d-flex flex-column flex-xl-row align-items-center justify-content-center py-4 ${styles.similar_job_content}`}
          >
            <div className={`col-6 col-lg-3 mb-3 mb-lg-0`}>
              <img src={photoshop} alt="photoshop"/>
            </div>
            <div className="col-12 col-lg-9">
              <h6 className="text-center">
                Senior Health and Nutrition Advisor
              </h6>
              <div className="d-flex flex-sm-row flex-md-column flex-lg-row justify-content-center justify-content-xlg-start align-items-center gap-3">
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={location}
                  title={"KSA"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
              </div>
            </div>
          </div>
          <div
            className={`row d-flex flex-column flex-xl-row align-items-center justify-content-center py-4 ${styles.similar_job_content}`}
          >
            <div className={`col-6 col-lg-3 mb-3 mb-lg-0`}>
              <img src={photoshop} alt="photoshop"/>
            </div>
            <div className="col-12 col-lg-9">
              <h6 className="text-center">
                Senior Health and Nutrition Advisor
              </h6>
              <div className="d-flex flex-sm-row flex-md-column flex-lg-row justify-content-center justify-content-xlg-start align-items-center gap-3">
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={location}
                  title={"KSA"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
              </div>
            </div>
          </div>
          <div
            className={`row d-flex flex-column flex-xl-row align-items-center justify-content-center py-4 ${styles.similar_job_content}`}
          >
            <div className={`col-6 col-lg-3 mb-3 mb-lg-0`}>
              <img src={photoshop} alt="photoshop"/>
            </div>
            <div className="col-12 col-lg-9">
              <h6 className="text-center">
                Senior Health and Nutrition Advisor
              </h6>
              <div className="d-flex flex-sm-row flex-md-column flex-lg-row justify-content-center justify-content-xlg-start align-items-center gap-3">
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={location}
                  title={"KSA"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
                <IconPlusTitle
                  IconSrc={King}
                  title={"Telimed"}
                  P_Style={styles.icon_title}
                  customIconStyle={styles.icon_title_style}
                />
              </div>
            </div>
          </div>
          <div className="m-auto">
            <Button
              title={"See all financial management jobs"}
              to={""}
              style={"mt-4 px-4 px-lg-5 py-3 shadow"}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default JobRightProfile;
