import React, { useState } from "react";
import styles from "./styles.module.css";
import { IconPlusTitle, Loading } from "../../components";
import { Search, Tips } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserCVs } from "../../redux/slices/jobsSlice";
function SearchCV() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.jobs);
  const [searchValue, setSearchValue] = useState();
  const submitSearchHandler = () => {
    const jobTitle = {
      job_title: searchValue,
    };
    dispatch(getAllUserCVs(jobTitle))
      .unwrap()
      .then(() => {
        navigate("/completeSearchCV");
      })
      .catch(() => {});
  };
  return (
    <div className={styles.search_cv_page}>
      <div className={`${styles.search_cv_header}`}>
        <div className={`${styles.search_cv_content} d-flex flex-column`}>
          <div className="mb-3 d-flex flex-column gap-2 gap-md-0 flex-md-row align-items-center justify-content-md-between">
            <h5>{t("SearchCandidatesCVs")}</h5>
            <IconPlusTitle
              IconSrc={Tips}
              title={t("TipsForBetterSearch")}
              P_Style={styles.tips_search_style}
            />
          </div>

          <div className="text-center">{error && <p className="text-danger">{error}</p>}</div>
          <div className={`${styles.search_bar_container}`}>
            <input
              type="text"
              placeholder={t("SeachBarTitle")}
              onChange={(e) => setSearchValue(e.target.value)}
            ></input>
            <img
              src={Search}
              className={
                i18n.language === "en"
                  ? styles.search_icon_en
                  : styles.search_icon_ar
              }
              alt="search"
            />
            <div
              className={
                i18n.language === "en"
                  ? styles.search_btn_en
                  : styles.search_btn_ar
              }
            >
              <Link onClick={submitSearchHandler}>
                <button>{loading ? <Loading /> : t("Search")}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchCV;
