import axios from "axios";

// live
// const BASE_URL = '';
// staging
const BASE_URL = "https://camp-coding.site/shogl/API/";

export const APIS = {
  // =================== Helpers =================== //
  //Location
  allCountries: "Helpers/Locations/Countries", // Exist
  allCities: "Helpers/Locations", // Done
  loginCompany:"COM/Auth/Login",
  //Company
  getCareerLevels: "Helpers/Companies/Career-Levels", // Exist
  getJobTypes: "Helpers/Companies/Job-Types", // Exist
  getWorkSpaceSetting: "Helpers/Companies/Workspace-Setting",
  getSalaryPer: "Helpers/Companies/Minimum-Net-Salary-Per",
  getAllCurrencies: "Helpers/Locations/Currencies",
  getAllGenders: "Helpers/Companies/Genders", // Exist
  getExperienceYears: "Helpers/Companies/Experience-Years", // Exist
  getEducationalLevels: "Helpers/Companies/Educational-Levels", // Exist
  getFieldsOfStudy: "Helpers/Companies/Fields-Of-Study", // Exist
  getUniversities: "Helpers/Companies/Universities", // Exist
  getGrades: "Helpers/Companies/Grades", // Exist
  getDegrees: "Helpers/Jobs/Degrees", // Will Be Added
  getAllSkills: "Helpers/Companies/Skills", // Exist
  getAllLanguages: "Helpers/Companies/Languages", // Exist
  getLanguagesLevels: "Helpers/Companies/Languages-Levels", // Exist
  getExperienceTypes: "Helpers/Jobs/ExperienceTypes", // Will Be Added
  getEmployeeRange: "Helpers/Companies/Employee-Range",
  //Jobs
  getPostTypes: "Helpers/Jobs/Post-Types",
  getJobCategory: "Helpers/Jobs/Categories", // Exist // high param
  getJobTypesForJobPost: "Helpers/Jobs/Job-Types",
  getJobContractTypes: "Helpers/Jobs/Contract-Types",
  getJobCareerLevels: "Helpers/Jobs/Career-Levels",
  getJobSalaryPer: "Helpers/Jobs/Salary-Pers",
  sendEmailNotifPers: "Helpers/Jobs/Send-Emails-Notification-Pers",

  // =================== auth Candidate =================== //
  //==== Forget Password ====
  forgetPasswordPhone: "COM/Auth/Forgot-Password/Phone",
  forgetPasswordOTP: "COM/Auth/Forgot-Password/Otp",
  forgetPasswordNewPass: "COM/Auth/Forgot-Password/New-Password",
  //==== Register ====
  // signUpOneCandidate: "LOW/Auth/Registration/FirstHighRegistration",
  signUpOne: "LOW/Auth/Registration/FirstHighRegistration",
  signUpTwo: "LOW/Auth/Registration/Two",
  signUpThree: "LOW/Auth/Registration/Three",
  signUpFour: "LOW/Auth/Registration/Four",
  resendOTP: "LOW/Auth/Registration/Two-Resend-Otp",
  HighRegistrationTwo: "LOW/Auth/Registration/HighRegistration",
  //==== Login ====
  loginOne: "LOW/Auth/Registration/HighLogin",
  // loginTwo: "LOW/Auth/Login/Two",

  //==== jobs ====
  editCandidateProfile: "LOW/My/Profile-Header",
  getCategoryWithSearch: "LOW/Jobs/Categorys",
  getAllHelperJobs: "LOW/Jobs/All-Helper",
  getAllWithFilter: "LOW/Jobs/All",
  getJobsDetails: "LOW/Jobs/Details",
  saveJob: "LOW/Jobs/Save",
  unSaveJob: "LOW/Jobs/Un-Save",
  allSavedJob: "LOW/Jobs/All-Saved",
  ViewJobCounter: "LOW/Jobs/View",
  ApplyJob: "LOW/Jobs/Apply",
  UnapplyJob: "LOW/Jobs/Un-Apply",
  getAllApplyJobs: "LOW/Jobs/All-Applied",
  // ================= Logout Candidate==================//
  logoutCandidate: "LOW/Auth/Logout",
  // =================== auth Company =================== //
  LoginCompany: "COM/Auth/Login",
  logoutCompany: "COM/Auth/Logout",
  signUpOneCorporate: "COM/Auth/Registration/Form",
  signUpTwoCorporate: "COM/Auth/Registration/Career-Interests",
  signUpThreeCorporate: "COM/Auth/Registration/General-Info",
  signUpFourCorporate: "COM/Auth/Registration/Professional-Info",
  // careerInterestsHelper: "COM/Auth/Registration/Career-Interests-Helpers",
  // professionalInfoHelpers: "COM/Auth/Registration/Professional-Info-Helpers",
  postJobHelper: "COM/Jobs/Post-New-Helper",
  allJobCategoryCOM: "COM/Jobs/Categorys",
  postNewJob: "COM/Jobs/Add",
  getSalaryCurrency: "Helpers/Currency/All",
  getAllMyJobPosts: "COM/Jobs/My",
  deleteJobPost: "COM/Jobs/Delete",
  editJobPost: "COM/Jobs/Edit",
  getAllUserApplications: "COM/Jobs/All-Applied-Users", // Users Applications
  getAllUserCVs: "COM/Users/L/All", // Users CVs
  getCandidateCVDetails: "COM/Users/L/Details",
  getCompanyEmployeeRange: "COM/My/Edit-Profile-Helper",
  getCompanyProfile: "COM/My/Profile",
  editCompanyProfile: "COM/My/Edit-Profile",
  getIndustriesSearch: "COM/My/Industries-Search",
  getSpecialtiesSearch: "COM/My/Specialties-Search",

  // =================== My =================== //
  getMyProfile: "LOW/My/Profile",
  myAboutMe: "LOW/My/About",
  myProfileOverview: "LOW/My/Profile-Overview",
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    let err = error?.response?.data || error?.message || "Bad Request";
    console.log("Request Error ::", err);
    return Promise.reject(error);
  }
);

const Axios = async ({
  method,
  path,
  data,
  params,
  header = {},
  pathParams = "",
  isFormData = false,
  accessToken = "",
}) => {
  const access = localStorage.getItem("access_token");
  console.log("accessToken", access)
  const authHeder = accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : access
    ? {
        Authorization: `Bearer ${access}`,
      }
    : {};

  const response = await axiosInstance.request({
    method: method,
    url: `${path}${pathParams}`,
    data: data,
    params: params,
    headers: {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      Accept: "application/json",
      ...header,
      ...authHeder,
    },
  });
  return response;
};

export default Axios;
