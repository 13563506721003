import React, { useState } from "react";
import { Button, IconPlusTitle } from "../../../components";
import {
  location,
  King,
  Dollar,
  AddIcon,
  CompanyPhoto,
  BlackExit,
  Search,
  BlueTips,
} from "../../../assets";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllWithFilter,
  getJobsDetails,
} from "../../../redux/slices/jobsSlice";
import { t } from "i18next";
import i18n from "../../../i18n/i18n";
// Configuration object for job types and their associated colors
const jobTypeColors = {
  Internship: "#e1d123",
  "Full Time": "#186fc9",
  Freelance: "#53b427",
  "Part Time": "#f1630d",
};

// Function to get border color based on job types
const getBorderColor = (types) => {
  if (types?.includes("Internship")) return jobTypeColors["Internship"];
  if (types?.includes("Full Time")) return jobTypeColors["Full Time"];
  if (types?.includes("Freelance")) return jobTypeColors["Freelance"];
  if (types?.includes("Part Time")) return jobTypeColors["Part Time"];
  if (types?.includes("Student activity")) return jobTypeColors["Part Time"];
  return "#f1630d"; // Default color if no types match
};

// Function to get button classes based on job types
const getButtonClasses = (types) => {
  const buttonClasses = {
    Internship: styles.job_status_btn_Internship,
    "Full Time": styles.job_status_btn_fulltime,
    Freelance: styles.job_status_btn_freelance,
    "Free Lance / Project": styles.job_status_btn_freelance,
    "Shift based": styles.job_status_btn_freelance,
    "Part Time": styles.job_status_btn_parttime,
    "Student activity": styles.job_status_btn_parttime,
  };
  return types?.map((type) => buttonClasses[type] || "").join(" ");
};

function LeftHandSide() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const { filteredJobs } = useSelector((state) => state.jobs);

  const responsive =
    "d-flex flex-column flex-md-row justify-content-center align-items-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";
  const responsiveTwo =
    "d-flex flex-column  justify-content-center d-sm-flex d-sm-flex-column d-sm-justify-content-center d-sm-align-items-center";

  const submitSearchHandler = () => {
    const jobTitle = {
      title: searchValue,
    };
    dispatch(getAllWithFilter(jobTitle))
      .unwrap()
      .then(() => { navigate("/managementJobs", { state: { title: jobTitle.title } }) })
      .catch(() => { });
  };

  const getThisJobHandler = (job) => {
    const jobCode = {
      job_code: job.code,
      is_high_job: 1
    };
    dispatch(getJobsDetails(jobCode))
      .unwrap()
      .then(() => {
        navigate("/jobProfile");
      })
      .catch(() => { });

  };
  // Next Page
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 5;

  // Ensure filteredJobs.data is defined and has a length
  const totalJobs = filteredJobs?.data?.length || 0;
  const totalPages = Math.max(1, Math.ceil(totalJobs / jobsPerPage));

  // Calculate jobs to display
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs =
    filteredJobs?.data?.slice(indexOfFirstJob, indexOfLastJob) || [];

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePageChange = (page) => setCurrentPage(page);
  return (
    <div>
      {/* Show Jobs Section */}
      <div className={`${styles.search_header} mb-3`}>
        <div className={`${styles.search_cv_content} d-flex flex-column`}>
          <div className="d-flex flex-column gap-2 gap-md-0 flex-md-row align-items-center justify-content-md-between">
            <h5>Search For a Job</h5>
            <Link to="/home">
              <IconPlusTitle
                IconSrc={BlueTips}
                title={t("TipsForBetterSearch")}
                P_Style={styles.tips_search_style}
              />
            </Link>
          </div>
          <div className={`${styles.search_bar_container}`}>
            <input
              type="text"
              placeholder={"Search by Job Title"}
              onChange={(e) => setSearchValue(e.target.value)}
            ></input>
            <img
              src={Search}
              className={
                i18n.language === "en"
                  ? styles.search_icon_en
                  : styles.search_icon_ar
              }
              alt="Search"
            />
            <div
              className={
                i18n.language === "en"
                  ? styles.search_btn_en
                  : styles.search_btn_ar
              }
              onClick={submitSearchHandler}
            >
              <button>{t("Search")}</button>
            </div>
          </div>
          {/*<div className="ms-4 d-flex flex-column flex-md-row align-items-center gap-2">
          <button
            className={`d-flex gap-1 align-items-center ${styles.recommendation_search_btn}`}
          >
            <p className="d-flex gap-1 align-items-center">
              Egypt<span>({t("Country")})</span>
            </p>
            <img src={BlackExit} alt="Exit" />
          </button>
          <button
            className={`d-flex gap-1 align-items-center ${styles.recommendation_search_btn}`}
          >
            <p className="d-flex gap-1 align-items-center">
              Alex<span>(City)</span>
            </p>
            <img src={BlackExit} alt="Exit" />
          </button>
        </div> */}

          <div className={styles.line}></div>
          <div
            className={`d-flex flex-column gap-2 gap-md-0 flex-md-row align-items-center justify-content-md-between ${styles.show_result}`}
          >
            <p>
              Showing <span>({filteredJobs?.data?.length})</span> results
            </p>
            <Button
              title={"+ Save this search"}
              style={styles.save_search_btn}
            />
          </div>
        </div>
      </div>
      <div className="pb-5 container">
        {currentJobs?.map((job, index) => (
          <div
            key={index}
            className={`row 
              ${responsive}
              ${styles.job_container} ${job.active ? styles.active : ""}`}
            style={{ borderLeftColor: getBorderColor(job?.job_types?.en) }}
          >
            <div
              className={`row col-12 col-md-10 text-center text-md-start ${responsive}`}
            >
              <div className="col-4 col-md-2 c-pointer">
                <img
                  src={
                    job?.company?.company_logo
                      ? job?.company?.company_logo
                      : CompanyPhoto
                  }
                  alt={job?.company?.company_name}
                  className="c-pointer"
                  onClick={() => getThisJobHandler(job)}
                />
              </div>
              <div
                className={`col-10 ${styles.job_info} ${responsiveTwo} align-items-center align-items-md-start`}
              >
                <p
                  className="py-1 c-pointer"
                  onClick={() => getThisJobHandler(job)}
                >
                  {job?.title}
                </p>
                <div className="d-flex align-items-center gap-4 py-1">
                  <IconPlusTitle
                    IconSrc={King}
                    title={
                      job?.company?.company_name
                        ? job?.company?.company_name
                        : "Raya Company"
                    }
                    P_Style={`${styles.job_descrebtion}`}
                  />
                  <IconPlusTitle
                    IconSrc={location}
                    title={`${job?.country.default_name}, ${job?.city.default_name}`}
                    P_Style={`${styles.job_descrebtion}`}
                  />
                </div>
                <IconPlusTitle
                  IconSrc={Dollar}
                  title={`${job?.experience_from} - ${job?.experience_to} 
                  Yrs of Exp · ${job?.career_level?.default_name
                      ? job?.career_level?.default_name
                      : "Internship"
                    }. ${job?.salary_from}-${job?.salary_to}
                   ${job?.salary_currency?.default_name} ${job?.salary_per?.default_name
                      ? job?.salary_per?.default_name
                      : "Daily"
                    }`}
                  P_Style={`${styles.job_descrebtion}`}
                  customIconStyle={styles.customIconStyle}
                />
              </div>
            </div>
            <div className="col-2 d-flex flex-column gap-2 align-items-center mt-4">
              {job?.job_types?.en?.map((type, idx) => (
                <button
                  key={idx}
                  disabled
                  className={`${getButtonClasses([type])}`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* Pages Number */}
      <div className="mb-5 d-flex align-items-center justify-content-around">
        <div
          className={`d-flex flex-row align-items-center gap-3 justify-content-center ${styles.number_parent}`}
        >
          {[...Array(totalPages).keys()]?.map((page) => (
            <p
              key={page}
              className={`c-pointer ${currentPage === page + 1 ? styles.active : ""
                }`}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </p>
          ))}
        </div>
        <div
          className={`d-flex justify-content-end c-pointer ${styles.number_parent}`}
        >
          <p onClick={handleNextPage}>{t("Next")}</p>
        </div>
      </div>
      {/* Questionnaire */}
      <div className={`${styles.questionair_parent} d-flex flex-column`}>
        <div className="d-flex justify-content-between align-items-center p-4">
          <p>What are the top 10 popular jobs in Saudi Arabia Now?</p>
          <img alt="" src={AddIcon} />
        </div>
        <div className="d-flex justify-content-between align-items-center p-4">
          <p>Saudi Arabia’s Top 5 Companies Hiring in Saudi Arabia Now</p>
          <img alt="" src={AddIcon} />
        </div>
        <div className="d-flex justify-content-between align-items-center p-4">
          <p>What are the top 5 cities with open jobs in Saudi Arabia?</p>
          <img alt="" src={AddIcon} />
        </div>
      </div>
    </div>
  );
}

export default LeftHandSide;
