import React from "react";
import { Container } from "react-bootstrap";
//images
import { BlueDoc, RayaComp , Globaly , ShareLink } from "../../../assets";
//styles
import styles from "./styles.module.css";
const {
  Company_Profile,
  profile_photo,
  company_profile_info,
  share_link_company,
} = styles;
//
function CompanyProfileHeader() {
  return (
    <div className={`${Company_Profile}`}>
      <Container className="row">
        <div className="col-12 col-md-9 row d-flex align-items-center">
          {/*Company Profile Image */}
          <div
            className={`col-12 col-md-3 pb-3 me-4 me-lg-0 d-flex justify-content-center`}
          >
            <img
              className={`${profile_photo} ms-3 ms-md-5 mt-1`}
              src={RayaComp}
              alt={`RayaCompany`}
            />
          </div>
          {/*Company Profile Info */}
          <div
            className={`col-12 col-md-8 d-flex flex-column align-items-center align-items-md-start mt-3 mt-md-0 ms-3 ms-lg-0 gap-2 ${company_profile_info}`}
          >
            <div className="d-flex align-items-center pb-1">
              <p className="me-2 text-nowrap ">Raya Distribution</p>
              <img src={BlueDoc} alt="Company URL" style={{ width: "16px" }} />
            </div>
            <div className="text-center text-md-start">
              <span>
                Consumer Electronics . Retail . Cairo, Egypt . 501-1000
                employees
              </span>
            </div>
            <div className="d-flex align-items-center">
              <img src={Globaly} alt="Company URL" style={{ width: "16px" }} />
              <a className="ms-2" href="/#">
                www.Rayacorp.com
              </a>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-md-3 ms-0 ms-md-4 pt-5 ${share_link_company}`}
        >
          <a
            href="/#"
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={ShareLink}
              alt="shareLink"
              style={{ width: "16px" }}
              className="me-3"
            />
            <p>Share Company Page</p>
          </a>
        </div>
      </Container>
    </div>
  );
}

export default CompanyProfileHeader;
