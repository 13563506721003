import React from "react";
import styles from "./styles.module.css";
import { t } from "i18next";
import { Button } from "../../components";
function ChooseJobPost() {
  return (
    <main className={`${styles.register_steps} py-5`}>
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-column align-items-center mb-5">
          <h3 className={`${styles.form_page_title} mb-3`}>
            {t("PostNewJob")}
          </h3>
          <p className="text-center">{t("CreateAJobPost")}</p>
        </div>
        <div className="d-flex align-items-center gap-5 justify-content-center">
        <Button title={"High"} to={"/jobPost"} style={`${styles.choosing_btn}`}/>
        <Button title={"Low"} to={"/jobLowPost"} style={`${styles.choosing_btn} ${styles.low_btn}`}/>
        </div>
      </div>
    </main>
  );
}

export default ChooseJobPost;
