import React from "react";
import { Container } from "react-bootstrap";
import { DownArrow, location } from "../../../assets";
import { Button } from "../../../components/index";
//======================== Style ==========================
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
function HomeHeader() {
  return (
    <div className={styles.header}>
      <div
        className={` d-flex flex-column container align-items-center align-items-lg-start ${styles.header_container}`}
      >
        <div className="d-flex flex-column mb-5 text-center text-lg-start">
          <h3>Find Job</h3>
          <p>Hire Experts or be hired in sales & marketing</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="d-flex flex-column flex-lg-row col-4 col-lg-12 align-items-start align-items-lg-center gap-5 pb-4 mt-4">
              <span>What job are you looking for?</span>
              <span className="mx-0 mx-lg-5">Where?</span>
              <span className={`ms-0 ms-lg-5 ${styles.cat_span}`}>Categories</span>
            </div>
            {/*Search Bar */}
            <div
              className={`${styles.search_bar} col-8 col-lg-12 d-flex flex-column flex-lg-row align-items-center gap-5 pb-4 shadow`}
            >
              <input type="text" placeholder="Job Title, Skill, Industry" className="text-center"/>
              <input
                type="text"
                placeholder="City, State, Or Zip"
                className="ms-0 ms-lg-3 text-center"
              />
              <div className="d-flex align-items-center">
                <img src={location} alt="location icon"/>
                <input
                  type="text"
                  placeholder="All Categories"
                  className="ms-0 ms-lg-4 text-center text-lg-start"
                />
              </div>
              <div
                className={`d-flex align-items-center gap-3 gap-lg-5 ${styles.search_bar_btn}`}
              >
                <img src={DownArrow} alt="arrow icon"/>
                <Button to={"managementJobs"} title={"Search"} style={`${styles.find_job_btn}`} />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center pt-4 m-auto m-lg-0">
          <span>Need more search options?</span>
          <Link to="/#">Advanced Search</Link>
        </div>
      </div>
    </div>
  );
}

export default HomeHeader;
