import React from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { t } from "i18next";
function ManagementJobsHeader() {
  const { allSavedJobData } = useSelector((state) => state.jobs);

  return (
    <div
      className={`${styles.page_header} d-flex align-items-center gap-4 mt-1`}
    >
      <p>Senior Management Jobs in Saudi Arabia</p>
      <span>
        {allSavedJobData?.data?.length} {t("JobsFound")}
      </span>
    </div>
  );
}

export default ManagementJobsHeader;
