import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { t } from "i18next";
import { useSelector } from "react-redux";
import {
  AddIcon,
  rectangleCheckBox,
  rectangleCheckBoxVector,
} from "../../../assets";
import { InputField } from "../../../components";
import i18n from "../../../i18n/i18n";

function StepFive({ onStepDataChange, step }) {
  const {
    careerLevelsData,
    allCurrenciesData,
    jobTypesData,
    workSpaceSettingData,
    jobSalaryPerData,
    jobCategoryData,
  } = useSelector((state) => state.helpers);
  const [currentCareerActive, setCurrentCareerActive] = useState("");
  const [typeOfJobActive, setTypeOfJobActive] = useState([]);
  const [typeOfJobID, setTypeOfJobID] = useState([]);
  const [workSpaceActive, setWorkSpaceActive] = useState([]);
  const [workSpaceID, setWorkSpaceID] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [currSalaryChange, setCurrSalaryChange] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    postType: { value: t("Job"), code: null },
    category: { value: "", code: null },
    country: { value: "", code: null },
    city: { value: "", code: null },
    currency: { value: "", code: null },
    currencyPer: { value: "", code: null },
    title: "",
    minExperience: null,
    maxExperience: null,
    minSalary: null,
    maxSalary: null,
    careerLevel: { value: "", code: null },
    numberOfVacancy: null,
    description: null,
    requirements: null,
    jobTybe: { value: [], code: [] },
    contractType: { value: null, code: null },
    keywords: [],
    keepCompanyConfidintial: "",
    sendEmailsNotification: "",
    sendEmailsNotificationPer: { value: null, code: null },
  });

  const [isFirstToggleChecked, setIsFirstToggleChecked] = useState(true);
  const [isSecondToggleChecked, setIsSecondToggleChecked] = useState(true);

  // ============== Fun & Logic & Integration ==============
  const onFirstToggleHandler = () => {
    setIsFirstToggleChecked(!isFirstToggleChecked);
  };

  const onSecondToggleHandler = () => {
    setIsSecondToggleChecked(!isSecondToggleChecked);
  };

  const checkBoxHandler = isChecked
    ? rectangleCheckBoxVector
    : rectangleCheckBox;

  const currentCareerHandler = (e, el) => {
    e.preventDefault();
    setCurrentCareerActive(el.code);
    // console.log(el?.default_name);
    onStepDataChange({ ...step, career_level: el.code });
  };

  const typeOfJobHandler = (jobType) => {
    setTypeOfJobActive((prev) => {
      if (prev.includes(jobType)) {
        // Remove from active if already selected
        return prev.filter((type) => type !== jobType);
      } else {
        // Add to active if not selected
        return [...prev, jobType];
      }
    });
    setTypeOfJobID((prev) => {
      if (prev.some((code) => code === jobType?.code)) {
        // Remove from active if already selected
        return prev.filter((code) => code !== jobType?.code);
      } else {
        // Add to active if not selected
        return [...prev, jobType?.code];
      }
    });
  };

  useEffect(() => {
    onStepDataChange({
      ...step,
      jobs: typeOfJobID,
    });
  }, [typeOfJobID])

  const handleInputChange = (field) => (e) => {
    setJobDetails((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
    onStepDataChange({
      ...step,
      [field]: e.target.value,
    });
  };

  const handleListsChange = (event, field, data) => {
    const selectedName = event.target.value;

    // Update the state for the field
    setJobDetails((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: selectedName,
        code: null, // Set the ID to null initially
      },
    }));

    const selectedItem = data?.find(
      (item) => item?.default_name === selectedName
    );

    if (selectedItem) {
      const selectedId =
        selectedItem.code ||
        selectedItem.currency_id ||
        selectedItem.category_id;
      // Now update the ID for the field
      setJobDetails((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          value: selectedName,
          code: selectedId,
        },
      }));
    }
  };

  const workSpaceHandler = (jobType) => {
    setWorkSpaceActive((prev) => {
      if (prev.includes(jobType)) {
        // Remove from active if already selected
        return prev.filter((type) => type !== jobType);
      } else {
        // Add to active if not selected
        return [...prev, jobType];
      }
    });

    setWorkSpaceID((prev) => {
      if (prev.some((code) => code === jobType.code)) {
        // Remove from active if already selected
        return prev.filter((code) => code !== jobType.code);
      } else {
        // Add to active if not selected
        return [...prev, jobType.code];
      }
    });
  };

  useEffect(() => {
    onStepDataChange({
      ...step,
      work_setting: workSpaceID,
    });
  }, [workSpaceID]);


  return (
    <main
      className={` flex-column d-flex justify-content-center position-relative ${styles.page_container}`}
    >
      <div
        className={`d-flex flex-column align-items-center py-4 mt-2 ${styles.page_header}`}
      >
        <p>{t("MainStep")}</p>
        <p>
          Tell Us About Your <span>Career Interests</span>
        </p>
      </div>
      <form>
        {/*============================= Start Section 1 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className="mb-4">
            <h6>{t("WhatIsYourCurrentCareerLevel")}</h6>
          </div>
          <div className={`row d-flex justify-content-between container`}>
            {careerLevelsData?.data?.map((el) => (
              <button
                onClick={(e) => currentCareerHandler(e, el)}
                key={el.code}
                className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${styles.current_level_result
                  } ${currentCareerActive === el.code ? styles.active : ""}`}
              >
                {el.default_name}
              </button>
            ))}
          </div>
        </section>
        {/*============================= End Section 1 ============================ */}
        {/*============================= Start Section 2 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center">
            <h6>{t("WhatTypeOfJobAreYouOpenTo")}</h6>
            <span>{t("YouCanChooseMoreThanOneJobType")}</span>
          </div>

          {/* Display Selected Job Types */}
          <div className={`row mb-3`}>
            {typeOfJobActive.map((el) => (
              <button
                key={el.code}
                className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow pe-4 ${styles.btn_hover} ${styles.job_type} ${styles.active}`}
                onClick={() => typeOfJobHandler(el)}
              >
                {el.default_name}
                <img src={AddIcon} alt="Remove Icon" />
              </button>
            ))}
          </div>
          {typeOfJobActive.length !== 0 && <hr />}
          {/* Display Available Job Types */}

          <div className={`row`}>
            {jobTypesData?.data
              .filter((el) => !typeOfJobActive.includes(el)) // Show only unselected job types
              .map((el) => (
                <button
                  key={el.code}
                  className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow pe-4 ${styles.btn_hover} ${styles.job_type}`}
                  onClick={() => typeOfJobHandler(el)}
                >
                  {el.default_name}
                  <img src={AddIcon} alt="Add Icon" />
                </button>
              ))}
          </div>
        </section>
        {/*============================= End Section 2 ============================ */}
        {/*============================= Start Section 3 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center ">
            <h6>{t("WhatYourPreferredWorkspaceSettings")}</h6>
            <span>{t("YourCanChooseMoreThanWorkspaceSetting")}</span>
          </div>
          <div className={`row`}>
            {/* Display Selected Job Types */}
            <div className={`row mb-3`}>
              {workSpaceActive.map((el) => (
                <button
                  key={el.code}
                  className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow ${styles.btn_hover}  ${styles.workspace_setting}`}
                  onClick={() => workSpaceHandler(el)}
                >
                  {el.default_name}
                  <img src={AddIcon} alt="Add Icon" />
                </button>
              ))}
            </div>
            {workSpaceActive.length !== 0 && <hr />}
            {/* Display Available Job Types */}
            <div className={`row`}>
              {workSpaceSettingData?.data
                .filter((el) => !workSpaceActive.includes(el)) // Show only unselected job types
                .map((el) => (
                  <button
                    key={el.code}
                    className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow ${styles.btn_hover}  ${styles.workspace_setting}`}
                    onClick={() => workSpaceHandler(el)}
                  >
                    {el.default_name}
                    <img src={AddIcon} alt="Add Icon" />
                  </button>
                ))}
            </div>
          </div>
        </section>
        {/*============================= End Section 3 ============================ */}
        {/*============================= Start Section 4 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center">
            <h6 className="fw-700">
              What job categories are you interested in?
            </h6>
            <span> — Add 1 or more.</span>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <InputField
                placeholder={t("Select")}
                isList={true}
                list={jobCategoryData?.data?.map((exp) => exp.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
                onChange={(e) => {
                  onStepDataChange((prevState) => ({
                    ...prevState,
                    categories:
                      prevState?.categories && prevState?.categories?.length
                        ? [...prevState?.categories, e.target.value]
                        : [e.target.value],
                  }));
                }}
              />
            </div>
          </div>
          {step?.categories && step?.categories?.length
            ? step?.categories?.map((item) => {
              return (
                <span className={"d-flex p-1 align-items-center"}>
                  <span>{item}</span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      onStepDataChange({
                        ...step,
                        categories: [
                          ...step?.categories?.filter(
                            (f_item) => f_item != item
                          ),
                        ],
                      })
                    }
                  >
                    &times;
                  </span>
                </span>
              );
            })
            : null}
        </section>
        {/*============================= End Section 4 ============================ */}
        {/*============================= Start Section 5 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center">
            <h6 className="fw-700">
              What is the minimum salary you would accept?
            </h6>
            <span>
              — Add a net salary (i.e., final amount you want after taxes and
              insurance).
            </span>
          </div>
          <div className="mt-4">
            <div className="mt-3 d-flex flex-column flex-md-row align-items-start align-items-md-center gap-0 gap-md-3">
              <div>
                <InputField
                  placeholder={t("eg8000")}
                  fieldName="options"
                  style={styles.salary_range_component_style}
                  onChange={handleInputChange("minSalary")}
                  value={jobDetails?.minSalary}
                />
              </div>

              <div
                className={`d-flex align-items-center align-items-md-enter gap-2 ${styles.sar_change}`}
              >
                {!currSalaryChange ? (
                  <div>
                    <p className="mb-3">
                      {jobDetails?.currency.value
                        ? jobDetails?.currency.value
                        : "SAR"}{" "}
                      /{" "}
                      {jobDetails?.currencyPer.value
                        ? jobDetails?.currencyPer.value
                        : "Month"}
                    </p>
                  </div>
                ) : (
                  <div className="d-flex align-items-start align-items-md-enter gap-2">
                    <InputField
                      placeholder={t("Select")}
                      fieldName="options"
                      style={styles.change_currency_style}
                      inputStyle={styles.option_component_style}
                      isList={true}
                      list={allCurrenciesData?.data?.map(
                        (curr) => curr?.default_name
                      )}
                      onChange={(e) =>
                        handleListsChange(
                          e,
                          "currency",
                          allCurrenciesData?.data
                        )
                      }
                      value={jobDetails?.currency.value}
                    />
                    <p className="pt-2">/</p>
                    <InputField
                      placeholder={t("Select")}
                      fieldName="options"
                      style={styles.change_currency_style}
                      inputStyle={styles.option_component_style}
                      isList={true}
                      list={jobSalaryPerData?.data?.map(
                        (per) => per?.default_name
                      )}
                      onChange={(e) =>
                        handleListsChange(
                          e,
                          "currencyPer",
                          jobSalaryPerData?.data
                        )
                      }
                      value={jobDetails?.currencyPer.value}
                    />
                  </div>
                )}

                {/* */}

                <div className={!currSalaryChange ? "mb-3" : "mb-4"}>
                  <span onClick={() => setCurrSalaryChange(!currSalaryChange)}>
                    {!currSalaryChange ? t("Change") : t("Save")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center my-4">
            <input
              type="checkbox"
              id="hide_salary"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              style={{ display: "none" }} // Hide the checkbox
            />
            <img
              id="hide_salary"
              src={checkBoxHandler}
              onClick={() => setIsChecked(!isChecked)}
              className={`${i18n.language === "en" ? "me-2" : "ms-2"} ${styles.check_box_salary
                }`}
              alt="checkbox"
            />
            <label htmlFor="hide_salary" className="fw-500">
              Hide my minimum salary from companies.
            </label>
          </div>
        </section>
        {/*============================= End Section 5 ============================ */}
        {/*============================= Start Section 6 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className={`d-flex align-items-start gap-3`}>
            <div className={`${styles.toggle_buttons}`}>
              <label className={`${styles.switch_btn}`}>
                <input
                  type="checkbox"
                  checked={isFirstToggleChecked}
                  onChange={onFirstToggleHandler}
                />
                <span
                  className={`${styles.slider} ${styles.sliderRound}`}
                ></span>
              </label>
            </div>
            <div className={`d-flex flex-column gap-2`}>
              <h6>Let companies find me on WUZZUF. (Recommended)</h6>
              <span>
                By activating this option, you will increase your chances of
                getting headhunted by companies searching our database.
              </span>
            </div>
          </div>
          <div className={`d-flex align-items-start gap-3 mt-5 mb-3`}>
            <div className={`${styles.toggle_buttons}`}>
              <label className={`${styles.switch_btn}`}>
                <input
                  type="checkbox"
                  checked={isSecondToggleChecked}
                  onChange={onSecondToggleHandler}
                />
                <span
                  className={`${styles.slider} ${styles.sliderRound}`}
                ></span>
              </label>
            </div>
            <div className={`d-flex flex-column gap-2`}>
              <h6>Make my profile public. (Recommended)</h6>
              <span>
                By activating this option, you can share your profile to those
                not on WUZZUF and get more exposure.
              </span>
            </div>
          </div>
        </section>
        {/*============================= End Section 6 ============================ */}
      </form>
    </main>
  );
}

export default StepFive;
