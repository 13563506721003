import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { APIS } from "../../utils/Axios";
import i18n from "../../i18n/i18n";
const initialState = {
  loading: false,
  token: null,
  error: null,
  countries: null,
  cities: null,
  careerLevelsData: null,
  jobTypesData: null,
  workSpaceSettingData: null,
  salaryPerData: null,
  allCurrenciesData: null,
  allGendersData: null,
  experienceYearsData: null,
  educationalLevelsData: null,
  fieldsOfStudyData: null,
  universitiesData: null,
  gradesData: null,
  DegreesData: null,
  allSkillsData: null,
  allLanguagesData: null,
  allLanguagesLevelData: null,
  allPostTypesData: null,
  jobTypesForJobPostData: null,
  jobContractTypeData: null,
  jobCareerLevelData: null,
  jobSalaryPerData: null,
  jobCategoryData: null,
  sendEmailNotifPers: null,
  employeeRangeData: null,
};

// ========================== Helpers =================================|
// ====================================================================|
// *************************** Company ********************************|
// ====================================================================|
// ===================== Get All Countries ============================
export const getAllCountries = createAsyncThunk(
  "helpers/getAllCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.allCountries,
        pathParams: i18n.language ? `?lang=${i18n.language}&per_call=15` : "",
      });
      const res = response.data.data;
      console.log("getAllCountries Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getAllCountries Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Cities ========================
export const getAllCities = createAsyncThunk(
  "helpers/getAllCities",
  async (country_code, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: `${APIS.allCities}/${country_code}/Cities`,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getAllCities Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getAllCities Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Career Levels ========================
export const getCareerLevels = createAsyncThunk(
  "helpers/getCareerLevels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getCareerLevels,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getCareerLevels Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getCareerLevels Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Job Types ========================
export const getJobTypes = createAsyncThunk(
  "helpers/getJobTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getJobTypes,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getJobTypes Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getJobTypes Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Work Space ========================  getWorkSpaceSetting
export const getWorkSpaceSetting = createAsyncThunk(
  "helpers/getWorkSpaceSetting",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getWorkSpaceSetting,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getWorkSpaceSetting Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getWorkSpaceSetting Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Salary Per ========================  getSalaryPer
export const getSalaryPer = createAsyncThunk(
  "helpers/getSalaryPer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getSalaryPer,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getSalaryPer Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getSalaryPer Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Currencies ========================
export const getAllCurrencies = createAsyncThunk(
  "helpers/getAllCurrencies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getAllCurrencies,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getAllCurrencies Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getAllCurrencies Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Genders ========================
export const getAllGenders = createAsyncThunk(
  "helpers/getAllGenders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getAllGenders,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getAllGenders Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getAllGenders Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Experience Year ========================
export const getExperienceYears = createAsyncThunk(
  "helpers/getExperienceYears",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getExperienceYears,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getExperienceYears Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getExperienceYears Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Educational Levels ========================
export const getEducationalLevels = createAsyncThunk(
  "helpers/getEducationalLevels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getEducationalLevels,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getEducationalLevels Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getEducationalLevels Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Feilds of Study ========================
export const getFieldsOfStudy = createAsyncThunk(
  "helpers/getFieldsOfStudy",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getFieldsOfStudy,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getFieldsOfStudy Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getFieldsOfStudy Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get UniverSity ========================
export const getUniversities = createAsyncThunk(
  "helpers/getUniversities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getUniversities,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getUniversities Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getUniversities Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Grades ========================
export const getGrades = createAsyncThunk(
  "helpers/getGrades",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getGrades,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getGrades Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getGrades Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Degree ======================== getDegrees
export const getDegrees = createAsyncThunk(
  "helpers/getDegrees",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getDegrees,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getDegrees Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getDegrees Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Skills ========================
export const getAllSkills = createAsyncThunk(
  "helpers/getAllSkills",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getAllSkills,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getAllSkills Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getAllSkills Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get all Languages ========================
export const getAllLanguages = createAsyncThunk(
  "helpers/getAllLanguages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getAllLanguages,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getAllLanguages Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getAllLanguages Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get all Languages ========================
export const getLanguagesLevels = createAsyncThunk(
  "helpers/getLanguagesLevels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getLanguagesLevels,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getLanguagesLevels Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getLanguagesLevels Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ====================================================================|
// ***************************** Jobs *********************************|
// ====================================================================|
// ===================== Get Post Types ======================== getPostTypes
export const getPostTypes = createAsyncThunk(
  "helpers/getPostTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getPostTypes,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getPostTypes Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getPostTypes Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ================== Get Job Category For Job Post =====================  getJobCategory
export const getJobCategory = createAsyncThunk(
  "helpers/getJobCategory",
  async (_, { rejectWithValue }) => {
    const data = {
      lang: i18n.language,
      high : "yes"
    }
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getJobCategory,
        // params : data
        pathParams: i18n.language ? `?lang=${i18n.language}&high=yes` : "",
      });
      const res = response.data.data;
      console.log("getJobCategory Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getJobCategory Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ================== Get Job Types For Job Post =====================
export const getJobTypesForJobPost = createAsyncThunk(
  "helpers/getJobTypesForJobPost",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getJobTypesForJobPost,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getJobTypesForJobPost Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getJobTypesForJobPost Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ================== Get Job Contract Types =====================
export const getJobContractTypes = createAsyncThunk(
  "helpers/getJobContractTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getJobContractTypes,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getJobContractTypes Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getJobContractTypes Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ================== Get Job Careel Level =====================
export const getJobCareerLevels = createAsyncThunk(
  "helpers/getJobCareerLevels",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getJobCareerLevels,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getJobCareerLevels Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getJobCareerLevels Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ================== Get Job Salary Per =====================
export const getJobSalaryPer = createAsyncThunk(
  "helpers/getJobSalaryPer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getJobSalaryPer,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getJobSalaryPer Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getJobSalaryPer Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ================== Get Send Email Notification Per =====================
export const sendEmailNotifPers = createAsyncThunk(
  "helpers/sendEmailNotifPers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.sendEmailNotifPers,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("sendEmailNotifPers Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("sendEmailNotifPers Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ================== Get Employee Range ===================== getEmployeeRange
export const getEmployeeRange = createAsyncThunk(
  "helpers/getEmployeeRange",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getEmployeeRange,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("getEmployeeRange Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getEmployeeRange Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ---------------------------- Reducers ------------------------------|
// --------------------------------------------------------------------|
const helperSlice = createSlice({
  name: "helpers",
  initialState: initialState,
  reducers: {
    // Define logoutSuccess to reset the helpers state
    logoutSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.token = null;
      state.countries = null;
      state.cities = null;
      state.careerLevelsData = null;
      state.jobTypesData = null;
      state.workSpaceSettingData = null;
      state.salaryPerData = null;
      state.allCurrenciesData = null;
      state.allGendersData = null;
      state.experienceYearsData = null;
      state.educationalLevelsData = null;
      state.educationalLevelsData = null;
      state.fieldsOfStudyData = null;
      state.universitiesData = null;
      state.gradesData = null;
      state.DegreesData = null;
      state.allSkillsData = null;
      state.allLanguagesData = null;
      state.allLanguagesLevelData = null;
      state.allPostTypesData = null;
      state.jobTypesForJobPostData = null;
      state.jobContractTypeData = null;
      state.jobCareerLevelData = null;
      state.jobSalaryPerData = null;
      state.sendEmailNotifPers = null;
      state.jobCategoryData = null;
      state.employeeRangeData = null;
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      // ===================== Get All Countries =======================
      .addCase(getAllCountries.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.loading = false;
        const filteredCountries = action.payload;
        // const filteredCountries = action.payload?.filter(
        //   (item) => item.id == 187
        // );
        state.countries = filteredCountries;
        state.error = null;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Cities =======================
      .addCase(getAllCities.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload;
        state.error = null;
      })
      .addCase(getAllCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Career Levels ========================
      .addCase(getCareerLevels.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCareerLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.careerLevelsData = action.payload;
        state.error = null;
      })
      .addCase(getCareerLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Job Types ========================
      .addCase(getJobTypes.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.jobTypesData = action.payload;
        state.error = null;
      })
      .addCase(getJobTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Work Space ========================
      .addCase(getWorkSpaceSetting.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkSpaceSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.workSpaceSettingData = action.payload;
        state.error = null;
      })
      .addCase(getWorkSpaceSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Salary Per ========================
      .addCase(getSalaryPer.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSalaryPer.fulfilled, (state, action) => {
        state.loading = false;
        state.salaryPerData = action.payload;
        state.error = null;
      })
      .addCase(getSalaryPer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Currencies ========================
      .addCase(getAllCurrencies.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.allCurrenciesData = action.payload;
        state.error = null;
      })
      .addCase(getAllCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Genders ========================
      .addCase(getAllGenders.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllGenders.fulfilled, (state, action) => {
        state.loading = false;
        state.allGendersData = action.payload;
        state.error = null;
      })
      .addCase(getAllGenders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Experience Year ========================
      .addCase(getExperienceYears.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExperienceYears.fulfilled, (state, action) => {
        state.loading = false;
        state.experienceYearsData = action.payload;
        state.error = null;
      })
      .addCase(getExperienceYears.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Educational Levels ========================
      .addCase(getEducationalLevels.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEducationalLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.educationalLevelsData = action.payload;
        state.error = null;
      })
      .addCase(getEducationalLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Feilds of Study ========================
      .addCase(getFieldsOfStudy.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFieldsOfStudy.fulfilled, (state, action) => {
        state.loading = false;
        state.fieldsOfStudyData = action.payload;
        state.error = null;
      })
      .addCase(getFieldsOfStudy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get UniverSity ========================
      .addCase(getUniversities.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUniversities.fulfilled, (state, action) => {
        state.loading = false;
        state.universitiesData = action.payload;
        state.error = null;
      })
      .addCase(getUniversities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Grades ========================
      .addCase(getGrades.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGrades.fulfilled, (state, action) => {
        state.loading = false;
        state.gradesData = action.payload;
        state.error = null;
      })
      .addCase(getGrades.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Degree ======================== 
      .addCase(getDegrees.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDegrees.fulfilled, (state, action) => {
        state.loading = false;
        state.DegreesData = action.payload;
        state.error = null;
      })
      .addCase(getDegrees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Skills ========================
      .addCase(getAllSkills.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllSkills.fulfilled, (state, action) => {
        state.loading = false;
        state.allSkillsData = action.payload;
        state.error = null;
      })
      .addCase(getAllSkills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get all Languages ========================
      .addCase(getAllLanguages.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllLanguages.fulfilled, (state, action) => {
        state.loading = false;
        state.allLanguagesData = action.payload;
        state.error = null;
      })
      .addCase(getAllLanguages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get all Languages ========================  getLanguagesLevels
      .addCase(getLanguagesLevels.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLanguagesLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.allLanguagesLevelData = action.payload;
        state.error = null;
      })
      .addCase(getLanguagesLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ====================================================================|
      // ***************************** Jobs *********************************|
      // ====================================================================|
      // ===================== Get Post Types ========================
      .addCase(getPostTypes.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPostTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.allPostTypesData = action.payload;
        state.error = null;
      })
      .addCase(getPostTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ================== Get Job Category For Job Post =====================
      .addCase(getJobCategory.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.jobCategoryData = action.payload;
        state.error = null;
      })
      .addCase(getJobCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ================== Get Job Types For Job Post =====================
      .addCase(getJobTypesForJobPost.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobTypesForJobPost.fulfilled, (state, action) => {
        state.loading = false;
        state.jobTypesForJobPostData = action.payload;
        state.error = null;
      })
      .addCase(getJobTypesForJobPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ================== Get Job Contract Types =====================
      .addCase(getJobContractTypes.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobContractTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.jobContractTypeData = action.payload;
        state.error = null;
      })
      .addCase(getJobContractTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ================== Get Job Careel Level =====================
      .addCase(getJobCareerLevels.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobCareerLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.jobCareerLevelData = action.payload;
        state.error = null;
      })
      .addCase(getJobCareerLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ================== Get Job Salary Per =====================
      .addCase(getJobSalaryPer.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobSalaryPer.fulfilled, (state, action) => {
        state.loading = false;
        state.jobSalaryPerData = action.payload;
        state.error = null;
      })
      .addCase(getJobSalaryPer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ================== Get Send Email Notification Per =====================
      .addCase(sendEmailNotifPers.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendEmailNotifPers.fulfilled, (state, action) => {
        state.loading = false;
        state.sendEmailNotificationData = action.payload;
        state.error = null;
      })
      .addCase(sendEmailNotifPers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ================== Get Employee Range ===================== 
      .addCase(getEmployeeRange.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmployeeRange.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeRangeData = action.payload;
        state.error = null;
      })
      .addCase(getEmployeeRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { logoutSuccess } = helperSlice.actions;
export default helperSlice.reducer;
