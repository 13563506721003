import React from "react";
import styles from "./styles.module.css";
import { CancelClose } from "../../assets";

function Modal({ isOpen, onClose, children, closeOnOverlayClick = true }) {
  // Handle click outside modal container
  const handleOverlayClick = (event) => {
    if (closeOnOverlayClick && event.target === event.currentTarget) {
      console.log("Overlay clicked, closing modal.");
      onClose(); // Calls the onClose function to close the modal
    }
  };

  return (
    <div
      className={`${styles.modal_overlay} ${isOpen ? styles.show : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={styles.modal_container} onClick={(e) => e.stopPropagation()}>
        {/* Close Button */}
        <div className={styles.close_btn} onClick={onClose}>
          <img src={CancelClose} alt="closeBtn" />
        </div>
        {/* Content */}
        {children}
      </div>
    </div>
  );
}

export default Modal;
