import React, { useEffect, useState } from "react";
import { bagIcon, RoundedCheck, SignUpImg } from "../../assets";
import { Button, InputField } from "../../components";
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  candidateAct,
  corporateAct,
  signUpOne,
  signUpOneCorporate,
} from "../../redux/slices/authSlice";
import { t } from "i18next";
import { getJobTypes } from "../../redux/slices/helperSlice";
import i18n from "../../i18n/i18n";

function SignUp() {
  const { loading, signupError } = useSelector((state) => state.auth);
  const { jobTypesData } = useSelector((state) => state.helpers);
  const [userStatus, setUserStatus] = useState("Candidate");
  const [roleValue, setRoleValue] = useState(false);
  const [candidate, setCandidate] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    job_title: "",
  });
  const [corporate, setCorporate] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    business_email: "",
    password: "",
    job_title: "",
  });
  const [validateErrors, setValidateErrors] = useState({
    userStatus: "",
    firstName: "",
    lastName: "",
    companyName: "",
    businessMail: "",
    password: "",
    phoneNumber: "",
    hiringRole: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validation function
  const CandidateChangeHandler = (propertyName) => (e) => {
    setCandidate((prev) => ({
      ...prev,
      [propertyName]: e.target.value,
    }));
  };

  const CorporateChangeHandler = (propertyName) => (e) => {
    setCorporate((prev) => ({
      ...prev,
      [propertyName]: e.target.value,
    }));
  };

  const selectRoleHandler = (e, status) => {
    const roleValue = e.target.value;
    const selectedRole = jobTypesData?.data?.find(
      (el) => el.default_name === roleValue
    );
    if (selectedRole) {
      const roleCode = selectedRole.code;
      setCandidate((prev) => ({ ...prev, job_title: roleCode }));
    }
    setRoleValue(roleValue);
  };

  const validate = () => {
    const companyEmailRegex = /^[^\s@]+@company\.com$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const egyptianPhoneRegex = /^(010|011|012|015)\d{8}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;

    let errors = {
      userStatus: "",
      firstName: "",
      lastName: "",
      companyName: "",
      businessMail: "",
      password: "",
      phoneNumber: "",
      hiringRole: "",
    };

    // Validate User Status
    if (!userStatus) {
      errors.userStatus = "Please select either Corporate or Candidate";
    }

    // Validation for Candidate
    if (userStatus === "Candidate") {
      if (!candidate.first_name.trim()) {
        errors.firstName = "First Name is required";
      }
      if (!candidate.last_name.trim()) {
        errors.lastName = "Last Name is required";
      }
      if (!candidate.email.trim() || !emailRegex.test(candidate.email)) {
        errors.businessMail = "Invalid email address";
      }
      if (
        !candidate.password.trim() ||
        !passwordRegex.test(candidate.password)
      ) {
        errors.password =
          "Password must be at least 8 characters, include an uppercase letter and a number";
      }
      if (!roleValue || !roleValue.trim()) {
        errors.hiringRole = "Job Title is required";
      }
    }

    // Validation for Corporate
    if (userStatus === "Corporate") {
      if (!corporate.first_name.trim()) {
        errors.firstName = "First Name is required";
      }
      if (!corporate.last_name.trim()) {
        errors.lastName = "Last Name is required";
      }
      if (
        !corporate.business_email.trim() ||
        !companyEmailRegex.test(corporate.business_email)
      ) {
        errors.businessMail = "Must be like this: Infinity@company.com";
      }
      if (
        !corporate.password.trim() ||
        !passwordRegex.test(corporate.password)
      ) {
        errors.password =
          "Password must be at least 8 characters, include an uppercase letter and a number";
      }
      if (
        !corporate.phone_number.trim() ||
        !egyptianPhoneRegex.test(corporate.phone_number)
      ) {
        errors.phoneNumber = "Invalid phone number";
      }
      if (!corporate.company_name.trim()) {
        errors.companyName = "Company Name is required";
      }
      if (!roleValue || !roleValue.trim()) {
        errors.hiringRole = "Job Title is required";
      }
    }
    // Set validation errors in state
    setValidateErrors(errors);

    // Return true if no errors, otherwise false
    return Object.values(errors).every((error) => !error);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    // Common actions to perform after data submission
    const clearFields = () => {
      if (userStatus === "Candidate") {
        setCandidate({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          job_title: "",
        });
      } else if (userStatus === "Corporate") {
        setCorporate({
          company_name: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          business_email: "",
          password: "",
          job_title: "",
        });
        setRoleValue("");
      }
    };

    // Validate form data before proceeding
    if (!validate()) return;

    // Handle Candidate data submission
    if (userStatus === "Candidate") {
      const candidateData = {
        first_name: candidate.first_name,
        last_name: candidate.last_name,
        email: candidate.email,
        password: candidate.password,
        job_type: candidate.job_title,
      };

      dispatch(signUpOne(candidateData))
        .unwrap()
        .then(() => {
          console.log("Candidate Signup", candidateData);
          dispatch(candidateAct("Candidate"));
          navigate("/mainRegisterStep?email=" + candidate.email);
          clearFields();
        })
        .catch(() => null)
    }

    // Handle Corporate data submission
    else if (userStatus === "Corporate") {
      const corporateData = {
        business_email: corporate.business_email,
        first_name: corporate.first_name,
        last_name: corporate.last_name,
        password: corporate.password,
        mobile_number: corporate.phone_number,
        company_name: corporate.company_name,
        hiring_for: [roleValue],
      };
      console.log("Corporate Signup", corporateData);
      dispatch(signUpOneCorporate(corporateData))
        .unwrap()
        .then((response) => {
          // console.log("response", response);
          localStorage.setItem("access_token", response?.data?.access_token);
          dispatch(corporateAct("Corporate"));
          navigate("/ChooseJobPost");
          clearFields();
        })
        .catch(() => null)
    }
  };

  const handleUserStatus = (e, status) => {
    e.preventDefault();
    setUserStatus(status);
    setValidateErrors({})
  };

  useEffect(() => {
    dispatch(getJobTypes());
  }, [dispatch]);

  return (
    <main>
      <div className={`${styles.landing} container-fluid pt-4`}>
        <div className="row flex-column flex-lg-row justify-content-center mb-4 mb-lg-0">
          {/* Register Header Image */}
          <div
            className={`pt-0 mt-0 pt-md-5 mt-md-5 col-12 col-lg-7 mb-4 mb-lg-0 order-first flex-grow-1 ${styles.signup_img}`}
          >
            <figure>
              <img src={SignUpImg} alt="meeting" className="img" />
            </figure>
          </div>
          {/* Register Header Form */}
          <div
            className={`d-flex flex-column justify-content-center col-12 col-lg-4 flex-grow-1 mb-sm-5 mb-md-5`}
          >
            <form>
              <div
                className={`${styles.signup_form} d-flex flex-column px-5 pt-4 pb-5 container align-items-center`}
              >
                {/* Title & icon */}
                <div className="d-flex flex-column align-items-center">
                  <div className={`${styles.bag_icon}`}>
                    <img src={bagIcon} alt="bagIcon" />
                  </div>
                  <p className="fw-bold fs-2">Sign up</p>
                </div>
                {/* End Title & icon */}
                {/* Two Button */}
                <div className="d-flex justify-content-evenly gap-4 mt-3 mb-5">
                  <Button
                    style={`px-4 px-md-5 py-3 shadow ${userStatus === "Candidate" ? styles.btn_Candidate : ""
                      }`}
                    title={t("Candidate")}
                    onClick={(e) => handleUserStatus(e, "Candidate")}
                    leftIcon={
                      userStatus === "Candidate" && i18n.language === "ar"
                        ? RoundedCheck
                        : null
                    }
                    rightIcon={
                      userStatus === "Candidate" && i18n.language === "en"
                        ? RoundedCheck
                        : null
                    }
                  />
                  <Button
                    style={`px-4 px-md-5 py-3  ${userStatus === "Corporate" ? styles.btn_Candidate : ""
                      }`}
                    title={t("Corporate")}
                    onClick={(e) => handleUserStatus(e, "Corporate")}
                    leftIcon={
                      userStatus === "Corporate" && i18n.language === "ar"
                        ? RoundedCheck
                        : null
                    }
                    rightIcon={
                      userStatus === "Corporate" && i18n.language === "en"
                        ? RoundedCheck
                        : null
                    }
                  />
                </div>
                {/* End Two Button */}
                {/* StartInputs */}
                <div>
                  {userStatus === null ? (
                    ""
                  ) : userStatus === "Corporate" ? (
                    <div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">{t("CompanyName")}</p>
                        <input
                          type="text"
                          placeholder={t("EnterYourCompanyName")}
                          className={`${styles.input_filed}`}
                          onChange={CorporateChangeHandler("company_name")}
                        />
                        {validateErrors.companyName && (
                          <p className="text-danger">
                            {validateErrors.companyName}
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center gap-3">
                        <div className="d-flex flex-column align-self-start w-100 mb-3">
                          <p className="fw-600 mb-3">{t("FirstName")}</p>
                          <input
                            type="text"
                            placeholder={t("egAhmed")}
                            className={`${styles.input_filed}`}
                            onChange={CorporateChangeHandler("first_name")}
                          />
                          {validateErrors.firstName && (
                            <p className="text-danger">
                              {validateErrors.firstName}
                            </p>
                          )}
                        </div>
                        <div className="d-flex flex-column align-self-start w-100 mb-3">
                          <p className="fw-600 mb-3">{t("LastName")}</p>
                          <input
                            type="text"
                            placeholder={t("egAhmed")}
                            className={`${styles.input_filed}`}
                            onChange={CorporateChangeHandler("last_name")}
                          />
                          {validateErrors.lastName && (
                            <p className="text-danger">
                              {validateErrors.lastName}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">{t("MobileNumber")}</p>
                        <input
                          type="text"
                          placeholder={t("EnterYourPhoneNumber")}
                          className={`${styles.input_filed}`}
                          onChange={CorporateChangeHandler("phone_number")}
                        />
                        {validateErrors.phoneNumber && (
                          <p className="text-danger">
                            {validateErrors.phoneNumber}
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">{t("BusinessMail")}</p>
                        <input
                          type="text"
                          placeholder={t("EnterYourBusinessEmail")}
                          className={`${styles.input_filed}`}
                          onChange={CorporateChangeHandler("business_email")}
                        />
                        {validateErrors.businessMail && (
                          <p className="text-danger">
                            {validateErrors.businessMail}
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">{t("CreatePassword")}</p>
                        <input
                          type="password"
                          placeholder={t("EnterYourPassword")}
                          className={`${styles.input_filed}`}
                          onChange={CorporateChangeHandler("password")}
                        />
                        {validateErrors.password && (
                          <p className="text-danger">
                            {validateErrors.password}
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">{t("WhichRoleAreYouFor")}</p>
                        <InputField
                          type="text"
                          placeholder={t("EnterTheJobTitle")}
                          style={styles.select_component_style}
                          inputStyle={styles.option_component_style}
                          value={roleValue}
                          onBlur={() => validate()}
                          isList={true}
                          list={jobTypesData?.data?.map(
                            (job) => job.default_name
                          )}
                          onChange={(e) => selectRoleHandler(e, "Corporate")}
                        />
                        {validateErrors.hiringRole && (
                          <p className="text-danger">
                            {validateErrors.hiringRole}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="d-flex flex-row justify-content-between align-items-center gap-3">
                        <div className="d-flex flex-column align-self-start w-100 mb-3">
                          <p className="fw-600 mb-3">{t("FirstName")}</p>
                          <input
                            type="text"
                            placeholder={t("egAhmed")}
                            className={`${styles.input_filed}`}
                            onChange={CandidateChangeHandler("first_name")}
                          />
                          {validateErrors.firstName && (
                            <p className="text-danger">
                              {validateErrors.firstName}
                            </p>
                          )}
                        </div>
                        <div className="d-flex flex-column align-self-start w-100 mb-3">
                          <p className="fw-600 mb-3">{t("LastName")}</p>
                          <input
                            type="text"
                            placeholder={t("egAhmed")}
                            className={`${styles.input_filed}`}
                            onChange={CandidateChangeHandler("last_name")}
                          />
                          {validateErrors.lastName && (
                            <p className="text-danger">
                              {validateErrors.lastName}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">{t("Email")}</p>
                        <input
                          type="text"
                          placeholder={t("EnterYourEmailAddress")}
                          className={`${styles.input_filed}`}
                          onChange={CandidateChangeHandler("email")}
                        />
                        {validateErrors.businessMail && (
                          <p className="text-danger">
                            {validateErrors.businessMail}
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">{t("CreatePassword")}</p>
                        <input
                          type="password"
                          placeholder={t("EnterYourPassword")}
                          className={`${styles.input_filed}`}
                          onChange={CandidateChangeHandler("password")}
                        />
                        {validateErrors.password && (
                          <p className="text-danger">
                            {validateErrors.password}
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-column align-self-start w-100 mb-3">
                        <p className="fw-600 mb-3">
                          What job titles are you looking for?
                        </p>
                        <InputField
                          type="text"
                          placeholder={
                            "Choose The job titles are you looking for"
                          }
                          style={styles.select_component_style}
                          inputStyle={styles.option_component_style}
                          value={roleValue}
                          onBlur={() => validate()}
                          isList={true}
                          list={jobTypesData?.data?.map(
                            (job) => job.default_name
                          )}
                          onChange={(e) => selectRoleHandler(e, "Candidate")}
                        />
                        {validateErrors.hiringRole && (
                          <p className="text-danger">
                            {validateErrors.hiringRole}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {/* End Inputs */}
                  {/* Start Handle Error */}
                  <div>
                    <div className={styles.error_style}>
                      {signupError && <p className="text-danger">{signupError}</p>}
                      {validateErrors.userStatus && (
                        <p className="text-danger">
                          {validateErrors.userStatus}
                        </p>
                      )}
                    </div>
                    {/* End Handle Error */}
                    {/* Next Button */}
                    <div
                      className={`w-100 d-flex align-items-center flex-column ${styles.end_signup_form}`}
                    >
                      <Button
                        title={
                          userStatus === "Candidate"
                            ? t("Next")
                            : t("CreateAccount")
                        }
                        style={`shadow mb-2 ${styles.next_btn}`}
                        onClick={onSubmitHandler}
                        loading={loading}
                      />
                      <Link to="#/" className="d-block my-2 fw-500">
                        {t("LookingToPostjobsCreateAnEmployerAccount")}
                      </Link>
                    </div>
                    {/* End Next Button */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SignUp;
