import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { APIS } from "../../utils/Axios";
import i18n from "../../i18n/i18n";
const initialState = {
  token: null,
  loading: false,
  error: null,
  postJobHelperData: null,
  filterHelpers: null,
  jobDetailsData: null,
  categoriesNameData: null,
  filteredJobs: null,
  allSavedJobData: null,
  allAppliedJobData: null,
  salaryCurrencyData: null,
  allJobCategoryData: null,
  allMyJobPostsData: null,
  isJobPostEditing: false,
  jobWeWillEditData: null,
  usersApplicationsData: null,
  userCvsData: null,
  userCvsDetailsData: null,
  CompanyEmployeeRangeData: null,
  companyProfileData: null,
  industriesData: null,
  specialtiesData: null,
  saudiArabiaCitiesData: null,
  companiesPartnerData: null,
  getRecentlyJobsData: null,
  companyDetailsData: null,
  companyViewedData: null
};


// ========================== GET Candidate Profile Company Viewed Data ========================
export const getandidateProfileCompanyViewedData = createAsyncThunk(
  "jobs/getandidateProfileCompanyViewedData",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getandidateProfileCompanyViewedData,
        accessToken: token,
        data: data,
        pathParams: i18n.language
          ? `?lang=${i18n.language}`
          : "",
      });
      console.log("getandidateProfileCompanyViewedData", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getandidateProfileCompanyViewedData Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ========================== Helpers ========================
// ===================== COM all Job Category Helper ======================== allJobCategoryCOM
// export const allJobCategoryCOM = createAsyncThunk(
//   "jobs/allJobCategoryCOM",
//   async (_, { rejectWithValue, getState }) => {
//     const { token } = getState().auth;
//     try {
//       const res = await Axios({
//         method: "GET",
//         path: APIS.allJobCategoryCOM,
//         accessToken: token,
//       });
//       console.log("allJobCategoryCOM", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("allJobCategoryCOM Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// ===================== Post Job Helper ========================
// export const postJobHelper = createAsyncThunk(
//   "jobs/postJobHelper",
//   async (_, { rejectWithValue, getState }) => {
//     const { token } = getState().auth;
//     try {
//       const res = await Axios({
//         method: "GET",
//         path: APIS.postJobHelper,
//         accessToken: token,
//       });
//       console.log("postJobHelper", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("postJobHelper Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// ===================== Get Salary Currency Helper ========================
// export const getSalaryCurrency = createAsyncThunk(
//   "jobs/getSalaryCurrency",
//   async (_, { rejectWithValue, getState }) => {
//     const { token } = getState().auth;
//     try {
//       const res = await Axios({
//         method: "GET",
//         path: APIS.getSalaryCurrency,
//         accessToken: token,
//       });
//       console.log("getSalaryCurrency", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("getSalaryCurrency Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// ========================== Candidate ========================
// ========================== Edit Candidate Profile ======================== 
export const editCandidateProfile = createAsyncThunk(
  "jobs/editCandidateProfile",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.editCandidateProfile,
        accessToken: token,
        data: data,
      });
      console.log("editCandidateProfile", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("editCandidateProfile Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== Get Category With Search ========================
export const getCategoryWithSearch = createAsyncThunk(
  "jobs/getCategoryWithSearch",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log("Get Category job code: ", data);
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getCategoryWithSearch,
        accessToken: token,
        params: data,
      });
      console.log("getCategoryWithSearch", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getCategoryWithSearch Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== Get Company Details Data ======================== 
export const getCompanyDetailsbyUser = createAsyncThunk(
  "jobs/getCompanyDetailsbyUser",
  async (companyCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log("Get country code: ", companyCode);
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getCompanyDetailsbyUser,
        accessToken: token,
        pathParams: companyCode?.company_code ? `?company_code=${companyCode.company_code}&is_high_job=${1}` : "",
      });
      console.log("getCompanyDetailsbyUser", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getCompanyDetailsbyUser Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== Get All Helpers Jobs For Filter ========================
// export const getAllHelperJobs = createAsyncThunk(
//   "jobs/getAllHelperJobs",
//   async (_, { rejectWithValue, getState }) => {
//     const { token } = getState().auth;
//     try {
//       const res = await Axios({
//         method: "GET",
//         path: APIS.getAllHelperJobs,
//         accessToken: token,
//       });
//       console.log("getAllHelperJobs", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("getAllHelperJobs Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// ========================== Get All With Filter ========================
export const getAllWithFilter = createAsyncThunk(
  "jobs/getAllWithFilter",
  async (data, { rejectWithValue, getState }) => {
    console.log("dataaaaaa", data);
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getAllWithFilter,
        accessToken: token,
        isFormData: true,
        pathParams: data
          ? `?contract_type=${JSON.stringify(
            data.contract_type
          )}&city=${JSON.stringify(
            data.city || []
          )}&career_level=${JSON.stringify(
            data.career_level || []
          )}&category=${data.category || []}&title=${data.title || ""}&is_high_job=${1}`
          : "",
      });
      console.log("getAllWithFilter", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getAllWithFilter Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== Get Job Details ========================
export const getJobsDetails = createAsyncThunk(
  "jobs/getJobsDetails",
  async (jobCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;

    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getJobsDetails,
        accessToken: token,
        params: jobCode,
      });
      console.log("getJobsDetails", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getJobsDetails Error", errorMessage);
      console.log("Error", error);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Save Job ========================
export const saveJob = createAsyncThunk(
  "jobs/saveJob",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    data?.append("is_high_job", 1)
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.saveJob,
        data: data,
        isFormData: true,
        accessToken: token,
      });
      console.log("saveJob", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("saveJob Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ===================== un Save Job ========================
export const unSaveJob = createAsyncThunk(
  "jobs/unSaveJob",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.unSaveJob,
        data: data,
        isFormData: true,
        accessToken: token,
      });
      console.log("unSaveJob", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("unSaveJob Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ========================== all Saved Job ========================
export const allSavedJob = createAsyncThunk(
  "jobs/allSavedJob",
  async (jobCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log("Get job Details: ", jobCode);

    try {
      const res = await Axios({
        method: "GET",
        path: APIS.allSavedJob,
        accessToken: token,
        params: jobCode,
      });
      console.log("allSavedJob", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("allSavedJob Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== View Job Counter ========================
export const ViewJobCounter = createAsyncThunk(
  "jobs/ViewJobCounter",
  async (jobCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log("Get job Details: ", jobCode);
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.ViewJobCounter,
        data: jobCode,
        isFormData: true,
        accessToken: token,
      });
      console.log("ViewJobCounter", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("ViewJobCounter Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== Apply Job ========================
export const ApplyJob = createAsyncThunk(
  "jobs/ApplyJob",
  async (jobCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log("Get job Details: ", jobCode);
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.ApplyJob,
        data: jobCode,
        isFormData: true,
        accessToken: token,
      });
      console.log("ApplyJob", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("ApplyJob Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== UN Apply Job ========================
export const UnapplyJob = createAsyncThunk(
  "jobs/UnapplyJob",
  async (jobCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log("Get job Details: ", jobCode);
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.UnapplyJob,
        accessToken: token,
        data: jobCode,
      });
      console.log("UnapplyJob", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("UnapplyJob Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== Get All Apply Job ========================
export const getAllApplyJobs = createAsyncThunk(
  "jobs/getAllApplyJobs",
  async (jobCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log("Get job Details: ", jobCode);
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getAllApplyJobs,
        accessToken: token,
        data: jobCode,
      });
      console.log("getAllApplyJobs", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getAllApplyJobs Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ========================== Company ========================
// ===================== Post New Job ========================
export const postNewJob = createAsyncThunk(
  "jobs/postNewJob",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.postNewJob,
        data: data,
        isFormData: true,
        accessToken: token,
      });
      console.log("postNewJob", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("postNewJob Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All my Job Posts ========================
export const getAllMyJobPosts = createAsyncThunk(
  "jobs/getAllMyJobPosts",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getAllMyJobPosts,
        accessToken: token,
      });
      console.log("getAllMyJobPosts", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getAllMyJobPosts Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== DELETE Job Post ========================
export const deleteJobPost = createAsyncThunk(
  "jobs/deleteJobPost",
  async (jobCode, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().auth;
    console.log(jobCode.job_code);
    try {
      const res = await Axios({
        method: "DELETE",
        path: APIS.deleteJobPost,
        accessToken: token,
        data: jobCode,
      });
      console.log("deleteJobPost", res?.data);
      dispatch(getAllMyJobPosts());
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("deleteJobPost Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Edit Job Post ========================
export const editJobPost = createAsyncThunk(
  "jobs/editJobPost",
  async (jobCode, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().auth;
    console.log(jobCode.job_code);
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.editJobPost,
        accessToken: token,
        data: jobCode,
      });
      console.log("editJobPost", res?.data);
      dispatch(getAllMyJobPosts());
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("editJobPost Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All User Applications ========================
export const getAllUserApplications = createAsyncThunk(
  "jobs/getAllUserApplications",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getAllUserApplications,
        accessToken: token,
      });
      console.log("getAllUserApplications", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getAllUserApplications Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All User CVs ========================
export const getAllUserCVs = createAsyncThunk(
  "jobs/getAllUserCVs",
  async (jobTitle, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log(jobTitle.job_title); // should now log the correct job title
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getAllUserCVs,
        accessToken: token,
        pathParams: jobTitle?.job_title
          ? `?job_title=${jobTitle.job_title}`
          : "",
      });
      console.log("getAllUserCVs", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getAllUserCVs Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All User CVs Details========================
export const getCandidateCVDetails = createAsyncThunk(
  "jobs/getCandidateCVDetails",
  async (jobCode, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getCandidateCVDetails,
        accessToken: token,
        pathParams: jobCode?.code ? `?code=${jobCode.code}` : "",
      });
      console.log("getCandidateCVDetails", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getCandidateCVDetails Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Company Employee Range ========================
export const getCompanyEmployeeRange = createAsyncThunk(
  "jobs/getCompanyEmployeeRange",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getCompanyEmployeeRange,
        accessToken: token,
      });
      console.log("getCompanyEmployeeRange", res?.data);
      return res.data.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getCompanyEmployeeRange Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Company Profile ========================
export const getCompanyProfile = createAsyncThunk(
  "jobs/getCompanyProfile",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getCompanyProfile,
        accessToken: token,
      });
      console.log("getCompanyProfile", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getCompanyProfile Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Edit Company Profile ========================
export const editCompanyProfile = createAsyncThunk(
  "jobs/editCompanyProfile",
  async (companyData, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.editCompanyProfile,
        accessToken: token,
        data: companyData,
      });
      console.log("editCompanyProfile", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("editCompanyProfile Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Industries Search ======================== getIndustriesSearch
export const getIndustriesSearch = createAsyncThunk(
  "jobs/getIndustriesSearch",
  async (industryName, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getIndustriesSearch,
        accessToken: token,
        pathParams: industryName?.company_industry_name
          ? `?company_industry_name=${industryName.company_industry_name}`
          : "",
      });
      console.log("getIndustriesSearch", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getIndustriesSearch Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get Specialties Search ======================== getSpecialtiesSearch
export const getSpecialtiesSearch = createAsyncThunk(
  "jobs/getSpecialtiesSearch",
  async (specialName, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getSpecialtiesSearch,
        accessToken: token,
        pathParams: specialName?.company_specialties_name
          ? `?company_specialties_name=${specialName.company_specialties_name}`
          : "",
      });
      console.log("getSpecialtiesSearch", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getSpecialtiesSearch Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ===================== Home ========================  
// ===================== Get All Saudi Arabia Cities ========================  saudiArabiaCities
export const saudiArabiaCities = createAsyncThunk(
  "jobs/saudiArabiaCities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.saudiArabiaCities,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data.data;
      console.log("saudiArabiaCities Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("saudiArabiaCities Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Companies Partner ========================  companiesPartner
export const companiesPartner = createAsyncThunk(
  "jobs/companiesPartner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.companiesPartner,
        pathParams: i18n.language ? `?lang=${i18n.language}` : "",
      });
      const res = response.data;
      console.log("companiesPartner Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("companiesPartner Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Get All Recently Jobs ========================  getRecentlyJobs
export const getRecentlyJobs = createAsyncThunk(
  "jobs/getRecentlyJobs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: "GET",
        path: APIS.getRecentlyJobs,
        pathParams: i18n.language ? `?lang=${i18n.language}&is_high_job=${1}` : "",
      });
      const res = response.data.data;
      console.log("getRecentlyJobs Success", res);
      return res;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("getRecentlyJobs Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ==============================================================
const jobsSlice = createSlice({
  name: "jobs",
  initialState: initialState,
  reducers: {
    logoutSuccess: (state) => {
      state.token = null;
      state.loading = false;
      state.error = null;
      state.postJobHelperData = null;
      state.filterHelpers = null;
      state.jobDetailsData = null;
      state.categoriesNameData = null;
      state.filteredJobs = null;
      state.allSavedJobData = null;
      state.allAppliedJobData = null;
      state.salaryCurrencyData = null;
      state.allJobCategoryData = null;
      state.allMyJobPostsData = null;
      state.isJobPostEditing = false;
      state.jobWeWillEditData = null;
      state.usersApplicationsData = null;
      state.userCvsData = null;
      state.userCvsDetailsData = null;
      state.CompanyEmployeeRangeData = null;
      state.companyProfileData = null;
      state.industriesData = null;
      state.specialtiesData = null;
      state.saudiArabiaCitiesData = null;
      state.companiesPartnerData = null;
      state.getRecentlyJobsData = null;
      state.companyDetailsData = null;

      localStorage.removeItem("token");
    },
    handleJobPostEditing: (state, action) => {
      state.isJobPostEditing = action.payload;
    },
    saveJobWillBeFilter: (state, action) => {
      state.jobWeWillEditData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // ===================== COM all Job Category Helper ========================
      // .addCase(allJobCategoryCOM.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(allJobCategoryCOM.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.allJobCategoryData = action.payload.data;
      //   state.error = null;
      // })
      // .addCase(allJobCategoryCOM.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // ===================== Post Job Helper =======================
      // .addCase(postJobHelper.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(postJobHelper.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.postJobHelperData = action.payload.data;
      //   state.error = null;
      // })
      // .addCase(postJobHelper.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // ===================== Get Salary Currency Helper ========================
      // .addCase(getSalaryCurrency.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(getSalaryCurrency.fulfilled, (state, action) => {
      //   state.loading = false;
      //   const filteredCurrency = action.payload?.data.filter(
      //     (item) => item.id == 1 || item.id == 187
      //   );
      //   state.salaryCurrencyData = filteredCurrency;
      //   state.error = null;
      // })
      // .addCase(getSalaryCurrency.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // ========================== Candidate ========================
      // ========================== Edit Candidate Profile ======================== 
      .addCase(editCandidateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCandidateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editCandidateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Get Category With Search ========================
      .addCase(getCategoryWithSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoryWithSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.categoriesNameData = action.payload.data;
        state.error = null;
      })
      .addCase(getCategoryWithSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Get Company Details Data ======================== 
      .addCase(getCompanyDetailsbyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyDetailsbyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.companyDetailsData = action.payload.data;
        state.error = null;
      })
      .addCase(getCompanyDetailsbyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Get All Helpers Jobs For Filter ========================
      // .addCase(getAllHelperJobs.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(getAllHelperJobs.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.filterHelpers = action.payload.data;
      //   state.error = null;
      // })
      // .addCase(getAllHelperJobs.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // ========================== Get All With Filter ========================
      .addCase(getAllWithFilter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllWithFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.filteredJobs = action.payload.data;
        state.error = null;
      })
      .addCase(getAllWithFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Get Job Details ========================
      .addCase(getJobsDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobsDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.jobDetailsData = action.payload.data;
        state.error = null;
      })
      .addCase(getJobsDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Save Job ========================
      .addCase(saveJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveJob.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== un Save Job ========================
      .addCase(unSaveJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unSaveJob.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(unSaveJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== all Saved Job ========================
      .addCase(allSavedJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(allSavedJob.fulfilled, (state, action) => {
        state.loading = false;
        state.allSavedJobData = action.payload.data;
        state.error = null;
      })
      .addCase(allSavedJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== View Job Counter ========================
      .addCase(ViewJobCounter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ViewJobCounter.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(ViewJobCounter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Apply Job ========================
      .addCase(ApplyJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ApplyJob.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(ApplyJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== UN Apply Job ========================
      .addCase(UnapplyJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UnapplyJob.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(UnapplyJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Get All Apply Job ========================
      .addCase(getAllApplyJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllApplyJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.allAppliedJobData = action.payload.data;
        state.error = null;
      })
      .addCase(getAllApplyJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Company ========================
      // ===================== Post New Job ========================
      .addCase(postNewJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postNewJob.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(postNewJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All my Job Posts ========================
      .addCase(getAllMyJobPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllMyJobPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.allMyJobPostsData = action.payload.data;
        state.error = null;
      })
      .addCase(getAllMyJobPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== DELETE Job Post ========================
      .addCase(deleteJobPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteJobPost.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteJobPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Edit Job Post ========================
      .addCase(editJobPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editJobPost.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editJobPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All User Applications ========================
      .addCase(getAllUserApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUserApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.usersApplicationsData = action.payload.data;
        state.error = null;
      })
      .addCase(getAllUserApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All User CVs ========================
      .addCase(getAllUserCVs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUserCVs.fulfilled, (state, action) => {
        state.loading = false;
        state.userCvsData = action.payload.data;
        state.error = null;
      })
      .addCase(getAllUserCVs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All User CVs Details========================
      .addCase(getCandidateCVDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCandidateCVDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userCvsDetailsData = action.payload.data;
        state.error = null;
      })
      .addCase(getCandidateCVDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Company Employee Range ========================
      .addCase(getCompanyEmployeeRange.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyEmployeeRange.fulfilled, (state, action) => {
        state.loading = false;
        state.CompanyEmployeeRangeData = action.payload.company_employees_range;
        state.error = null;
      })
      .addCase(getCompanyEmployeeRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Company Profile ========================
      .addCase(getCompanyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.companyProfileData = action.payload.data;
        state.error = null;
      })
      .addCase(getCompanyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Edit Company Profile ========================
      .addCase(editCompanyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCompanyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editCompanyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Industries Search ========================
      .addCase(getIndustriesSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIndustriesSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.industriesData = action.payload.data;
        state.error = null;
      })
      .addCase(getIndustriesSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get Specialties Search ======================== getSpecialtiesSearch
      .addCase(getSpecialtiesSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSpecialtiesSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.specialtiesData = action.payload.data;
        state.error = null;
      })
      .addCase(getSpecialtiesSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Saudi Arabia Cities ========================  
      .addCase(saudiArabiaCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saudiArabiaCities.fulfilled, (state, action) => {
        state.loading = false;
        state.saudiArabiaCitiesData = action.payload.data;
        state.error = null;
      })
      .addCase(saudiArabiaCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Companies Partner ========================  
      .addCase(companiesPartner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(companiesPartner.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.data)
        state.companiesPartnerData = action.payload.data;
        state.error = null;
      })
      .addCase(companiesPartner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Get All Recently Jobs ========================    
      .addCase(getRecentlyJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecentlyJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.getRecentlyJobsData = action.payload.data;
        state.error = null;
      })
      .addCase(getRecentlyJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ========================== Get Company Details Data ========================
      .addCase(getandidateProfileCompanyViewedData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getandidateProfileCompanyViewedData.fulfilled, (state, action) => {
        state.loading = false;
        state.companyViewedData = action.payload.data;
        state.error = null;
      })
      .addCase(getandidateProfileCompanyViewedData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});
export const { logoutSuccess, handleJobPostEditing, saveJobWillBeFilter } =
  jobsSlice.actions;
export default jobsSlice.reducer;
