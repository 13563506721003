import React from "react";
import { Container } from "react-bootstrap";
import CompanyProfileHeader from "./CompanyProfileHeader/CompanyProfileHeader";
import LeftHandSideCompany from "./LeftHandSideCompany";
import RightHandSideCompany from "./RightHandSideCompany";

function CompanyProfile() {
  return (
    <div>
      <CompanyProfileHeader />
      <Container>
        <div className="row pt-2 pt-md-5 py-2 m-auto ">
          <div className="col-12 col-md-8 order-lg-1 order-md-1 order-2">
            <LeftHandSideCompany />
          </div>
          <div className="  py-5 py-md-0 col-md-4 order-lg-2 order-md-2 order-1 ">
            <RightHandSideCompany />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CompanyProfile;
