import React, { forwardRef } from "react";
import inputStyles from "./styles.module.css";
import i18n from "../../i18n/i18n";

const InputField = forwardRef(
  (
    {
      label,
      placeholder,
      value,
      onChange,
      type,
      fieldName,
      style,
      inputStyle,
      isTextarea,
      rows,
      cols,
      rightIcon,
      onRightIconClick,
      isList,
      list,
      arrowStyle
    },
    ref
  ) => {
    return (
      <div className={`${inputStyles.fieldContainer} ${style}`}>
        {label && (
          <label htmlFor={fieldName} className={inputStyles.input_label}>
            {label}
          </label>
        )}
        {isTextarea ? (
          <textarea
            ref={ref} // Attach the ref here
            id={fieldName}
            name={fieldName}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`${inputStyles.input} ${inputStyle}`}
            rows={rows}
            cols={cols}
          ></textarea>
        ) : isList ? (
          <select
            ref={ref} // Attach the ref here
            className={`${inputStyles.input} ${arrowStyle} ${
              i18n.language === "en"
                ? inputStyles.list_input_right
                : inputStyles.list_input_left
            } ${inputStyle}`}
            value={value}
            onChange={onChange}
          >
            <option value="" className={`${inputStyles.list_placeholder}`}>
              {placeholder}
            </option>
            {list &&
              list.map((item) => (
                <option
                  key={item}
                  value={item}
                  className={inputStyles.list_option}
                >
                  {item}
                </option>
              ))}
          </select>
        ) : (
          <>
            {type === "file" ? (
              <div className={inputStyles.file_input}>
                <input
                  type={type}
                  id={fieldName}
                  style={{ display: "none" }}
                  onChange={onChange}
                  name={fieldName}
                />
                <div className="d-flex align-items-baseline mb-3">
                  <label htmlFor={fieldName} className={inputStyles.file_btn}>
                    browse
                  </label>
                  <p className={inputStyles.file_value}>
                    {value ? value : placeholder}
                  </p>
                </div>
              </div>
            ) : (
              <input
                ref={ref} // Attach the ref here
                id={fieldName}
                type={type}
                name={fieldName}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={`${inputStyles.input} ${inputStyle}`}
              />
            )}
          </>
        )}
        {rightIcon && (
          <div onClick={onRightIconClick} className={inputStyles.eye_box}>
            {rightIcon}
          </div>
        )}
      </div>
    );
  }
);

export default InputField;
