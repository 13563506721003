import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  AddIcon,
  CancelClose,
  GreenBoxWithoutVector,
  GreenBoxWithVector,
} from "../../assets";
import { Button, CheckBox, InputField } from "../../components";
//styles
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCities,
  getAllCountries,
  getAllCurrencies,
  getJobCareerLevels,
  getJobCategory,
  getJobContractTypes,
  getJobSalaryPer,
  getJobTypesForJobPost,
  getPostTypes,
  sendEmailNotifPers,
} from "../../redux/slices/helperSlice";
import {
  editJobPost,
  postNewJob,
  saveJobWillBeFilter,
} from "../../redux/slices/jobsSlice";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";

function JobLowPost() {
  const { state } = useLocation();
  const fromWhere = state?.from;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  // ==== SELECTORS ====
  const { companyInfo } = useSelector((state) => state.auth);
  const { loading, error, jobWeWillEditData } = useSelector(
    (state) => state.jobs
  );
  const {
    countries,
    cities,
    allCurrenciesData,
    allPostTypesData,
    jobTypesForJobPostData,
    jobContractTypeData,
    jobCareerLevelData,
    jobSalaryPerData,
    sendEmailNotificationData,
    jobCategoryData,
  } = useSelector((state) => state.helpers);
  // ==== STATES ====
  const [jobDetails, setJobDetails] = useState({
    postType: { value: t("Job"), code: null },
    category: { value: "", code: null },
    country: { value: "", code: null },
    city: { value: "", code: null },
    currency: { value: "", code: null },
    currencyPer: { value: "", code: null },
    title: "",
    minExperience: null,
    maxExperience: null,
    minSalary: null,
    maxSalary: null,
    careerLevel: { value: "", code: null },
    numberOfVacancy: null,
    description: null,
    requirements: null,
    jobTybe: { value: [], code: [] },
    contractType: { value: null, code: null },
    keywords: [],
    keepCompanyConfidintial: "",
    sendEmailsNotification: "",
    sendEmailsNotificationPer: { value: null, code: null },
    email: companyInfo?.business_email,
  });
  // Input Value For KeyWords
  const [inputValue, setInputValue] = useState("");
  // Handle Change Currency Action
  const [currSalaryChange, setCurrSalaryChange] = useState(false);
  //Change Email Address
  const [isEditing, setIsEditing] = useState(false);
  // Checked Hide Salary Box
  const [isChecked, setIsChecked] = useState(false);
  const [validateErrors, setValidateErrors] = useState({
    postType: "",
    jobTitle: "",
    jobCategory: "",
    jobType: "",
    contractType: "",
    country: "",
    city: "",
    careerLevel: "",
    experienceFrom: "",
    experienceto: "",
    salaryFrom: "",
    salaryto: "",
    vacancies: "",
    jobDescription: "",
    jobRequirement: "",
    keywords: "",
    jobOption: "",
  });
  console.log(validateErrors);
  const checkBoxHandler = isChecked
    ? GreenBoxWithVector
    : GreenBoxWithoutVector;
  //=======================================
  useEffect(() => {
    dispatch(getJobCategory());
    dispatch(getAllCountries());
    dispatch(getAllCurrencies());
    dispatch(getPostTypes());
    dispatch(getJobTypesForJobPost());
    dispatch(getJobContractTypes());
    dispatch(getJobCareerLevels());
    dispatch(getJobSalaryPer());
    dispatch(sendEmailNotifPers());
  }, [dispatch]);

  //=======================================
  const postJobHandler = (el) => {
    setJobDetails((prev) => ({
      ...prev,
      postType: {
        value: el?.default_name,
        code: el.code,
      },
    }));
  };
  const careerLevelHandler = (el) => {
    setJobDetails((prevState) => ({
      ...prevState,
      careerLevel: {
        value: el?.default_name, // Set the display name (or value)
        code: el.code, // Set the code
      },
    }));
  };
  const handleListsChange = (event, field, data) => {
    const selectedName = event.target.value;

    // Update the state for the field
    setJobDetails((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: selectedName,
        code: null, // Set the ID to null initially
      },
    }));

    const selectedItem = data?.find(
      (item) => item?.default_name === selectedName
    );

    if (selectedItem) {
      const selectedId =
        selectedItem.code ||
        selectedItem.currency_id ||
        selectedItem.category_id;
      // Now update the ID for the field
      setJobDetails((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          value: selectedName,
          code: selectedId,
        },
      }));
    }
  };
  const handleInputChange = (field) => (e) => {
    setJobDetails((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };
  const contractTypeHandler = (type) => {
    setJobDetails((prev) => {
      if (type === prev.contractType.value) {
        // Clear the selection if the same type is clicked again
        return {
          ...prev,
          contractType: { value: "", code: "" },
        };
      } else {
        // Set the selected contract type
        return {
          ...prev,
          contractType: { value: type?.default_name, code: type?.code },
        };
      }
    });
  };
  const typeOfJobHandler = (type) => {
    setJobDetails((prev) => {
      const updatedValue = prev.jobTybe.value.includes(type)
        ? prev.jobTybe.value.filter((type) => type !== type) // Remove from active if already selected
        : [...prev.jobTybe.value, type]; // Add to active if not selected

      const updatedCode = prev.jobTybe.code.includes(type?.code)
        ? prev.jobTybe.code.filter((code) => code !== type?.code) // Remove from active if already selected
        : [...prev.jobTybe.code, type?.code]; // Add to active if not selected
      return {
        ...prev,
        jobTybe: {
          value: updatedValue,
          code: updatedCode,
        },
      };
    });
  };
  const addKeyWordsHandler = () => {
    if (inputValue.trim()) {
      // Update the jobDetails? keywords array
      setJobDetails((prevState) => ({
        ...prevState,
        keywords: [...prevState.keywords, inputValue.trim()],
      }));
      setInputValue(""); // Clear input after adding
    }
  };
  const removeKeywordHandler = (keyword) => {
    setJobDetails((prevState) => ({
      ...prevState,
      keywords: prevState.keywords.filter((word) => word !== keyword),
    }));
  };
  const handleCheckboxChange = (option) => {
    setJobDetails((prevState) => {
      const updatedState = { ...prevState };

      if (option === "confidintial") {
        // When "Keep Company Confidential" is selected
        updatedState.keepCompanyConfidintial = true;
        updatedState.sendEmailsNotification = false;
        updatedState.sendEmailsNotificationPer = null; // Reset the notification period
      } else if (option === "notifications") {
        // When "Send Me Emails Notifications" is selected
        updatedState.keepCompanyConfidintial = false;
        updatedState.sendEmailsNotification = true;
      }
      return updatedState;
    });
  };
  const handleRadioChange = (e) => {
    const radioName = e.target.value;

    // Find the selected radio item
    const selectedRadio = sendEmailNotificationData.data.find(
      (rad) => rad?.default_name === radioName
    );

    if (selectedRadio) {
      const { code, default_name } = selectedRadio;

      setJobDetails((prevState) => ({
        ...prevState,
        sendEmailsNotificationPer: {
          value: default_name, // Update the selected value
          code: code, // Update the code
        },
      }));
    }
  };
  const handleEmailAddressChange = () => {
    setIsEditing(!isEditing);
  };
  const handleSaveEmail = () => {
    setIsEditing(false);
    // Save email to backend or state management if necessary
  };
  // ========================= Validation ============================
  const validate = () => {
    let errors = {
      postType: "",
      jobTitle: "",
      jobCategory: "",
      jobType: "",
      contractType: "",
      country: "",
      city: "",
      careerLevel: "",
      experienceFrom: "",
      experienceto: "",
      salaryFrom: "",
      salaryto: "",
      vacancies: "",
      jobDescription: "",
      jobRequirement: "",
      keywords: "",
      jobOption: "",
    };
    if (!jobDetails?.postType?.code) {
      errors.postType = "Post Type is required";
    }
    if (!jobDetails?.title) {
      errors.jobTitle = "Job Title is required";
    }
    if (!jobDetails?.category?.code) {
      errors.jobCategory = "Job Category is required";
    }
    if (!jobDetails?.jobTybe?.code || jobDetails?.jobTybe?.code.length === 0) {
      errors.jobType = "Job Type is required";
    }
    if (!jobDetails?.contractType?.code) {
      errors.contractType = "Contract Type is required";
    }
    if (!jobDetails?.country?.code) {
      errors.country = "Country is required";
    }
    if (!jobDetails?.city?.code) {
      errors.city = "City is required"; // If no languages are provided
    }
    if (!jobDetails?.careerLevel?.code) {
      errors.careerLevel = "Career Level are required";
    }
    if (!jobDetails?.minExperience) {
      errors.experienceFrom = "Minimum Experience is required";
    }
    if (!jobDetails?.maxExperience) {
      errors.experienceto = "Miximum Experience is required";
    }
    if (!jobDetails?.minSalary) {
      errors.salaryFrom = "Minimum Salary is required";
    }
    if (!jobDetails?.maxSalary) {
      errors.salaryto = "Miximum Experience is required";
    }
    if (!jobDetails?.numberOfVacancy) {
      errors.vacancies = "Number of vacancy is required";
    }
    if (!jobDetails?.description) {
      errors.jobDescription = "Job description is required";
    } else if (
      jobDetails.description.length < 100 ||
      jobDetails.description.length > 1024
    ) {
      errors.jobDescription =
        "The job description field must be between 100 and 1024 characters";
    }

    if (!jobDetails?.requirements) {
      errors.jobRequirement = "Job requirements is required";
    } else if (
      jobDetails?.requirements.length < 100 ||
      jobDetails?.requirements.length > 1024
    ) {
      errors.jobRequirement =
        "The job requirements field must be between 100 and 1024 characters";
    }
    if (!jobDetails?.keywords || jobDetails?.keywords.length === 0) {
      errors.keywords = "Keywords are required"; // If no skills are provided
    } else if (jobDetails?.keywords.length < 2) {
      errors.keywords = "Please add at least two keywords"; // If less than two skills are provided
    }
    if (
      !jobDetails?.keepCompanyConfidintial &&
      !jobDetails?.sendEmailsNotification
    ) {
      errors.jobOption = "Job option is required";
    }

    setValidateErrors(errors);
    return Object.values(errors).every((error) => !error);
  };
  // Submit Handler
  const submitHandler = () => {
    if (!validate()) return;

    const jobPostData = {
      code: fromWhere === "edit" && jobWeWillEditData?.code,
      post_type: jobDetails?.postType?.code,
      title: jobDetails?.title,
      category: jobDetails?.category?.code,
      job_types: jobDetails?.jobTybe?.code,
      contract_type: jobDetails?.contractType?.code,
      country: jobDetails?.country?.code,
      city: jobDetails?.city?.code,
      career_level: jobDetails?.careerLevel?.code,
      experience_from: Number(jobDetails?.minExperience),
      experience_to: Number(jobDetails?.maxExperience),
      salary_from: Number(jobDetails?.minSalary),
      salary_to: Number(jobDetails?.maxSalary),
      salary_currency: jobDetails?.currency?.code
        ? jobDetails?.currency?.code
        : 1,
      salary_per: jobDetails?.currencyPer?.code
        ? jobDetails?.currencyPer?.code
        : 5,
      salary_hide: isChecked ? "yes" : "no",
      number_of_vacancies: Number(jobDetails?.numberOfVacancy),
      job_description: jobDetails?.description,
      job_requirements: jobDetails?.requirements,
      keywords: jobDetails?.keywords,
      keep_company_confidintial: jobDetails?.keepCompanyConfidintial
        ? "yes"
        : "no",
      send_emails_notification: jobDetails?.sendEmailsNotification
        ? "yes"
        : "no",
      send_emails_notification_per: jobDetails?.sendEmailsNotificationPer?.code,
      send_emails_notification_to: jobDetails?.email,
      is_high_job: "no",
    };
    if (fromWhere === "edit") {
      dispatch(editJobPost(jobPostData))
        .unwrap()
        .then(() => {
          dispatch(saveJobWillBeFilter(null));
          window.location.href = '/welcomeEmployee'
        })
        .catch(() => {});
    } else {
      dispatch(postNewJob(jobPostData))
        .unwrap()
        .then(() => {
          navigate("/welcomeEmployee");
        })
        .catch(() => {});
    }
    // console.log(jobDetails);

    // console.log(jobPostData);
  };

  useEffect(() => {
    if (jobWeWillEditData && fromWhere === "edit") {
      setJobDetails((prev) => ({
        ...prev,
        postType: {
          value: jobWeWillEditData?.post_type?.default_name,
          code: jobWeWillEditData?.post_type?.code,
        },
        category: {
          value: jobWeWillEditData?.category?.default_name,
          code: jobWeWillEditData?.category?.code,
        },
        country: {
          value: jobWeWillEditData?.country?.default_name,
          code: jobWeWillEditData?.country?.code,
        },
        city: {
          value: jobWeWillEditData?.city?.default_name,
          code: jobWeWillEditData?.city?.code,
        },
        currency: {
          value: jobWeWillEditData?.salary_currency?.default_name,
          code: jobWeWillEditData?.salary_currency?.code,
        },
        currencyPer: {
          value: jobWeWillEditData?.salary_per?.default_name,
          code: jobWeWillEditData?.salary_per?.code,
        },
        careerLevel: {
          value: jobWeWillEditData?.career_level?.default_name,
          code: jobWeWillEditData?.career_level?.code,
        },
        title: jobWeWillEditData?.title,
        minExperience: jobWeWillEditData?.experience_from,
        maxExperience: jobWeWillEditData?.experience_to,
        minSalary: jobWeWillEditData?.salary_from,
        maxSalary: jobWeWillEditData?.salary_to,
        numberOfVacancy: jobWeWillEditData?.number_of_vacancies,
        description: jobWeWillEditData?.job_description,
        requirements: jobWeWillEditData?.job_requirements,
        jobTybe: {
          value: jobWeWillEditData?.job_types,
          code: jobWeWillEditData?.job_types?.map((type) => type.code),
        },
        contractType: {
          value: jobWeWillEditData?.contract_type?.default_name,
          code: jobWeWillEditData?.contract_type?.code,
        },
        keywords: jobWeWillEditData?.keywords,
        keepCompanyConfidintial: jobWeWillEditData?.keep_company_confidintial,
        sendEmailsNotification: jobWeWillEditData?.send_emails_notification,
        sendEmailsNotificationPer: {
          value: jobWeWillEditData?.send_emails_notification_per?.default_name,
          code: jobWeWillEditData?.send_emails_notification_per?.code,
        },
        email: jobWeWillEditData?.send_emails_notification_to,
      }));
      setIsChecked(jobWeWillEditData?.salary_hide);
    }
  }, [jobWeWillEditData, fromWhere]);
  useEffect(() => {
    dispatch(getAllCities(jobDetails?.country?.code));
  }, [dispatch, jobDetails, jobWeWillEditData, fromWhere]);
  return (
    <main className={`${styles.register_steps} py-5`}>
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-column align-items-center mb-5">
          <h3 className={`${styles.form_page_title} mb-3`}>
            {fromWhere === "edit" ? "Edit Post Job" : t("PostNewJob")}
          </h3>
          <p className="text-center">
            {fromWhere === "edit"
              ? "Create A New Job Post"
              : "Create a Low Job Post"}
          </p>
        </div>
        {/*Start Form */}
        <div className={`${styles.form_content}`}>
          <Container className="d-flex flex-column">
            {/*Start First Section*/}
            <section className="mb-5">
              <div className="mb-4 ms-3">
                <h6>{t("PostType")}</h6>
              </div>
              <div className={`row  d-flex   container`}>
                {allPostTypesData?.data?.map((el) => (
                  <button
                    onClick={() => postJobHandler(el)}
                    key={el.code}
                    className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${
                      styles.current_level_result
                    } ${
                      jobDetails?.postType?.code === el.code
                        ? styles.active
                        : ""
                    }`}
                  >
                    {el?.default_name}
                  </button>
                ))}
              </div>
              {validateErrors.postType && (
                <p className="text-danger">{validateErrors.postType}</p>
              )}
            </section>
            {/*End First Section*/}
            {/*Start Second Section*/}
            <section className={`${styles.background_forms}  my-5 container`}>
              <div className="mb-4">
                <h6>
                  {jobDetails?.postType?.value} {t("Details")}
                </h6>
              </div>
              <div className="my-3 mb-5">
                <p className="mb-2">
                  {jobDetails?.postType?.value} {t("Title")}
                </p>
                <InputField
                  placeholder={t("Select")}
                  fieldName="options"
                  style={styles.text_component_style}
                  value={jobDetails?.title}
                  onChange={handleInputChange("title")}
                />
                {validateErrors.jobTitle && (
                  <p className="text-danger">{validateErrors.jobTitle}</p>
                )}
              </div>

              <div className="my-3">
                <p className="mb-2">
                  {jobDetails?.postType?.value} {t("Category")}
                </p>
                <InputField
                  placeholder={t("Select")}
                  fieldName="options"
                  value={
                    fromWhere === "edit"
                      ? jobWeWillEditData?.category?.default_name
                      : jobDetails?.category?.value
                  }
                  onChange={(e) =>
                    handleListsChange(e, "category", jobCategoryData?.data)
                  }
                  isList={true}
                  list={jobCategoryData?.data?.map((cat) => cat?.default_name)}
                  style={styles.select_component_style}
                  inputStyle={styles.option_component_style}
                  arrowStyle={styles.arrow_style}
                />
                {validateErrors.jobCategory && (
                  <p className="text-danger">{validateErrors.jobCategory}</p>
                )}
              </div>
            </section>
            {/*End Second Section*/}
            {/*Start Third Section*/}
            <section className={`${styles.background_forms}  my-5 container`}>
              <div className="mb-5 ">
                <h6>
                  {jobDetails?.postType?.value} {t("Type")}
                </h6>
              </div>
              <div
                className={`row d-flex mb-3 flex-column flex-md-row align-items-center align-items-md-start`}
              >
                {jobDetails?.jobTybe?.value?.map((el) => (
                  <button
                    key={el.code}
                    className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow ${styles.btn_hover} ${styles.job_type}`}
                    onClick={() => typeOfJobHandler(el)}
                  >
                    {el?.default_name}
                    <img src={AddIcon} alt="Add Icon" />
                  </button>
                ))}
              </div>
              {jobDetails?.jobTybe?.value?.length !== 0 && <hr />}
              <div
                className={`row d-flex flex-column flex-md-row align-items-center align-items-md-start`}
              >
                {jobTypesForJobPostData?.data
                  ?.filter(
                    (el) =>
                      !jobDetails?.jobTybe?.value.some(
                        (jobType) => jobType.code === el.code
                      )
                  )
                  ?.map((el) => (
                    <button
                      key={el.code}
                      className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow pe-4 ${styles.btn_hover} ${styles.job_type}`}
                      onClick={() => typeOfJobHandler(el)}
                    >
                      {el?.default_name}
                      <img src={AddIcon} alt="Add Icon" />
                    </button>
                  ))}
              </div>
              {validateErrors.jobType && (
                <p className="text-danger">{validateErrors.jobType}</p>
              )}
            </section>
            {/*End Third Section*/}
            {/*Start Forth Section*/}
            <section className={`${styles.background_forms} my-5 container`}>
              <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center">
                <h6>
                  {t("WhatTypeOf")} {jobDetails?.postType?.value}{" "}
                  {t("AreYouOpenTo")}
                </h6>
              </div>

              {/* Selected Contract Type */}
              <div className="row d-flex flex-column flex-md-row align-items-center align-items-md-start">
                {jobDetails?.contractType?.value && (
                  <button
                    key={jobDetails?.contractType?.value.code}
                    className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow ${styles.btn_hover} ${styles.workspace_setting}`}
                    //onClick={() => contractTypeHandler(null)}
                  >
                    {jobDetails?.contractType?.value}
                  </button>
                )}
              </div>

              {jobDetails?.contractType?.value && <hr />}

              {/* Unselected Contract Types */}
              <div className="row d-flex flex-column flex-md-row align-items-center align-items-md-start">
                {jobContractTypeData?.data
                  ?.filter((el) => el.code !== jobDetails?.contractType.code) // Exclude selected contract type by comparing `code`
                  ?.map((el) => (
                    <button
                      key={el.code}
                      className={`col-6 col-sm-4 col-md-3 col-lg-2 shadow pe-4 ${styles.btn_hover} ${styles.job_type}`}
                      onClick={() => contractTypeHandler(el)}
                    >
                      {el?.default_name}
                      <img src={AddIcon} alt="Add Icon" />
                    </button>
                  ))}
              </div>
              {validateErrors.contractType && (
                <p className="text-danger">{validateErrors.contractType}</p>
              )}
            </section>

            {/*End Forth Section*/}
            {/*Start Fifth Section*/}
            <section
              className={`${styles.background_forms} py-5 my-5 container`}
            >
              <h6 className="pb-4">
                {jobDetails?.contractType?.value?.default_name === "Remotly"
                  ? "Company Location"
                  : t("YourLocation")}
              </h6>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("Country")}
                    placeholder={t("ChooseAnOption")}
                    value={jobDetails?.country.value}
                    onChange={(e) =>
                      handleListsChange(e, "country", countries.data)
                    }
                    arrowStyle={styles.arrow_style}
                    isList={true}
                    list={countries?.data?.map(
                      (country) => country?.default_name
                    )}
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                  {validateErrors.country && (
                    <p className="text-danger">{validateErrors.country}</p>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 col-12">
                  <InputField
                    label={t("City")}
                    placeholder={t("ChooseAnOption")}
                    value={jobDetails?.city.value}
                    onChange={(e) => handleListsChange(e, "city", cities.data)}
                    isList={true}
                    arrowStyle={styles.arrow_style}
                    list={cities?.data?.map((city) => city?.default_name)}
                    style={styles.select_component_style}
                    inputStyle={styles.option_component_style}
                  />
                  {validateErrors.city && (
                    <p className="text-danger">{validateErrors.city}</p>
                  )}
                </div>
              </div>
            </section>
            {/*End Fifth Section*/}
            {/*Start Sixth Section*/}
            <section className="mb-5">
              <div className="mb-4">
                <h6>{t("CareerLevel")}</h6>
              </div>
              <div className={`row  d-flex justify-content-between  container`}>
                {jobCareerLevelData?.data?.map((el) => (
                  <button
                    key={el.code}
                    className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${
                      styles.current_level_result
                    } ${
                      jobDetails?.careerLevel.code === el.code
                        ? styles.active
                        : ""
                    }`}
                    onClick={() => careerLevelHandler(el)}
                  >
                    {el?.default_name}
                  </button>
                ))}
              </div>
              {validateErrors.careerLevel && (
                <p className="text-danger">{validateErrors.careerLevel}</p>
              )}
            </section>
            {/*End Sixth Section*/}
            {/*Start Seven Section*/}
            <section className={`${styles.background_forms}  mt-5 container`}>
              <div>
                <div className="d-flex flex-column flex-sm-row gap-3 align-items-start align-items-md-center ">
                  <h6>{t("YearsOfExperience")}</h6>
                </div>
                <div className="mt-3 d-flex flex-column flex-md-row align-items-start align-items-md-center gap-0 gap-md-3">
                  <div>
                    <InputField
                      placeholder={t("Min")}
                      fieldName="options"
                      style={styles.salary_range_component_style}
                      value={jobDetails?.minExperience}
                      onChange={handleInputChange("minExperience")}
                    />
                  </div>
                  <p className="fw-bold">{t("To")}</p>
                  <InputField
                    placeholder={t("Max")}
                    fieldName="options"
                    style={styles.salary_range_component_style}
                    value={jobDetails?.maxExperience}
                    onChange={handleInputChange("maxExperience")}
                  />
                </div>
                {validateErrors.experienceFrom && (
                  <p className="text-danger">{validateErrors.experienceFrom}</p>
                )}
                {validateErrors.experienceto && (
                  <p className="text-danger">{validateErrors.experienceto}</p>
                )}
              </div>
              <div className="mt-4">
                <div className="d-flex flex-column flex-sm-row gap-3 align-items-start align-items-md-center ">
                  <h6>{t("SalaryRange")}</h6>
                </div>
                <div className="mt-3 d-flex flex-column flex-md-row align-items-start align-items-md-center gap-0 gap-md-3">
                  <div>
                    <InputField
                      placeholder={t("eg8000")}
                      fieldName="options"
                      style={styles.salary_range_component_style}
                      onChange={handleInputChange("minSalary")}
                      value={jobDetails?.minSalary}
                    />
                  </div>
                  <p className="mb-3 fw-bold">{t("To")}</p>
                  <div>
                    <InputField
                      placeholder={t("eg1000")}
                      fieldName="options"
                      style={styles.salary_range_component_style}
                      onChange={handleInputChange("maxSalary")}
                      value={jobDetails?.maxSalary}
                    />
                  </div>
                  <div
                    className={`d-flex align-items-center align-items-md-enter gap-2 ${styles.sar_change}`}
                  >
                    {!currSalaryChange ? (
                      <div>
                        <p className="mb-3">
                          {jobDetails?.currency.value
                            ? jobDetails?.currency.value
                            : "SAR"}{" "}
                          /{" "}
                          {jobDetails?.currencyPer.value
                            ? jobDetails?.currencyPer.value
                            : "Month"}
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex align-items-start align-items-md-enter gap-2">
                        <InputField
                          placeholder={t("Select")}
                          fieldName="options"
                          style={styles.change_currency_style}
                          inputStyle={styles.option_component_style}
                          isList={true}
                          arrowStyle={styles.arrow_style}
                          list={allCurrenciesData?.data?.map(
                            (curr) => curr?.default_name
                          )}
                          onChange={(e) =>
                            handleListsChange(
                              e,
                              "currency",
                              allCurrenciesData?.data
                            )
                          }
                          value={jobDetails?.currency.value}
                        />
                        <p className="pt-2">/</p>
                        <InputField
                          placeholder={t("Select")}
                          fieldName="options"
                          style={styles.change_currency_style}
                          inputStyle={styles.option_component_style}
                          isList={true}
                          arrowStyle={styles.arrow_style}
                          list={jobSalaryPerData?.data?.map(
                            (per) => per?.default_name
                          )}
                          onChange={(e) =>
                            handleListsChange(
                              e,
                              "currencyPer",
                              jobSalaryPerData?.data
                            )
                          }
                          value={jobDetails?.currencyPer.value}
                        />
                      </div>
                    )}

                    {/* */}

                    <div className={!currSalaryChange ? "mb-3" : "mb-4"}>
                      <span
                        onClick={() => setCurrSalaryChange(!currSalaryChange)}
                      >
                        {!currSalaryChange ? t("Change") : t("Save")}
                      </span>
                    </div>
                  </div>
                </div>
                {validateErrors.salaryFrom && (
                  <p className="text-danger">{validateErrors.salaryFrom}</p>
                )}
                {validateErrors.salaryto && (
                  <p className="text-danger">{validateErrors.salaryto}</p>
                )}
              </div>

              {/*Check Box Hide Salary */}
              <div className="d-flex align-items-center my-4">
                <input
                  type="checkbox"
                  id="hide_salary"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  style={{ display: "none" }} // Hide the checkbox
                />
                <img
                  id="hide_salary"
                  src={checkBoxHandler}
                  onClick={() => setIsChecked(!isChecked)}
                  className={`${i18n.language === "en" ? "me-2" : "ms-2"} ${
                    styles.check_box_salary
                  }`}
                  alt="checkbox"
                />
                <label htmlFor="hide_salary" className="fw-500">
                  {t("HideSalary")}
                </label>
              </div>
            </section>
            {/*End Seven Section*/}
            {/*Start eight Section*/}
            <section className={`${styles.background_forms}  mt-5 container`}>
              <div>
                <div className="d-flex flex-column flex-sm-row gap-3 align-items-start align-items-md-center ">
                  <h6>{t("NumberOfVacancies")}</h6>
                </div>
                <div className="mt-3 d-flex flex-column flex-md-row align-items-start align-items-md-center gap-0 gap-md-3">
                  <div>
                    <InputField
                      placeholder={t("Number")}
                      fieldName="options"
                      style={styles.salary_range_component_style}
                      value={jobDetails?.numberOfVacancy}
                      onChange={handleInputChange("numberOfVacancy")}
                    />
                    {validateErrors.vacancies && (
                      <p className="text-danger">{validateErrors.vacancies}</p>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/*End eight Section*/}
            {/*Start nine Section*/}
            <section className={`${styles.background_forms}  mt-5 container`}>
              <div>
                <div className="mb-4 d-flex flex-column flex-sm-row gap-3 align-items-start align-items-md-center ">
                  <h6>
                    {t("About")} {jobDetails?.postType?.value}
                  </h6>
                </div>
                <div className="row mb-3">
                  <div className={` col-12 ${styles.text_area_container}`}>
                    <p>
                      {jobDetails?.postType?.value} {t("Description")}
                    </p>
                    <InputField
                      placeholder={t("TypeHere")}
                      style={styles.text_area_component_style}
                      isTextarea={true}
                      rows={5}
                      cols={50}
                      value={jobDetails?.description}
                      description
                      requirements
                      onChange={handleInputChange("description")}
                    />
                    {validateErrors.jobDescription && (
                      <p className="text-danger">
                        {validateErrors.jobDescription}
                      </p>
                    )}
                  </div>
                  <div className={` col-12 ${styles.text_area_container}`}>
                    <p>
                      {jobDetails?.postType?.value} {t("Requirements")}
                    </p>
                    <InputField
                      placeholder={t("TypeHere")}
                      style={styles.text_area_component_style}
                      isTextarea={true}
                      rows={5}
                      cols={50}
                      value={jobDetails?.requirements}
                      onChange={handleInputChange("requirements")}
                    />
                    {validateErrors.jobRequirement && (
                      <p className="text-danger">
                        {validateErrors.jobRequirement}
                      </p>
                    )}
                  </div>
                  <div className={` col-12 ${styles.text_area_container}`}>
                    <div className="mb-3 mt-5 d-flex flex-column  gap-2 align-items-start ">
                      <p>{t("Keywords")}</p>
                      <span>
                        {t("KeywordsDescreptionPart1")}{" "}
                        {jobDetails?.postType?.value}{" "}
                        {t("KeywordsDescreptionPart2")}
                      </span>
                    </div>
                    <div className="row mb-3 d-flex align-items-center">
                      <div className="col-md-10 col-12">
                        <InputField
                          placeholder={t("TypeHere")}
                          fieldName="options"
                          value={inputValue}
                          style={`${styles.text_component_style}`}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2 col-12">
                        <Button
                          title={t("Add")}
                          style={`shadow px-5 py-2 mb-3 ${styles.green_btn}`}
                          onClick={addKeyWordsHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 gap-md-3 mt-md-0 mt-3">
                    {jobDetails?.keywords?.map((keyword, index) => (
                      <div
                        className={`shadow py-2 ${styles.results_style}`}
                        key={index}
                      >
                        <p>{keyword}</p>
                        <div>
                          <img
                            alt={"Delete"}
                            src={CancelClose}
                            className={styles.results_exit_icon}
                            onClick={() => removeKeywordHandler(keyword)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {validateErrors.keywords && (
                  <p className="text-danger">{validateErrors.keywords}</p>
                )}
              </div>
            </section>
            {/*End nine Section*/}
            {/*Start 10 Section*/}
            <section className={`${styles.background_forms}  mt-5 container `}>
              <div>
                <div className="mb-4  d-flex flex-column flex-sm-row gap-3 align-items-start align-items-md-center ">
                  <h6>
                    {jobDetails?.postType?.value} {t("Option")}
                  </h6>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-start gap-4">
                  <div className="d-flex flex-column">
                    <CheckBox
                      label={t("KeepCompanyConfidential")}
                      option="confidintial"
                      value="confidintial"
                      onChange={handleCheckboxChange}
                      selectedOption={
                        jobDetails?.keepCompanyConfidintial
                          ? "confidintial"
                          : null
                      }
                      imgStyle={styles.checkbox_style}
                    />
                    <span className="ms-4">
                      {t("HideCompanyNameLogoAndProfile")}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start align-items-md-center flex-md-row gap-3 gap-md-4">
                    <CheckBox
                      label={t("SendMeEmailsNotifications")}
                      option="notifications"
                      value="notifications"
                      onChange={handleCheckboxChange}
                      selectedOption={
                        jobDetails?.sendEmailsNotification
                          ? "notifications"
                          : null
                      }
                      imgStyle={styles.checkbox_style}
                    />
                    {jobDetails?.sendEmailsNotification && (
                      <div className="d-flex flex-row align-items-center mb-2 ms-4 gap-3">
                        {sendEmailNotificationData?.data?.map((el) => (
                          <div
                            className="d-flex gap-2 flex-row align-items-center"
                            key={el.code}
                          >
                            <input
                              id={el?.default_name}
                              type="radio"
                              name="frequency"
                              value={el?.default_name}
                              checked={
                                jobDetails?.sendEmailsNotificationPer?.value ===
                                el?.default_name
                              }
                              onChange={handleRadioChange}
                            />
                            <label htmlFor={el?.default_name}>
                              {el?.default_name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {validateErrors.jobOption && (
                    <p className="text-danger">{validateErrors.jobOption}</p>
                  )}
                  <div className={styles.recipient_email}>
                    <h6>{t("RecipientEmail")}</h6>
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-start gap-3">
                      {isEditing ? (
                        <>
                          <input
                            type="email"
                            value={jobDetails?.email}
                            onChange={handleInputChange("email")}
                            className="form-control"
                          />
                          <Button
                            title={t("Save")}
                            onClick={handleSaveEmail}
                            style={`py-1 px-3`}
                          />
                        </>
                      ) : (
                        <>
                          <span>{jobDetails?.email}</span>
                          <p onClick={handleEmailAddressChange}>
                            {t("ChangeEmailAddress")}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*End 10 Section*/}
          </Container>
        </div>
        {/*End Form */}
        <div>{error && <p className="text-danger">{error}</p>}</div>
        <div className={`d-flex flex-column flex-sm-row align-items-center`}>
          <Button
            // to={"/welcomeEmployee"}
            onClick={submitHandler}
            title={fromWhere === "edit" ? "Edit" : t("PostNow")}
            style={`my-4  shadow ${styles.save_continue_btn} ${styles.green_btn}`}
            loading={loading}
          />
        </div>
      </div>
    </main>
  );
}

export default JobLowPost;
