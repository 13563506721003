import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Container } from "react-bootstrap";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmationModal,
  signUpFourCorporate,
  signUpThreeCorporate,
  signUpTwoCorporate,
} from "../../redux/slices/authSlice";
import {
  getAllCountries,
  getAllCurrencies,
  getAllGenders,
  getCareerLevels,
  getJobTypes,
  getSalaryPer,
  getWorkSpaceSetting,
  getExperienceYears,
  getEducationalLevels,
  getFieldsOfStudy,
  getUniversities,
  getGrades,
  getAllSkills,
  getAllLanguages,
  getLanguagesLevels
} from "../../redux/slices/helperSlice";
import { t } from "i18next";

function RegisterSteps() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loading } = useSelector((state) => state.auth);
  const [activeStep, setActiveStep] = useState(1);
  // Define state to store data for each step
  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [stepThreeData, setStepThreeData] = useState({});

  const handleNextStep = () => {
    if (activeStep < 3) {
      setActiveStep((prevStep) => prevStep + 1);
    }
    // This Step To open Modal
    if (activeStep <= 3) {
      // Consolidate all data
      //Step 1
      if (activeStep == 1) {
        dispatch(signUpTwoCorporate(stepOneData))
          .unwrap()
          .then(() => {
            if (activeStep < 3) {
              setActiveStep((prevStep) => prevStep + 1);
            }
            window.scrollTo(0, 0);
          })
          .catch(() => {});
      }
      //Step 2
      if (activeStep == 2) {
        dispatch(signUpThreeCorporate(stepTwoData))
          .unwrap()
          .then(() => {
            if (activeStep < 3) {
              setActiveStep((prevStep) => prevStep + 1);
            }
            window.scrollTo(0, 0);
          })
          .catch(() => {});
      }
      //Step 3
      if (activeStep == 3) {
        dispatch(signUpFourCorporate(stepThreeData))
          .unwrap()
          .then(() => {
            if (activeStep < 3) {
              setActiveStep((prevStep) => prevStep + 1);
            }
            navigate("/companyLandingPage");
            dispatch(openConfirmationModal(3));
            window.scrollTo(0, 0);
          })
          .catch(() => {});
      }

      const registerData = {
        ...stepOneData,
        ...stepTwoData,
        ...stepThreeData,
      };
      console.log("Register Data:", registerData);
      dispatch(openConfirmationModal(3));
    }
  };

  const handleBackStep = () => {
    if (activeStep > 1) {
      setActiveStep((prevStep) => prevStep - 1);
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(getCareerLevels());
    dispatch(getJobTypes());
    dispatch(getWorkSpaceSetting());
    dispatch(getSalaryPer());
    dispatch(getAllCurrencies());
    dispatch(getAllGenders());
    dispatch(getAllCountries());
    dispatch(getExperienceYears());
    dispatch(getEducationalLevels());
    dispatch(getFieldsOfStudy());
    dispatch(getUniversities());
    dispatch(getGrades());
    dispatch(getAllSkills());
    dispatch(getAllLanguages());
    dispatch(getLanguagesLevels());
  }, [dispatch]);

  return (
    <div className={`${styles.register_steps} `}>
      <Container className="pt-5">
        {/* Start Step Indicator */}
        <div className={`mb-5 ${styles.steps_indicators}`}>
          <div className={`me-3 ${styles.steps_indicator_box}`}>
            <span
              className={
                activeStep === 1 || activeStep === 2 || activeStep === 3
                  ? styles.active
                  : ""
              }
            >
              1
            </span>
            <p className="fw-500 text-nowrap">{t("CareerInterests")}</p>
          </div>
          <div className={styles.steps_indicators_line}></div>

          <div className={`mx-3 ${styles.steps_indicator_box}`}>
            <span
              className={
                activeStep === 2 || activeStep === 3 ? styles.active : ""
              }
            >
              2
            </span>
            <p className="fw-500 text-nowrap">{t("GeneralInfo")}</p>
          </div>
          <div className={styles.steps_indicators_line}></div>

          <div className={`ms-3 ${styles.steps_indicator_box}`}>
            <span className={activeStep === 3 ? styles.active : ""}>3</span>
            <p className="fw-500 text-nowrap">{t("ProfessionalInfo")}</p>
          </div>
        </div>
        {/* End Step Indicator */}
        <div className={`d-flex flex-column align-items-center`}>
          <div className="text-center mt-5 mb-3">
            <p>
              {t("Step")} <span className="ms-2">{activeStep}/3</span>
            </p>
          </div>
          {/* Start Steps Components */}
          <div>
            {activeStep === 1 && <StepOne onStepDataChange={setStepOneData} />}
            {activeStep === 2 && <StepTwo onStepDataChange={setStepTwoData} />}
            {activeStep === 3 && (
              <StepThree onStepDataChange={setStepThreeData} />
            )}
          </div>
          <div>{error && <p className="text-danger">{error}</p>}</div>
          {/* End Steps Components */}
          <div className={` ${activeStep === 1 ? "" : "w-100"}`}>
            <div
              className={`d-flex flex-column flex-sm-row align-items-center  ${
                activeStep === 2
                  ? "justify-content-around"
                  : "justify-content-between"
              }        `}
            >
              {activeStep > 1 && (
                <Button
                  loading={loading}
                  onClick={handleBackStep}
                  title={t("Back")}
                  style={`my-4  shadow ${styles.save_continue_btn}`}
                />
              )}

              {activeStep <= 3 && (
                <Button
                  loading={loading}
                  onClick={handleNextStep}
                  title={t("SaveAndContinue")}
                  style={`my-4  shadow ${styles.save_continue_btn}`}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default RegisterSteps;