/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { t } from "i18next";
import { Button, InputField } from "../../../components";
import { CancelClose } from "../../../assets";
import { useSelector } from "react-redux";

function StepTwo({ onStepDataChange }) {
  const {
    fieldsOfStudyData,
    universitiesData,
    gradesData,
    DegreesData,
    educationalLevelsData,
  } = useSelector((state) => state.helpers);
  const [stepTwo, setStepTwo] = useState({
    educationLevel: "",
    university: { value: "", code: "" },
    degree: { value: "", code: "" },
    grade: { value: "", code: "" },
  });
  //Fields of Study
  const [studyField, setStudyField] = useState("");
  const [fieldsData, setFieldsData] = useState([]);
  const [fieldsCode, setFieldsCode] = useState([]);
  const currentCareerHandler = (e, el) => {
    e.preventDefault();
    setStepTwo((prev) => ({
      ...prev,
      educationLevel: el.code,
    }));
  };
  const handleFieldOfStudyChange = (event) => {
    const selectedField = fieldsOfStudyData?.data?.find(
      (field) => field.default_name === event.target.value
    );
    setStudyField(selectedField);
  };
  const handleAddFieldStudy = (e) => {
    e.preventDefault();
    if (studyField) {
      const { default_name, code } = studyField;

      // Check if the field is already added
      if (fieldsData.includes(default_name)) {
        alert("This field is already added.");
        return;
      }

      // Add the field name and code to their respective states
      setFieldsData([...fieldsData, default_name]);
      setFieldsCode([...fieldsCode, code]);

      // Clear the selected field
      setStudyField(null);
    } else {
      alert("Please select a Field of Study.");
    }
  };
  const handleRemoveFields = (codeToRemove) => {
    // Find the index of the code to remove
    const indexToRemove = fieldsCode.indexOf(codeToRemove);

    if (indexToRemove > -1) {
      // Remove the corresponding name and code
      setFieldsData(fieldsData.filter((_, index) => index !== indexToRemove));
      setFieldsCode(fieldsCode.filter((_, index) => index !== indexToRemove));
    }
  };
  const handleListsChange = (event, field, data) => {
    const selectedName = event.target.value;

    // Update the state for the field
    setStepTwo((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: selectedName,
        code: null, // Set the ID to null initially
      },
    }));

    const selectedItem = data?.find(
      (item) => item?.default_name === selectedName
    );

    if (selectedItem) {
      const selectedId =
        selectedItem.code ||
        selectedItem.currency_id ||
        selectedItem.category_id;
      // Now update the ID for the field
      setStepTwo((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          value: selectedName,
          code: selectedId,
        },
      }));
    }
  };
  useEffect(() => {
    // if (!validate()) return;
    const stepTwoData = {
      education_level: stepTwo?.educationLevel,
      fields: fieldsCode,
      university: stepTwo?.university?.code,
      grade: stepTwo?.grade?.code,
      degree: stepTwo?.degree?.code,
    };
    console.log(stepTwoData);
    onStepDataChange(stepTwoData);
  }, [
    onStepDataChange,
    JSON.stringify(stepTwo),
    fieldsCode
  ]);

  return (
    <main
      className={` flex-column d-flex justify-content-center position-relative`}
    >
      <div
        className={`d-flex flex-column align-items-center py-4 mt-2 ${styles.page_header}`}
      >
        <p>{t("MainStep")}</p>
        <p>
          Tell Us About Your <span>Education</span>
        </p>
      </div>
      {/*Start Forms */}
      <form>
        {/*============================= Start Section 1 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <div className="mb-5 d-flex flex-column flex-sm-row gap-3 align-items-center">
            <h6 className="fw-700">What is your current educational level?</h6>
            <span>
              — If you are currently studying, select your expected degree.
            </span>
          </div>
          <div className={`row d-flex justify-content-between container`}>
            {educationalLevelsData?.data?.map((el, index) => (
              <button
                onClick={(e) => currentCareerHandler(e, el)}
                key={el.code}
                className={`col-5 col-sm-3 shadow ${styles.btn_hover} ${styles.current_level_result
                  } ${stepTwo?.educationLevel === el.code ? styles.active : ""}`}
              >
                {el.default_name}
              </button>
            ))}
          </div>
        </section>
        {/*============================= End Section 1 ============================ */}
        {/*============================= Start Section 2 ============================ */}
        <section className={`${styles.background_forms} p-5 mb-4 container`}>
          <h6 className="pb-4">{t("DegreeDetails")}</h6>
          <div className="row mb-3 d-flex align-items-center justify-content-between">
            <div className="col-md-6 col-12">
              <InputField
                label={t("FieldOfStudy")}
                placeholder={t("Select")}
                value={studyField ? studyField.default_name : ""}
                onChange={handleFieldOfStudyChange}
                isList={true}
                list={fieldsOfStudyData?.data?.map(
                  (field) => field.default_name
                )}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
            <div className="col-md-6">
              <Button
                title={t("Add")}
                style={`shadow px-5 py-2`}
                onClick={handleAddFieldStudy}
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 gap-md-3 mt-md-0 mt-3">
              {fieldsData?.map((field, index) => (
                <div
                  className={`shadow py-2 ${styles.results_style}`}
                  key={index}
                >
                  <p>{field}</p>
                  <div>
                    <img
                      alt={"Delete"}
                      src={CancelClose}
                      className={styles.results_exit_icon}
                      onClick={() => handleRemoveFields(fieldsCode[index])}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <InputField
                label={`${t("University")} / ${t("Institution")}`}
                placeholder={t("Select")}
                value={stepTwo?.university?.value}
                onChange={(e) =>
                  handleListsChange(e, "university", universitiesData?.data)
                }
                isList={true}
                list={universitiesData?.data?.map(
                  (field) => field.default_name
                )}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <InputField
                label={t("WhenDidYouGetYourDegree")}
                placeholder={t("Select")}
                value={stepTwo?.degree?.value}
                onChange={(e) => handleListsChange(e, "degree", DegreesData)}
                isList={true}
                list={DegreesData.map((deg) => deg.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <InputField
                label={t("Grade")}
                placeholder={t("Select")}
                value={stepTwo?.grade?.value}
                onChange={(e) =>
                  handleListsChange(e, "grade", gradesData?.data)
                }
                isList={true}
                list={gradesData?.data?.map((field) => field.default_name)}
                fieldName="options"
                style={styles.select_component_style}
                inputStyle={styles.option_component_style}
              />
            </div>
          </div>
        </section>
        {/*============================= End Section 2 ============================ */}
      </form>
    </main>
  );
}

export default StepTwo;
