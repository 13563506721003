import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { APIS } from "../../utils/Axios";

const initialState = {
  token: "null",
  loading: false,
  user: null,
  companyInfo: null,
  userProfileData: null,
  error: null,
  signupError: null,
  userStatus: "Default",
  openModalStatus: null,
  corpRegisterSteps: null,
  userCode: null,
  helloUserConfirmation: null,
  cities: null,
  countries: null,
  resendOTPMessage: null,
  careerInterestsData: null,
  professionalInfoData: null,
};

// ===================== Auth ========================
// // ===================== SignUp One Action ========================--> (Candidate)
export const signUpOne = createAsyncThunk(
  "auth/signUpOne",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.signUpOne,
        data: data,
        isFormData: true,
      });
      console.log("signUpOne", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("signUpOne Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// // ===================== SignUp Two Action ========================--> (Candidate)
// export const signUpTwo = createAsyncThunk(
//   "auth/signUpTwo",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await Axios({
//         method: "POST",
//         path: APIS.signUpTwo,
//         data: data,
//         isFormData: true,
//       });
//       console.log("signUpTwo", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("signUpTwo Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// // ===================== SignUp Three Action ========================--> (Candidate)
// export const signUpThree = createAsyncThunk(
//   "auth/signUpThree",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await Axios({
//         method: "POST",
//         path: APIS.signUpThree,
//         data: data,
//         isFormData: true,
//       });
//       console.log("signUpThree", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("signUpThree Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// ===================== SignUp Four Action ========================--> (Candidate)
export const signUpFour = createAsyncThunk(
  "auth/signUpFour",
  async (data, { rejectWithValue, getState }) => {
    console.log(data);
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.signUpFour,
        data: data,
        isFormData: true,
        accessToken: token,
      });
      console.log("Candidate SignUp Four", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("Candidate SignUp Four Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// // ===================== Resend OTP Action =======================--> (Candidate)
// export const resendOTP = createAsyncThunk(
//   "auth/resendOTP",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await Axios({
//         method: "POST",
//         path: APIS.resendOTP,
//         data: data,
//         isFormData: true,
//       });
//       console.log("resendOTP", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("resendOTP Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// // ===================== Login One Action ========================
// export const loginOne = createAsyncThunk(
//   "auth/loginOne",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await Axios({
//         method: "POST",
//         path: APIS.loginOne,
//         data: data,
//         isFormData: true,
//       });
//       console.log("loginOne", res?.data);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("loginOne Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// // ===================== Login Two Action ========================
// export const loginTwo = createAsyncThunk(
//   "auth/loginTwo",
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await Axios({
//         method: "POST",
//         path: APIS.loginTwo,
//         data: data,
//         isFormData: true,
//       });
//       console.log("loginTwo", res?.data);
//       console.log(res?.data?.data.access_token);
//       localStorage.setItem("token", res?.data?.data.access_token);
//       return res.data;
//     } catch (error) {
//       const errorMessage = error?.response?.data?.message || error.message;
//       console.log("loginTwo Error", errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// ======================== Logout candidate =========================
export const logoutCandidate = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;

    try {
      const res = await Axios({
        method: "GET",
        path: APIS.logoutCandidate,
        accessToken: token,
      });
      console.log("logout", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("logout Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ======================== Get My Profile ===========================
export const getMyProfile = createAsyncThunk(
  "auth/getMyProfile",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getMyProfile,
        accessToken: token,
      });
      console.log("getMyProfile", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("getMyProfile Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ======================== My About Me ===========================
export const myAboutMe = createAsyncThunk(
  "auth/myAboutMe",
  async (aboutMeData, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.myAboutMe,
        data: aboutMeData,
        accessToken: token,
      });
      console.log("myAboutMe", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("myAboutMe Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Login One Action ========================
export const loginOne = createAsyncThunk(
  "auth/loginOne",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.loginOne,
        data: data,
        isFormData: true,
      });
      console.log("loginOne", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("loginOne Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ===================== Login One Action ========================
export const loginCompany = createAsyncThunk(
  "auth/loginCompany",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.loginCompany,
        data: data,
        isFormData: true,
      });
      console.log("loginCompany", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("loginOne Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ======================== My Profile Overview ===========================
export const myProfileOverview = createAsyncThunk(
  "auth/myProfileOverview",
  async (profileOverviewData, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.myProfileOverview,
        data: profileOverviewData,
        accessToken: token,
      });
      console.log("myProfileOverview", res?.data);
      dispatch(getMyProfile());
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("myProfileOverview Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// <<===================== Auth Company "Corporate" ========================>>
// ------------------------------ Helpers ------------------------------------
// ===================== Career Interests Helpery ========================
export const careerInterestsHelper = createAsyncThunk(
  "auth/careerInterestsHelper",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.careerInterestsHelper,
        accessToken: token,
      });
      console.log("careerInterestsHelper", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("careerInterestsHelper Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Professional Info Helpers ========================
export const professionalInfoHelpers = createAsyncThunk(
  "auth/professionalInfoHelpers",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.professionalInfoHelpers,
        accessToken: token,
      });
      console.log("professionalInfoHelpers", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("professionalInfoHelpers Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ===================== Login Company ========================
export const loginComapny = createAsyncThunk(
  "auth/loginComapny",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.LoginCompany,
        data: data,
      });
      console.log("loginComapny", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("loginComapny Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ===================== Logout Company ========================
export const logoutCompany = createAsyncThunk(
  "auth/logoutCompany",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.logoutCompany,
        accessToken: token,
      });
      console.log("logoutCompany", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.message || error.message;
      console.log("logoutCompany Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ======================== SignUp Company "Corporate"===========================
export const signUpOneCorporate = createAsyncThunk(
  "auth/signUpOneCorporate",
  async (data, { rejectWithValue }) => {
    console.log("signUpOneCorporate data", data);
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.signUpOneCorporate,
        data: data,
      });
      console.log("signUpOneCorporate", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("Error response data:", error.response?.data);
      console.log("signUpOneCorporate Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ======================== SignUp Company step1 "Corporate"===========================
export const signUpTwoCorporate = createAsyncThunk(
  "auth/signUpTwoCorporate",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.signUpTwoCorporate,
        data: data,
        accessToken: token,
      });
      console.log("signUpTwoCorporate", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("Error response data:", error.response?.data.errors);
      console.log("signUpTwoCorporate Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ======================== SignUp Company step2 "Corporate"===========================
export const signUpThreeCorporate = createAsyncThunk(
  "auth/signUpThreeCorporate",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;

    try {
      const res = await Axios({
        method: "POST",
        path: APIS.signUpThreeCorporate,
        data: data,
        accessToken: token,
      });
      console.log("signUpThreeCorporate", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("Error response data:", error.response?.data);
      console.log("signUpThreeCorporate Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ======================== SignUp Company step3 "Corporate"===========================
export const signUpFourCorporate = createAsyncThunk(
  "auth/signUpFourCorporate",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    console.log(data);
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.signUpFourCorporate,
        data: data,
        accessToken: token,
        isFormData: true,
      });
      console.log("signUpFourCorporate", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.log("Error response data:", error.response?.data);
      console.log("signUpFourCorporate Error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// ==============================================================
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    //Default Action
    defaultAct: (state, action) => {
      state.userStatus = action.payload;
    },
    // Candidate Action
    candidateAct: (state, action) => {
      state.userStatus = action.payload;
    },
    // Corporate Action
    corporateAct: (state, action) => {
      state.userStatus = action.payload;
    },
    openConfirmationModal: (state, action) => {
      state.openModalStatus = action.payload;
    },
    closeModal: (state) => {
      state.openModalStatus = null; // or another value indicating no open modal
      state.helloUserConfirmation = null; // or another value indicating no open modal
    },
    addCorpRegisterData: (state, action) => {
      state.corpRegisterSteps = action.payload; //Collect Register Steps For Corporate Here
    },
    // Define logoutSuccess to reset the auth state
    logoutSuccess: (state) => {
      state.token = null;
      state.loading = false;
      state.user = null;
      state.userProfileData = null;
      state.error = null;
      state.userStatus = null;
      state.openModalStatus = null;
      state.countries = null;
      state.corpRegisterSteps = null;
      state.userCode = null;
      state.helloUserConfirmation = null;
      state.companyInfo = null;
      state.careerInterestsData = null;
      state.professionalInfoData = null;
      localStorage.removeItem("token");
    },
    trigerHelloUser: (state, action) => {
      state.helloUserConfirmation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // // ===================== signUpOne =======================--> (Candidate)
      .addCase(signUpOne.pending, (state, action) => {
        state.loading = true;
        state.signupError = null;
      })
      .addCase(signUpOne.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfileData = action.payload.data;
        state.token = action.payload.data.access_token;
        state.signupError = null;
      })
      .addCase(signUpOne.rejected, (state, action) => {
        state.loading = false;
        state.signupError = action.payload;
      })

      // // ===================== signUpTwo =======================--> (Candidate)
      // .addCase(signUpTwo.pending, (state, action) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(signUpTwo.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.user = action.payload;
      //   state.error = null;
      // })
      // .addCase(signUpTwo.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // // ===================== signUpThree =======================--> (Candidate)
      // .addCase(signUpThree.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(signUpThree.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.userCode = action.payload.data.user;
      //   state.token = action.payload.data.access_token;
      //   state.error = null;
      //   state.resendOTPMessage = null;
      // })
      // .addCase(signUpThree.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // ===================== signUpFour =======================--> (Candidate)
      .addCase(signUpFour.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUpFour.fulfilled, (state, action) => {
        state.loading = false;
        state.userCode = action.payload.data.code;
        state.error = null;
      })
      .addCase(signUpFour.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // // ===================== resend OTP =======================--> (Candidate)
      // .addCase(resendOTP.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      //   state.resendOTPMessage = null;
      // })
      // .addCase(resendOTP.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.resendOTPMessage = action.payload.message;
      //   state.error = null;
      // })
      // .addCase(resendOTP.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // // ===================== Login One ========================
      // .addCase(loginOne.pending, (state, action) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(loginOne.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.user = action.payload;
      //   state.token = action.payload.data.access_token;
      //   state.error = null;
      // })
      // .addCase(loginOne.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // // ===================== Login Two ========================
      // .addCase(loginTwo.pending, (state, action) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(loginTwo.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.user = action.payload;
      //   state.token = action.payload.data.access_token;
      //   state.error = null;
      // })
      // .addCase(loginTwo.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // ===================== Login Company ========================
      .addCase(loginComapny.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginComapny.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.data.access_token;
        state.companyInfo = action.payload.data.user;
        state.error = null;
      })
      .addCase(loginComapny.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Logout Company ========================
      .addCase(logoutCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutCompany.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.token = null;
        state.loading = false;
        state.user = null;
        state.userProfileData = null;
        state.userStatus = null;
        state.openModalStatus = null;
        state.countries = null;
        state.cities = null;
        state.corpRegisterSteps = null;
        state.userCode = null;
        state.companyInfo = null;
        state.careerInterestsData = null;
        state.professionalInfoData = null;
      })
      .addCase(logoutCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // -------------------------- Helpers ------------------------------
      // ===================== Career Interests Helper ========================
      .addCase(careerInterestsHelper.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(careerInterestsHelper.fulfilled, (state, action) => {
        state.loading = false;
        state.careerInterestsData = action.payload.data;
        state.error = null;
      })
      .addCase(careerInterestsHelper.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Professional Info Helpers ========================
      .addCase(professionalInfoHelpers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(professionalInfoHelpers.fulfilled, (state, action) => {
        state.loading = false;
        state.professionalInfoData = action.payload.data;
        state.error = null;
      })
      .addCase(professionalInfoHelpers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== signUpOneCorporate ========================
      .addCase(signUpOneCorporate.pending, (state) => {
        state.loading = true;
        state.signupError = null;
      })
      .addCase(signUpOneCorporate.fulfilled, (state, action) => {
        state.loading = false;
        state.companyInfo = action.payload.data.user;
        state.token = action.payload.data.access_token;
        state.signupError = null;
      })
      .addCase(signUpOneCorporate.rejected, (state, action) => {
        state.loading = false;
        state.signupError = action.payload;
      })
      // ===================== signUpTwoCorporate ========================
      .addCase(signUpTwoCorporate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUpTwoCorporate.fulfilled, (state, action) => {
        state.loading = false;
        state.companyInfo = action.payload.data;
        state.StepOneData = action.payload.data;
        state.error = null;
      })
      .addCase(signUpTwoCorporate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== signUpThreeCorporate ========================
      .addCase(signUpThreeCorporate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUpThreeCorporate.fulfilled, (state, action) => {
        state.loading = false;
        state.companyInfo = action.payload.data;
        state.StepTwoData = action.payload.data;
        state.error = null;
      })
      .addCase(signUpThreeCorporate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== signUpFourCorporate ========================
      .addCase(signUpFourCorporate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUpFourCorporate.fulfilled, (state, action) => {
        state.loading = false;
        state.companyInfo = action.payload.data;
        state.StepThreeData = action.payload.data;
        state.error = null;
      })
      .addCase(signUpFourCorporate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== Logout ========================
      .addCase(logoutCandidate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutCandidate.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.token = null;
        state.loading = false;
        state.user = null;
        state.userProfileData = null;
        state.userStatus = null;
        state.openModalStatus = null;
        state.countries = null;
        state.cities = null;
        state.corpRegisterSteps = null;
        state.userCode = null;
        state.companyInfo = null;
        state.careerInterestsData = null;
        state.professionalInfoData = null;
      })
      .addCase(logoutCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== get My Profile ========================
      .addCase(getMyProfile.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfileData = action.payload.data;
        state.error = null;
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== My About Me ========================
      .addCase(myAboutMe.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(myAboutMe.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(myAboutMe.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ===================== My Profile Overview ========================
      .addCase(myProfileOverview.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(myProfileOverview.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(myProfileOverview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  trigerHelloUser,
  defaultAct,
  candidateAct,
  corporateAct,
  openConfirmationModal,
  logoutSuccess,
  closeModal,
} = authSlice.actions;
export default authSlice.reducer;
